import React, { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import ErrorText from '../../components/ErrorText';
import { auth } from '../../config/firebase';
import logging from '../../config/logging';
import IPageProps from '../../interfaces/page';
import {
    FormControl,
    InputAdornment,
    TextField,
    Button,
} from '@material-ui/core';
import KakaoUnlink from '../../KakaoUnlink';

const ChangePasswordPage: React.FunctionComponent<IPageProps> = (props) => {
    const [changing, setChanging] = useState<boolean>(false);
    const [password, setPassword] = useState<string>('');
    const [old, setOld] = useState<string>('');
    const [confirm, setConfirm] = useState<string>('');
    const [error, setError] = useState<string>('');

    const history = useHistory();

    const goBack = () => {
        history.push('/setting');
    };
    const passwordChangeRequest = () => {
        if (password !== confirm) {
            setError('비밀번호가 같은지 다시확인해 주시기 바랍니다.');
            return;
        }
        if (password.length < 8) {
            setError('비밀번호는 8 자 이상으로 지정해주세요.');
            return;
        }
        if (password.search(/[a-z]/i) < 0) {
            setError('비밀번호는 최소 글자 하나가 필요합니다.');
            return;
        }
        if (password.search(/[0-9]/) < 0) {
            setError('비밀번호는 최소 번호 하나가 들어가야합니다.');
            return;
        }
        if (error !== '') setError('');

        setChanging(true);

        auth.currentUser
            ?.updatePassword(password)
            .then(() => {
                logging.info('비밀번호 변경이 완료되었습니다.');
                history.push('/');
            })
            .catch((error) => {
                logging.error(error);
                setChanging(false);
                if (error.code == 'auth/requires-recent-login')
                    setError(
                        '최근 로그인이 필요합니다, 다시 로그인해주시고 변경해주세요.'
                    );
                else if (error.code == 'auth/weak-password')
                    setError('더욱 복잡한 비밀번호를 입력해주세요. ');
            });
    };

    if (auth.currentUser?.providerData[0]?.providerId !== 'password')
        return <Redirect to="/" />;

    return (
        <div className="fade" style={{ margin: '40px' }}>
            {changing && (
                <div className="loader-wrapper">
                    <span className="loader">
                        <span className="loader-innder"></span>
                    </span>
                    <span className="loader_text">변경사항 수정중입니다..</span>
                </div>
            )}
            <img className="img-logo-login" src="./videhome_logo.png"></img>
            <div style={{ color: 'black' }}>
                <FormControl style={{ width: '100%' }}>
                    <TextField
                        autoComplete="new-password"
                        type="password"
                        name="password"
                        id="password"
                        placeholder="Enter Password"
                        onChange={(event) => setPassword(event.target.value)}
                        value={password}
                        style={{
                            top: '2px',
                            marginBottom: '25px',
                            color: 'black',
                        }}
                        label="새로운 비밀번호"
                        inputProps={{
                            style: { textAlign: 'center', fontSize: '19px' },
                            'aria-label': 'naked',
                            startAdornment: (
                                <InputAdornment position="start"></InputAdornment>
                            ),
                        }}
                        InputLabelProps={{
                            style: {
                                fontSize: '15px',
                                fontFamily: 'Pretendard-Light',
                            },
                        }}
                    />
                    <TextField
                        autoComplete="new-password"
                        type="password"
                        name="confirm"
                        id="confirm"
                        placeholder="Confirm Password"
                        onChange={(event) => setConfirm(event.target.value)}
                        value={confirm}
                        style={{
                            top: '2px',
                            marginBottom: '25px',
                            color: 'black',
                        }}
                        label="새로운 비밀번호 재입력"
                        inputProps={{
                            style: { textAlign: 'center', fontSize: '19px' },
                            'aria-label': 'naked',
                            startAdornment: (
                                <InputAdornment position="start"></InputAdornment>
                            ),
                        }}
                        InputLabelProps={{
                            style: {
                                fontSize: '15px',
                                fontFamily: 'Pretendard-Light',
                            },
                        }}
                    />
                    <div
                        style={{
                            justifyContent: 'space-evenly',
                            display: 'flex',
                        }}
                    >
                        <Button
                            variant="outlined"
                            onClick={() => goBack()}
                            style={{
                                width: '40%',
                                margin: 'auto',
                                minWidth: '30px',
                                border: 'solid 2px',
                                borderRadius: '10rem',
                                fontFamily: 'Pretendard-Light',
                            }}
                        >
                            뒤로가기
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => passwordChangeRequest()}
                            style={{
                                width: '40%',
                                margin: 'auto',
                                minWidth: '30px',
                                border: 'solid 2px',
                                borderRadius: '10rem',
                                fontFamily: 'Pretendard-Light',
                            }}
                        >
                            수정하기
                        </Button>
                    </div>
                    <div
                        style={{
                            padding: '10px',
                            fontFamily: 'Pretendard-Light',
                            color: 'red',
                        }}
                    >
                        <ErrorText error={error} />
                    </div>
                    <div>
                        <KakaoUnlink
                            token={process.env.REACT_APP_KAKAO_JS_KEY!}
                            onSuccess={console.log}
                            onFail={console.error}
                            onLogout={console.info}
                        />
                    </div>
                </FormControl>
            </div>
        </div>
    );
};

export default ChangePasswordPage;
