import {
    FormControl,
    InputAdornment,
    TextField,
    Button,
    Select,
    withStyles,
    Theme,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ErrorText from '../../components/ErrorText';
import { auth, db } from '../../config/firebase';
import IPageProps from '../../interfaces/page';
import DaumPostcode from 'react-daum-postcode';
import { createStyles } from '@mui/material';
import InputBase from '@material-ui/core/InputBase';
import MenuItem from '@material-ui/core/MenuItem';

const BootstrapInput = withStyles((theme: Theme) =>
    createStyles({
        root: {
            'label + &': {
                position: 'relative',
            },
        },
        input: {
            borderRadius: 4,
            position: 'relative',
            backgroundColor: theme.palette.background.paper,
            border: 'none',
            fontSize: 16,
            padding: '10px 26px 10px 12px',
            transition: theme.transitions.create([
                'border-color',
                'box-shadow',
            ]),
            // Use the system font instead of the default Roboto font.
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            '&:focus': {
                borderRadius: 4,
                borderColor: '#80bdff',
                boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
            },
        },
    })
)(InputBase);
const MyInfoPage: React.FunctionComponent<IPageProps> = (props) => {
    const [changing, setChanging] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [Nname, setNName] = useState<string>('');
    const [address, setAddress] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [additionalAddress, setAdditionalAddresss] = useState<string>(''); // 주소
    const [banks, setBanks] = useState([]);
    const [selectedBank, setSelectedBank] = useState('');
    const [routingNumber,setRoutingNumber] =  useState<string>('');



    const [checked, setChecked] = useState<boolean>(false);
    const [showAdditional, setShowAdditional] = useState<boolean>(false);
    const [editON, setEditON] = useState<boolean>(false);
    const delay = (ms: any) => new Promise((res: any) => setTimeout(res, ms));

    const [authDataChanged, setAuthDataChanged] = useState<any | null>(null);

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            setAuthDataChanged(user);
        });
    }, []);

    useEffect(() => {
        if (auth && auth.currentUser?.uid! != undefined) {
            setNName(auth.currentUser?.displayName!);
            setChecked(true);
            db.collection('user')
                .doc(auth.currentUser?.uid!)
                .get()
                .then((doc) => {
                    if (doc.exists) {
                        setAddress(doc.data()!.address);
                        setPhone(doc.data()!.phone);
                        setName(doc.data()!.name);
                        setSelectedBank(doc.data()!.bank_type);
                        setRoutingNumber(doc.data()!.accountNumber);
                    }
                });
        }
        if (db) {
            db.collection('infos')
                .doc('banks')
                .get()
                .then((doc) => {
                    setBanks(doc.data()!.korea);
                });
        }
    }, [authDataChanged]);

    const startEdit = () => {
        setEditON(!editON);
    };
    const goBack = () => {
        history.push('/setting');
    };

    const history = useHistory();

    const saveEdit = async () => {
        var saving = window.confirm('저장하시겠습니까?');
        if (saving) {
            setChanging(true);
            if (
                name !== '' &&
                address !== '' &&
                phone !== '' &&
                selectedBank !== '' &&
                routingNumber !== '' &&
                checked === true &&
                showAdditional === false
            ) {
                auth.currentUser
                    ?.updateProfile({
                        displayName: Nname,
                    })
                    .then(function () {
                        console.log('Display name updated');
                    })
                    .catch(function (error) {
                        console.error(error);
                    });
                db.collection('user')
                    .doc(auth.currentUser?.uid!)
                    .update({
                        userId: auth.currentUser?.email,
                        name: name,
                        bank_type: selectedBank,
                        accountNumber: routingNumber
                    });
                alert('수정이 완료되었습니다.');
                await delay(2000);
                history.push('/');
                setChanging(false);
            } else if (
                name !== '' &&
                address !== '' &&
                phone !== '' &&
                selectedBank !== '' &&
                routingNumber !== '' &&
                checked === true &&
                showAdditional === true && additionalAddress !== ""
            ) {
                auth.currentUser
                    ?.updateProfile({
                        displayName: Nname,
                    })
                    .then(function () {
                        console.log('Display name updated');
                    })
                    .catch(function (error) {
                        console.error(error);
                    });
                db.collection('user')
                    .doc(auth.currentUser?.uid!)
                    .update({
                        userId: auth.currentUser?.email,
                        name: name,
                        address: address + ', ' + additionalAddress,
                        banks: selectedBank,
                        accountNumber: routingNumber
                    });
                alert('수정이 완료되었습니다.');
                await delay(2000);
                history.push('/');
                setChanging(false);
            } else {
                if (checked !== true) {
                    alert('별명 중복확인 해주세요.');
                    setChanging(false);
                }
                if (additionalAddress === '') {
                    alert('추가주소를 입려해주세요.');
                    setChanging(false);
                } else {
                    alert('작성완료후 눌러주세요.');
                    setChanging(false);
                }
            }
        }
    };

    const handleChange = (prop: string) => (event: any) => {
        if (prop == 'name_') {
            setName(event.target.value);
        }
        if (prop == 'Nname_') {
            var nickname = event.target.value
                .toString()
                .replace(
                    /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi,
                    ''
                );
            if (nickname.length < 8) {
                setNName(nickname);
            } else if (nickname.length === 8) {
                setNName(nickname);
            }
            if (nickname === auth.currentUser?.displayName) {
                setChecked(true);
            } else {
                setChecked(false);
            }
        } else if (prop == 'check_') {
            db.collection('nickname')
                .doc('nicknameList')
                .get()
                .then((doc) => {
                    if (doc.exists) {
                        for (var i = 0; i < doc.data()!.list.length; i++) {
                            if (doc.data()!.list[i] === Nname) {
                                alert(
                                    '이미 사용중인 별명입니다. 다른 별명으로 작성해주세요.'
                                );
                                return;
                            }
                        }
                        setChecked(true);
                        alert('사용 가능합니다.');
                    }
                });
        } else if (prop == 'address_') {
            db.collection('user')
                .doc(auth.currentUser?.uid!)
                .get()
                .then((doc) => {
                    if (doc.exists) {
                        if (doc.data()!.address === address) {
                            setShowAdditional(false);
                            return;
                        }
                        setShowAdditional(true);
                    }
                });
            setAddress(event.target.value);
        } else if (prop == 'additionalAddress_') {
            setAdditionalAddresss(event.target.value);
        } else if (prop == 'bank_') {
            setSelectedBank(event.target.value);
        }else if (prop == 'routingNumber_') {
            setRoutingNumber(event.target.value);
        }
        else if (prop == 'phone_') {
            const onlyNums = event.target.value.replace(/[^0-9]/g, '');
            if (onlyNums.length < 11) {
                setPhone(onlyNums);
            } else if (onlyNums.length === 11) {
                const number = onlyNums.replace(
                    /(\d{3})(\d{4})(\d{4})/,
                    '($1) $2-$3'
                );
                setPhone(number);
            }
        }
    };
    const onCompletePost = (data: any) => {
        let fullAddr = data.address;
        let extraAddr = '';

        if (data.addressType === 'R') {
            if (data.bname !== '') {
                extraAddr += data.bname;
            }
            if (data.buildingName !== '') {
                extraAddr +=
                    extraAddr !== ''
                        ? `, ${data.buildingName}`
                        : data.buildingName;
            }
            fullAddr += extraAddr !== '' ? ` (${extraAddr})` : '';
        }

        setAddress(data.zonecode);
        setAddress(fullAddr);
        setIsOpenPost(false);
        setShowAdditional(true);
    };

    const [isOpenPost, setIsOpenPost] = useState(false);

    const onChangeOpenPost = () => {
        setIsOpenPost(!isOpenPost);
    };

    return (
        <div className="fade" style={{ margin: '40px' }}>
            {changing && (
                <div className="loader-wrapper">
                    <span className="loader">
                        <span className="loader-innder"></span>
                    </span>
                    <span className="loader_text">변경사항 수정중입니다..</span>
                </div>
            )}
            <img className="img-logo-login" src="./videhome_logo.png"></img>
            <div style={{ color: 'black' }}>
                <FormControl style={{ width: '100%' }}>
                    <TextField
                        autoComplete="new-password"
                        name="confirm"
                        id="confirm"
                        style={{
                            top: '2px',
                            marginBottom: '25px',
                            color: 'black',
                        }}
                        placeholder="성함을 입력해주세요"
                        type="input"
                        value={name}
                        onChange={handleChange('name_')}
                        label="성함"
                        disabled={!editON}
                        inputProps={{
                            style: {
                                textAlign: 'left',
                                fontSize: '19px',
               fontFamily: "Pretendard-Light",
                            },
                            'aria-label': 'naked',
                            startAdornment: (
                                <InputAdornment position="start"></InputAdornment>
                            ),
                        }}
                        InputLabelProps={{
                            style: {
                                fontSize: '15px',
               fontFamily: "Pretendard-Light",
                            },
                        }}
                    />
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <div style={{ width: '100%', display: 'flex' }}>
                            <TextField
                                autoComplete="new-password"
                                name="confirm"
                                id="confirm"
                                disabled={!editON}
                                style={{
                                    top: '2px',
                                    marginBottom: '25px',
                                    color: 'black',
                                }}
                                placeholder="별명을 입력해주세요"
                                type="input"
                                value={Nname}
                                onChange={handleChange('Nname_')}
                                label="별명"
                                inputProps={{
                                    style: {
                                        textAlign: 'left',
                                        fontSize: '19px',
                       fontFamily: "Pretendard-Light",
                                    },
                                    'aria-label': 'naked',
                                    startAdornment: (
                                        <InputAdornment position="start"></InputAdornment>
                                    ),
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontSize: '15px',
                       fontFamily: "Pretendard-Light",
                                    },
                                }}
                            />
                        </div>
                        {!checked && (
                            <div
                                style={{
                                    display: 'flex',
                                    width: '100%',
    
                                }}
                            >
                                <Button
                                    disabled={!editON}
                                    variant="outlined"
                                    style={{
                                        width: '100%',
                                        padding: '5px 0px',
                                        margin: 'auto',
                                        border: 'none',
                                        color: 'black',
                       fontFamily: "Pretendard-Light",
                                        
                                    }}
                                    onClick={handleChange('check_')}
                                >
                                    중복확인
                                </Button>
                            </div>
                        )}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            fontSize: '15px',
                            paddingBottom: '20px',
                        }}
                    >
                        <div style={{ width: '30%' }}>
                            <div
                                style={{
                                    textAlign: 'left',
                                    color: 'rgba(0, 0, 0, 0.54)',
                                    marginTop: '6px',
                                }}
                            >
                                은행/계좌번호
                            </div>
                            <div
                                style={{
                                    position: 'relative',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-evenly',
                                    textAlign: 'center',
                                    width: '100%',
                                }}
                            >
                                <div
                                    style={{
                                        position: 'relative',
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}
                                >
                                    <div
                                        style={{
                                            width: '100%',
                                            position: 'relative',
                                        }}
                                    >
                                        <Select
                                            label="시간"
                                            labelId="demo-customized-select-label"
                                            id="demo-customized-select"
                                            value={selectedBank}
                                            onChange={handleChange('bank_')}
                                            input={<BootstrapInput />}
                                            disabled={!editON}
                                        >
                                            {banks!.map((key) => {
                                                return (
                                                    <MenuItem value={key}>
                                                        {key}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <TextField
                                style={{
                                    top: '20px',
                                    marginBottom: '25px',
                                    fontSize: '15px',
                                }}
                                disabled={!editON}
                                id="input-with-icon-TextField"
                                type="input"
                                InputLabelProps={{
                                    style: {
                                        fontSize: '20px',
                                    },
                                }}
                                InputProps={{
                                    style: {
                                        fontSize: '15px',

                                        padding: '10px 0 0 0',
                                    },
                                    startAdornment: (
                                        <InputAdornment position="start"></InputAdornment>
                                    ),
                                }}
                                placeholder="xxx-xxxx-xxxx"
                                value={routingNumber}
                                onChange={handleChange('routingNumber_')}
                            />
                        </div>
                    </div>
                    <TextField
                        autoComplete="new-password"
                        name="confirm"
                        id="confirm"
                        style={{
                            top: '2px',
                            marginBottom: '25px',
                            color: 'black',
                        }}
                        placeholder="주소를 입력해주세요"
                        type="input"
                        onChange={handleChange('address_')}
                        label="주소"
                        disabled
                        onClick={() => {
                            setIsOpenPost(!isOpenPost);
                        }}
                        value={address}
                        inputProps={{
                            style: {
                                textAlign: 'left',
                                fontSize: '19px',
               fontFamily: "Pretendard-Light",
                            },
                            'aria-label': 'naked',
                            startAdornment: (
                                <InputAdornment position="start"></InputAdornment>
                            ),
                        }}
                        InputLabelProps={{
                            style: {
                                fontSize: '15px',
               fontFamily: "Pretendard-Light",
                            },
                        }}
                    />
                    {isOpenPost && (
                        <DaumPostcode
                            style={{
                                display: 'block',
                                position: 'relative',
                                top: '0%',
                                width: '100%',
                                height: '300px',
                                padding: '7px',
                            }}
                            autoClose
                            onComplete={onCompletePost}
                        />
                    )}
                    {showAdditional && (
                        <TextField
                            autoComplete="new-password"
                            name="confirm"
                            id="confirm"
                            style={{
                                top: '2px',
                                marginBottom: '25px',
                                color: 'black',
                            }}
                            placeholder="추가 주소를 입력해주세요"
                            type="input"
                            disabled={!editON}
                            onChange={handleChange('additionalAddress_')}
                            label="추가 주소"
                            inputProps={{
                                style: {
                                    textAlign: 'left',
                                    fontSize: '19px',
                   fontFamily: "Pretendard-Light",
                                },
                                'aria-label': 'naked',
                                startAdornment: (
                                    <InputAdornment position="start"></InputAdornment>
                                ),
                            }}
                            InputLabelProps={{
                                style: {
                                    fontSize: '15px',
                   fontFamily: "Pretendard-Light",
                                },
                            }}
                        />
                    )}

                    <TextField
                        autoComplete="new-password"
                        name="confirm"
                        id="confirm"
                        style={{
                            top: '2px',
                            marginBottom: '25px',
                            color: 'black',
                        }}
                        placeholder="전화번호를 입력해주세요"
                        type="input"
                        value={phone}
                        disabled
                        onChange={handleChange('phone_')}
                        label="전화번호"
                        inputProps={{
                            style: {
                                textAlign: 'left',
                                fontSize: '19px',
               fontFamily: "Pretendard-Light",
                            },
                            'aria-label': 'naked',
                            startAdornment: (
                                <InputAdornment position="start"></InputAdornment>
                            ),
                        }}
                        InputLabelProps={{
                            style: {
                                fontSize: '15px',
               fontFamily: "Pretendard-Light",
                            },
                        }}
                    />
                    {!editON ? (
                        <div
                            style={{
                                justifyContent: 'space-evenly',
                                display: 'flex',
                            }}
                        >
                            <Button
                                disabled={editON}
                                variant="outlined"
                                onClick={() => goBack()}
                                style={{
                                    width: '40%',
                                    margin: 'auto',
                                    minWidth: '30px',
                                    border: 'solid 2px',
                                    borderRadius: '10rem',
                   fontFamily: "Pretendard-Light",
                                }}
                            >
                                뒤로가기
                            </Button>
                            <Button
                                disabled={editON}
                                variant="outlined"
                                onClick={() => startEdit()}
                                style={{
                                    width: '40%',
                                    margin: 'auto',
                                    minWidth: '30px',
                                    border: 'solid 2px',
                                    borderRadius: '10rem',
                   fontFamily: "Pretendard-Light",
                                }}
                            >
                                수정하기
                            </Button>
                        </div>
                    ) : (
                        <div
                            style={{
                                justifyContent: 'space-evenly',
                                display: 'flex',
                            }}
                        >
                            <Button
                                disabled={!editON}
                                variant="outlined"
                                onClick={() => goBack()}
                                style={{
                                    width: '40%',
                                    margin: 'auto',
                                    minWidth: '30px',
                                    border: 'solid 2px',
                                    borderRadius: '10rem',
                   fontFamily: "Pretendard-Light",
                                }}
                            >
                                뒤로가기
                            </Button>
                            <Button
                                disabled={!editON}
                                variant="outlined"
                                onClick={() => saveEdit()}
                                style={{
                                    width: '40%',
                                    margin: 'auto',
                                    minWidth: '30px',
                                    border: 'solid 2px',
                                    borderRadius: '10rem',
                   fontFamily: "Pretendard-Light",
                                }}
                            >
                                저장하기
                            </Button>
                        </div>
                    )}
                    <div
                        style={{
                            padding: '10px',
           fontFamily: "Pretendard-Light",
                            color: 'red',
                        }}
                    >
                        <ErrorText error={error} />
                    </div>
                </FormControl>
            </div>
        </div>
    );
};

export default MyInfoPage;
