import { Button, InputAdornment } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ERState } from '../ERReducer';
import './items2.css';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import { green } from '@material-ui/core/colors';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import Er_rate from './er_rate';
import { format } from 'path';
import Firebase, { auth, db } from '../config/firebase';
import firebase from 'firebase/app'
import { finder } from './finder';

const useStyles = makeStyles({
  table: {
    minWidth: 650,  
  },
});

function createData(group :any, defaultAmount :number, totalWeight :number, totalAmount :number) {
  return { group, defaultAmount, totalWeight, totalAmount };
}

function Items2(){
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#07381B",
      color: "white",
      fontWeight:"bold",
      fontSize: 17,
    },
    body: {
      fontSize: 14,
      fontWeight:"bold",
    },
  }))(TableCell);

  const mass = useSelector<ERState, ERState["totalMass"]>((state)=> state.totalMass);
  const user = useSelector<ERState, ERState["user"]>((state)=> state.user);
  const dispatch = useDispatch();

  const calcMass = (mass:number)=>{
    if(consentAgreement1 == false){
        alert("필수 약관에 동의해주시기 바랍니다.")
    }
    else if(user.length<=0){
        alert("회원정보를 다 입력하시고 저장하기를 누르셧는지 확인해주세요.")
    }
    else{
      const orders = {
        date:user[user.length-1][3],
        time:user[user.length-1][4],
        way:user[user.length-1][5],
        weight:mass,
        additional:0,
      }
      const BOrders = {
        name: user[user.length-1][0],
        address: user[user.length-1][1],
        phone:user[user.length-1][2],
        date:user[user.length-1][3],
        time:user[user.length-1][4],
        way:user[user.length-1][5],
        weight:mass,
        additional:0,
      }
      db.collection("user").doc(auth.currentUser?.email!).update({
          orders: firebase.firestore.FieldValue.arrayUnion(orders),
          numberOfOrders:firebase.firestore.FieldValue.increment(1),
          totalWeight: firebase.firestore.FieldValue.increment(mass),
          privacyAgree: true
      })
      if (auth.currentUser?.email){
          db.collection("orders").doc("admin").update({
          "user" : {
          orders: firebase.firestore.FieldValue.arrayUnion(orders),
          userId: auth.currentUser?.email
        }
          })
        }
        else{
          db.collection("orders").doc("admin").update({
            orders: firebase.firestore.FieldValue.arrayUnion(BOrders),
            userId: "non_user"
            })
        }
      alert("수거신청이 완료되었습니다. 판매신청 진행/수정/확인필요시, 신청확인애서 확인해주세요.");
    }
  }
  const [grouping, setGrouping] = useState(1);

//   category
  const [count1, setCount1] = useState<number>();

  // consemt AGREEMEMT
  const [consentAgreement1, setConsentAgreement1] = useState(false);

  const [countCommaW,setCountCommaW] = useState<String>("0");
  const [countCommaC,setCountCommaC] = useState<String>("0");

  // BUG FIXER
  const [pressed,setPressed] = useState(0);

  const [totalCost, setTotalCost] = useState<number>(0);
  const [cost, setCost] = useState<number>(200);
  const [rows,setRows] = useState([
    createData('예시*' , 200 , 3, 600 ),
  ]);


  const [show, setShow] = useState(false);
  const [overallCost,setOverallCost]=useState<number>(0);
  const [overallWeight,setOverallWeight]=useState<number>(0);

  const popRef = useRef<HTMLDivElement>(null);
  const classes = useStyles();

    useEffect(() => {
        document.addEventListener('click', onClickOutside);
        return () => {
          document.removeEventListener('click', onClickOutside);
        };
      }, []);

const onClickOutside = useCallback(
    ({ target }) => {
      if (popRef.current && !popRef.current.contains(target)) {
        setShow(false);
      }
    },
    [setShow]
  );

const onClickToggleModal = useCallback(() => {
    setShow(prev => !prev);
  }, [setShow]);

  const formatNumber = (inputNumber:number) => {
    let formetedNumber=(Number(inputNumber)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    let splitArray=formetedNumber.split('.');
    if(splitArray.length>1){
      formetedNumber=splitArray[0];
    }
    return(formetedNumber);
  };
const addToItems = async (cate1:number, cate2:number, cate3:number) => {

    if (pressed === 0 ){
        if (cate1 === undefined){
            cate1 = 0
        }
        if (cate2 === undefined){
            cate2 = 0
        }
        if (cate3 === undefined){
            cate3 = 0
        }
        const clothes = cate1 *200;
        const kitchen = cate2*250;
        const garage = cate3*50;
    
        const formattedCountW = formatNumber(overallWeight+Number(cate1)+Number(cate2)+Number(cate3));
        const formattedCountC = formatNumber(overallCost+Number(clothes)+Number(kitchen)+Number(garage))

        setCountCommaW(formattedCountW);
        setCountCommaC(formattedCountC);
        setOverallWeight(overallWeight+Number(cate1)+Number(cate2)+Number(cate3));
        setOverallCost(overallCost+Number(clothes)+Number(kitchen)+Number(garage));
        setPressed(pressed + 1);
    }
    else{
        if (cate1 === undefined){
            cate1 = 0
        }
        if (cate2 === undefined){
            cate2 = 0
        }
        if (cate3 === undefined){
            cate3 = 0
        }
        const clothes = cate1 *200;
        const kitchen = cate2*250;
        const garage = cate3*50;
      
        const formattedCountW = formatNumber(Number(cate1)+Number(cate2)+Number(cate3));
        const formattedCountC = formatNumber(Number(clothes)+Number(kitchen)+Number(garage))

        setCountCommaW(formattedCountW);
        setCountCommaC(formattedCountC);
        setOverallWeight(overallWeight+Number(cate1)+Number(cate2)+Number(cate3));
        setOverallCost(overallCost+Number(clothes)+Number(kitchen)+Number(garage));
        refresh();
    }
}

const refresh = () =>{
  setCost(200);
  setCount1(undefined);
  setTotalCost(0);
  setGrouping(1);
  onClickToggleModal();
}

const handleChange = (prop : string) => (event: any) => {
  if (prop == "grouping"){
    setGrouping(event.target.value);
    if (event.target.value == 1){
      setCost(200);
    }
    else if (event.target.value == 2){
      setCost(250);
    }
    else if (event.target.value == 3){
      setCost(100);
    }
    else if (event.target.value == 4){
      setCost(50);
    }
  }
  else if (prop == "count_1"){
    const onlyNums = event.target.value.replace(/[^0-9]/g, '');
    setCount1(onlyNums);
  }
  else if (prop == "totalCost_"){
    setTotalCost(event.target.value);
  }
  else if (prop == "consent_1"){
      setConsentAgreement1(!consentAgreement1);
  }
};

  const GreenCheckbox = withStyles({
    root: {
      color: green[400],
      '&$checked': {
        color: green[600],
      },
    },
    checked: {},
  })((props: CheckboxProps) => <Checkbox color="default" {...props} />);
  
  

    return (
         <div className="add">
           <div>
            <div className="bottom">
            {/* this is the adding part */}
                <div className="modal" style={{margin:" 0 40px", width:"100%"}}>
                    <div>
                    <div className="total"> 
                      <div  className="totalCate" >
                        <div style={{ borderBottom:"solid black 5px", 
                          margin: "auto",
                          backgroundColor:"#07381B",
                          color:"white",
                          padding: "10px 0px",fontSize:"20px"}} >
                          무게입력(KG)
                        </div>
                        <div className="totalCate_price" style={{    margin:"auto",
                          padding: "20px 0",}}>
                                 <FormControl style={{width:"50%"}} >
                            <TextField
                                style = {{top:"2px", marginBottom:"25px"}}
                                    id="input-with-icon-textfield"
                                    value={count1}
                                    type="input"
                                    onChange={handleChange("count_1")}
                                    placeholder="무게를 입력해주세요"
                                    inputProps={{ style: { textAlign: 'center',fontSize:"19px", fontFamily: 'TmoneyRoundWindExtraBold' },
                                    'aria-label': 'naked',
                                    startAdornment: (
                                      <InputAdornment position="start">
                                      </InputAdornment>
                                  ),}}
                                    InputLabelProps={{ 
                                      style:{fontSize:"25px", fontFamily: 'TmoneyRoundWindExtraBold'}
                                      }}
                            />
                            </FormControl>
                        </div>
                      </div>
                    </div> 

                    </div>
            </div>
        </div>
        <div className="total" style={{paddingTop:"25px"}}> 
            <div  className="totalCate" >
              <div className="totalCate_name" >
                무게금액(원)
              </div>
              <div className="totalCate_price" >
             {(count1! == undefined)?<>0 원</>:<>{count1!*200+ " 원"}</>}
              </div>
            </div>
            <div  className="totalCate" >
            <div className="totalCate_name" >
                추가금액(원)
              </div>
              <div className="totalCate_price" >
                0 원
              </div>
            </div>     
            <div  className="totalCate" >
            <div className="totalCate_name" >
                평균금액(원)
              </div>
              <div className="totalCate_price" >
                0 원
              </div>
            </div>
        </div>
        <div className="right">
            <div className="top">
                <p style={{fontSize:"20px"}}>환경 절약</p>
            </div>
            <div className="wrapper" style={{display:"flex",position:"relative", justifyContent:"center"}}>
            {Er_rate()}
            </div> 
            <div style={{padding:"15px 0 5px 0"}}>
              <FormControlLabel
                          style={{fontSize:"10px", color:"black"}}
                          control={<GreenCheckbox
                              checked={consentAgreement1}
                              onChange={handleChange("consent_1")}
                              value={consentAgreement1}
                              name="radio-button-demo"
                              inputProps={{ 'aria-label': 'C' }}
                              />}
                          label="개인정보처리방침안내의 내용에 동의힙니다."
                          labelPlacement="end"
                          />
            </div>
              <div style={{display:"flex", flexDirection:"row", justifyContent:'space-evenly', fontSize:"10px"}}>
                <Button className="buttons" style={{fontFamily: 'TmoneyRoundWindExtraBold', padding:"12px"}} variant="outlined">개인정보처리방침</Button>
                <Button className="buttons" style={{fontFamily: 'TmoneyRoundWindExtraBold', padding:"12px"}} variant="outlined" onClick={()=>{calcMass(count1!)}}>신청하기</Button>
                <Button className="buttons" style={{fontFamily: 'TmoneyRoundWindExtraBold', padding:"12px"}} variant="outlined" >다시작성하기</Button>
                </div>
        </div>
                {/* <p>약관 및 개인정보 수집동의</p>
                <div style={{color:"black", display:"flex", flexDirection:"column", justifyContent:'center', margin:"15px", border:"dotted 1px",padding:"10px"}}>
                    <FormControlLabel
                        control={<GreenCheckbox
                            checked={consentAgreement1}
                            onChange={handleChange("consent_1")}
                            value={consentAgreement1}
                            name="radio-button-demo"
                            inputProps={{ 'aria-label': 'C' }}
                            />}
                        label="회원약관에 동의합니다.(필수)"
                        labelPlacement="end"
                        /> <Button className="buttons" style={{fontFamily: 'TmoneyRoundWindExtraBold'}}  variant="outlined" > 회원약관 읽기</Button>
                    <FormControlLabel
                        control={<GreenCheckbox
                            checked={consentAgreement2}
                            onChange={handleChange("consent_2")}
                            value={consentAgreement2}
                            name="radio-button-demo"
                            inputProps={{ 'aria-label': 'C' }}
                            />} 
                        label="개인정보 수집에 동의합니다.(필수)"
                        labelPlacement="end"
                        /> <Button className="buttons" style={{fontFamily: 'TmoneyRoundWindExtraBold'}} variant="outlined" >개인정보 수집 약관 읽기</Button>
                    <FormControlLabel
                        control={<GreenCheckbox
                            checked={consentAgreement3}
                            onChange={handleChange("consent_3")}
                            value={consentAgreement3}
                            name="radio-button-demo"
                            inputProps={{ 'aria-label': 'C' }}
                            />}
                        label="제3자 개인정보 제공에 동의합니다.(선택)"
                        labelPlacement="end"
                        /> <Button className="buttons" style={{fontFamily: 'TmoneyRoundWindExtraBold'}} variant="outlined" >제3자 개인정보 약관 읽기</Button>
                </div> */}
          </div>
    </div>
  );
}
export default Items2;