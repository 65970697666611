import { AnyAction } from "redux"

export interface ERState{
    totalMass: number[];
    user: string[];
    info: object[];
    isOn: boolean;
}
const initialState = {
    totalMass : [],
    user: [],
    info: [{}],
    isOn: false
}

// type Action = {type: "CALC_MASS" , payload:number}

export const ERRReducer = (state:ERState = initialState,action:AnyAction) => {


    switch(action.type){
        case "CALC_MASS":{
            return{...state, totalMass:[...state.totalMass, action.payload]}
        }
        case "ADD_INFO":{
            return{...state, user:[...state.user, action.payload]}
        }
        case "FIND_USER":{
            return{...state, info:[...state.info, action.payload]}
        }
        case "ADD_WEIGHT":{
            return{...state, isOn: action.payload}
        }
        default:
            return state;
    }
} 

export default (ERRReducer)