import {
    Button,
    Icon,
    InputAdornment,
    makeStyles,
    TextField,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { auth, db } from '../../config/firebase';
import IPageProps from '../../interfaces/page';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import CropFreeIcon from '@material-ui/icons/CropFree';
import firebase from 'firebase/app';
import TextsmsIcon from '@material-ui/icons/Textsms';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

const useStyles = makeStyles({
    flexGrow: {
        flex: '1',
    },
    button: {
        fontSize: '13px',
        backgroundColor: '#fff',
        borderRadius: '1rem',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#FBAA13',
            color: '#07381B',
        },
    },
});

const SettingPage: React.FunctionComponent<IPageProps> = (props) => {
    const [username, setUsername] = useState(auth.currentUser?.displayName);
    const [Nname, setNName] = useState(auth.currentUser?.displayName);
    const [checked, setChecked] = useState<boolean>(false);

    const [messages, setMessages] = useState<any | null>(0);
    const history = useHistory();
    const userList = document.querySelector('.user');
    const setupUser = (data: any) => {
        data.forEach((doc: any) => {
            const user = doc.data();
        });
    };

    const [dispSetDone, setDispSetDone] = useState<boolean>(
        auth.currentUser?.displayName != undefined
    );

    db.collection('user')
        .get()
        .then((snapshot) => {
            setupUser(snapshot.docs);
        });
    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if (user?.displayName) {
                setDispSetDone(true);
            }
        });
    }, [username]);
    useEffect(() => {
        db.collection('user')
            .doc(auth.currentUser?.uid!)
            .get()
            .then((doc) => {
                if (doc.data()!) {
                    var inbox = doc.data()!.message;
                    var count = 0;
                    inbox!.forEach((data: any) => {
                        if (data.read === false) count += 1;
                    });
                    setMessages(count);
                    //finding unread messages counts
                }
            });
    }, []);

    const saveUserName = (name: string) => {
        if (auth.currentUser?.displayName === '카카오') {
            auth.currentUser
                ?.updateProfile({
                    displayName: name,
                })
                .then(function () {
                    console.log('Display name updated');
                })
                .catch(function (error) {
                    console.error(error);
                });
            db.collection('user').doc(auth.currentUser?.uid).update({
                nickname: name,
            });
            history.push('/provider_fill');
        } else {
            auth.currentUser
                ?.updateProfile({
                    displayName: name,
                })
                .then(function () {
                    console.log('Display name updated');
                })
                .catch(function (error) {
                    console.error(error);
                });
            db.collection('user').doc(auth.currentUser?.uid).update({
                nickname: name,
            });
            setDispSetDone(true);
        }
    };
    const classes = useStyles();
    const saveEdit = async () => {
        auth.currentUser
            ?.updateProfile({
                displayName: Nname,
            })
            .then(function () {
                console.log('Display name updated');
            })
            .catch(function (error) {
                console.error(error);
            });
        history.push('/setting');
    };

    const handleChange = (prop: string) => (event: any) => {
        if (prop == 'Nname_') {
            var nickname = event.target.value
                .toString()
                .replace(
                    /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi,
                    ''
                );
            if (nickname.length < 8) {
                setNName(nickname);
            } else if (nickname.length === 8) {
                setNName(nickname);
            }
        } else if (prop == 'check_') {
            db.collection('nickname')
                .doc('nicknameList')
                .get()
                .then((doc) => {
                    if (doc.exists) {
                        for (var i = 0; i < doc.data()!.list.length; i++) {
                            if (doc.data()!.list[i] === Nname) {
                                alert(
                                    '이미 사용중인 별명입니다. 다른 별명으로 작성해주세요.'
                                );
                                return;
                            }
                        }
                        setChecked(true);
                        alert('사용 가능합니다.');
                    }
                });
        } else {
            if (Nname === '') {
                alert('별명은 3~8자 사이로 설정해주세요.');
                return;
            } else {
                if (checked) {
                    saveEdit();
                    db.collection('user').doc(auth.currentUser?.uid!).update({
                        nickname: Nname,
                    });
                    db.collection('nickname')
                        .doc('nicknameList')
                        .update({
                            list: firebase.firestore.FieldValue.arrayUnion(
                                Nname
                            ),
                        });
                    setUsername(Nname);
                    setDispSetDone(true);
                } else {
                    alert('중복확인을 해주세요.');
                }
            }
        }
    };

    return (
        <div style={{ margin: '55px 20px 20px 20px' }}>
            <div className="Header_center fade">
                <div>
                    <img
                        id="pot"
                        className="img-logo"
                        src="./videhome_logo.png"
                    ></img>
                </div>
            </div>

            {dispSetDone ? (
                <div>
                    <div
                        id="pot2"
                        style={{
                            color: 'black',
                            fontFamily: 'Pretendard-Light',
                            padding: '0 0 15px 0',
                            fontSize: '20px',
                        }}
                    >
                        <p>안녕하세요, {username} 님</p>
                    </div>
                    <div
                        id="pot"
                        style={{ padding: '0 0 20px 0', width: '100%' }}
                    >
                        <Button
                            className={classes.button}
                            variant="outlined"
                            style={{
                                width: '65%',
                                padding: '10px',
                                margin: 'auto',

                                borderRadius: '10rem',
                                color: 'black',
                                fontFamily: 'Pretendard-Light',
                            }}
                            onClick={() => {
                                history.push('/myinfo_page');
                            }}
                        >
                            <PermIdentityIcon
                                fontSize="medium"
                                style={{
                                    position: 'absolute',
                                    display: 'inline',
                                    left: '22px',
                                }}
                            />
                            내정보
                        </Button>
                    </div>
                    <div id="pot" style={{ padding: '0 0 20px 0' }}>
                        <Button
                            className={classes.button}
                            variant="outlined"
                            onClick={() => {
                                history.push('/records');
                            }}
                            style={{
                                width: '65%',
                                padding: '10px',
                                margin: 'auto',

                                borderRadius: '10rem',
                                color: 'black',
                                fontFamily: 'Pretendard-Light',

                                textAlign: 'center',
                            }}
                        >
                            <AttachMoneyIcon
                                fontSize="small"
                                style={{
                                    position: 'absolute',
                                    display: 'inline',
                                    left: '22px',
                                }}
                            />
                            거래 내역
                        </Button>
                    </div>
                    <div id="pot" style={{ padding: '0 0 20px 0' }}>
                        <Button
                            className={classes.button}
                            variant="outlined"
                            onClick={() => {
                                history.push('/inbox');
                            }}
                            style={{
                                width: '65%',
                                padding: '10px',
                                margin: 'auto',

                                borderRadius: '10rem',
                                color: 'black',
                                fontFamily: 'Pretendard-Light',
                            }}
                        >
                            <TextsmsIcon
                                fontSize="small"
                                style={{
                                    position: 'absolute',
                                    display: 'inline',
                                    left: '22px',
                                }}
                            />
                            {messages !== 0 && (
                                <div className="notification">{messages}</div>
                            )}
                            메세지
                        </Button>
                    </div>
                    <div id="pot" style={{ padding: '0 0 20px 0' }}>
                        <Button
                            className={classes.button}
                            variant="outlined"
                            onClick={() => {
                                history.push('/QR');
                            }}
                            style={{
                                width: '65%',
                                padding: '10px',
                                margin: 'auto',

                                borderRadius: '10rem',
                                color: 'black',
                                fontFamily: 'Pretendard-Light',
                            }}
                        >
                            <CropFreeIcon
                                fontSize="small"
                                style={{
                                    position: 'absolute',
                                    display: 'inline',
                                    left: '22px',
                                }}
                            />
                            내 QR
                        </Button>
                    </div>
                    <div id="pot" style={{ padding: '0 0 20px 0' }}>
                        <Button
                            className={classes.button}
                            variant="outlined"
                            onClick={() => {
                                history.push('/change');
                            }}
                            style={{
                                width: '65%',
                                padding: '10px',
                                margin: 'auto',

                                borderRadius: '10rem',
                                color: 'black',
                                fontFamily: 'Pretendard-Light',
                            }}
                        >
                            <VpnKeyIcon
                                fontSize="small"
                                style={{
                                    position: 'absolute',
                                    display: 'inline',
                                    left: '22px',
                                }}
                            />
                            보안정보
                        </Button>
                    </div>
                    <div id="pot" style={{ padding: '0 0 20px 0' }}>
                        <Button
                            className={classes.button}
                            variant="outlined"
                            onClick={() => {
                                history.push('/logout');
                            }}
                            style={{
                                width: '65%',
                                padding: '10px',
                                margin: 'auto',

                                borderRadius: '10rem',
                                color: 'black',
                                fontFamily: 'Pretendard-Light',
                            }}
                        >
                            <ExitToAppIcon
                                fontSize="small"
                                style={{
                                    position: 'absolute',
                                    display: 'inline',
                                    left: '22px',
                                }}
                            />
                            로그아웃
                        </Button>
                    </div>
                </div>
            ) : (
                <div>
                    <div
                        id="pot2"
                        style={{
                            color: 'black',

                            padding: '0 0 15px 0',
                            fontSize: '20px',
                        }}
                    >
                        <p>처음 오셨군요!</p>
                        <p>별명을 작성해주세요.</p>
                    </div>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <div style={{ width: '100%' }}>
                            <TextField
                                autoComplete="new-password"
                                name="confirm"
                                id="confirm"
                                disabled={checked}
                                style={{
                                    top: '2px',
                                    marginBottom: '25px',
                                    color: 'black',
                                }}
                                placeholder="별명을 입력해주세요"
                                type="input"
                                value={Nname}
                                onChange={handleChange('Nname_')}
                                label="별명"
                                inputProps={{
                                    style: {
                                        textAlign: 'left',
                                        fontSize: '19px',
                                        fontFamily: 'Pretendard-Light',
                        
                                    },
                                    'aria-label': 'naked',
                                    startAdornment: (
                                        <InputAdornment position="start"></InputAdornment>
                                    ),
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontSize: '15px',
                                        fontFamily: 'Pretendard-Light',
                                  
                                    },
                                }}
                            />
                        </div>
                        {!checked && (
                            <div
                                style={{
                                    display: 'flex',
                                    width: '50%',
                                }}
                            >
                                <Button
                                    className={classes.button}
                                    variant="outlined"
                                    style={{
                                        width: '100%',
                                        padding: '5px 0px',
                                        margin: 'auto',

                                        color: 'black',
                                        fontFamily: 'Pretendard-Light',
                                    }}
                                    onClick={handleChange('check_')}
                                >
                                    중복확인
                                </Button>
                            </div>
                        )}
                    </div>

                    <div
                        id="pot"
                        style={{ padding: '0 0 20px 0', width: '100%' }}
                    >
                        <Button
                            className={classes.button}
                            variant="outlined"
                            style={{
                                width: '50%',
                                padding: '5px',
                                margin: 'auto',

                                borderRadius: '10rem',
                                color: 'black',
                                fontFamily: 'Pretendard-Light',
                            }}
                            onClick={handleChange('Nname_set')}
                        >
                            저장하기
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SettingPage;
