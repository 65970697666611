import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { auth, db } from '../../config/firebase';
import IPageProps from '../../interfaces/page';
import { Button } from '@material-ui/core';
import { useEffect } from 'react';
import QRCode from 'react-qr-code';

const QRPage: React.FunctionComponent<IPageProps> = (props) => {
    const [email, setEmail] = useState<string>('');
    const [orders, setOrders] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [uid, setUid] = useState<string>('');

    const history = useHistory();
    const [editON, setEditON] = useState<boolean>(false);

    const goBack = () => {
        history.push('/setting');
    };

    const [authDataChanged, setAuthDataChanged] = useState<any | null>(null);
    
    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            setAuthDataChanged(user);
        });
    }, []);

    
    useEffect(() => {
        if (auth && auth.currentUser?.email != undefined) {
            db.collection('user')
                .doc(auth.currentUser?.uid!)
                .get()
                .then((doc) => {
                    if (doc.exists) {
                        setName(doc.data()!.name);
                        setEmail(doc.data()!.userId);
                        setOrders(doc.data()!.orders);
                        setUid(auth.currentUser?.uid!);
                    }
                });
        }
    }, [authDataChanged]);

    return (
        <div
            className="fade"
            style={{ margin: 'auto', width: 'fit-content', paddingTop: '60px' }}
        >
               {(authDataChanged)?
                    <div
                        style={{ border: 'solid 5px', padding: '10px', color: 'black' }}
                    >
                
                        <QRCode
                            id="QRCode"
                            title="Custom Title"
                            value={auth.currentUser?.uid!}
                        />
        
                    </div>
                      :<>로그인 해주세요.</>}
            <Button
                disabled={editON}
                variant="outlined"
                onClick={() => goBack()}
                style={{
                    width: '50%',
                    padding: '3px',
                    margin: '40px',
                    minWidth: '30px',
                    border: 'solid 2px',
                    borderRadius: '10rem',
                    fontFamily: 'EliceDigitalBaeum_Bold',
                }}
            >
                뒤로가기
            </Button>
        </div>
    );
};

export default QRPage;
