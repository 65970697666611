import QRCode from "react-qr-code";
import IRoute from "../interfaces/route";
import ChangePasswordPage from "../pages/auth/change";
import ForgotPasswordPage from "../pages/auth/forgot";
import LoginPage from "../pages/auth/login";
import LogoutPage from "../pages/auth/logout";
import MyInfoPage from "../pages/auth/MyPage";
import ProviderFillPage from "../pages/auth/providerFill";
import QRPage from "../pages/auth/qrCodeGen";
import RecordPage from "../pages/auth/records";
import RegisterPage from "../pages/auth/register";
import ResetPasswordPage from "../pages/auth/reset";
import HomePage from "../pages/home";
import ApplyPage from "../pages/tabs/Collect";
import TransactionPage from "../pages/tabs/Receipt"
import BulletinBoardPage from "../pages/tabs/Bulletin_board"
import LocationPage from "../pages/tabs/Direction"
import InboxPage from "../pages/auth/inbox";
import SettingPage from "../pages/auth/setting";
import CompanyInfoPage from '../pages/tabs/CompanyInfo';
import DIYPage from '../pages/tabs/DIY';
import NeedPage from '../pages/tabs/Need';
import QnAPage from "../pages/tabs/Q&A";

const routes: IRoute[] = [
    {
        path: '/',
        exact: true,
        component: HomePage,
        name: 'Home Page',
        protected: false
    },
    {
        path: '/home',
        exact: true,
        component: HomePage,
        name: 'Home Page',
        protected: false
    },
    {
        path: '/setting',
        exact: true,
        component: SettingPage,
        name: '나의 정보',
        protected: true
    },
    {
        path: '/register',
        exact: false,
        component: RegisterPage,
        name: 'Register Page',
        protected: false
    },
    {
        path: '/login',
        exact: false,
        component: LoginPage,
        name: 'Login Page',
        protected: false
    },
    {
        path: '/provider_fill',
        exact: true,
        component: ProviderFillPage,
        name: 'Provider Fill Page',
        protected: false
    },
    {
        path: '/myinfo_page',
        exact: true,
        component: MyInfoPage,
        name: '내 정보',
        protected: false
    },
    {
        path: '/records',
        exact: true,
        component: RecordPage,
        name: '내 거래내역',
        protected: false
    },
    {
        path: '/QR',
        exact: true,
        component: QRPage,
        name: '내 QR',
        protected: false
    },
    {
        path: '/inbox',
        exact: true,
        component: InboxPage,
        name: '내 메세지',
        protected: false
    },
    {
        path: '/apply',
        exact: true,
        component: ApplyPage,
        name: '판매 신청',
        protected: true
    },
    {
        path: '/company_info',
        exact: true,
        component: CompanyInfoPage,
        name: '사업내용',
        protected: false
    },
    {
        path: '/how_to_apply',
        exact: true,
        component: DIYPage,
        name: '신청방법',
        protected: false
    },
    {
        path: '/need',
        exact: true,
        component: NeedPage,
        name: '필요해요',
        protected: true
    },
    {
        path: '/transaction',
        exact: true,
        component: TransactionPage,
        name: '거래 내역',
        protected: false
    },
    {
        path: '/bulletin_board',
        exact: true,
        component: BulletinBoardPage,
        name: '게시판',
        protected: false
    },
    {
        path: '/direction',
        exact: true,
        component: LocationPage,
        name: '오시는길',
        protected: false
    },
    {
        path: '/Q&A',
        exact: true,
        component: QnAPage,
        name: 'QnA Page',
        protected: false
    },
    {
        path: '/change',
        exact: true,
        component: ChangePasswordPage,
        name: 'Change Password Page',
        protected: true
    },
    {
        path: '/logout',
        exact: true,
        component: LogoutPage,
        name: 'Logout Page',
        protected: true
    },
    {
        path: '/forget',
        exact: true,
        component: ForgotPasswordPage,
        name: 'Forgot Password Page',
        protected: false
    },
    {
        path: '/reset',
        exact: true,
        component: ResetPasswordPage,
        name: 'Reset Password Page',
        protected: false
    }
];

export default routes;
