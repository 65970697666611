import React, { useEffect, useState } from 'react';
import './button.css';
import LocalDrinkIcon from '@material-ui/icons/LocalDrink';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import {
    createStyles,
    lighten,
    makeStyles,
    Theme,
} from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { auth, db } from '../config/firebase';
import {
    Button,
    FormControl,
    InputAdornment,
    TextField,
} from '@material-ui/core';
import './button.css';
import { useHistory } from 'react-router-dom';
import { Alert } from '@mui/material';
// READY
interface Data {
    confirmed: string;
    weight: number;
    additional: number;
    date: string;
    // total:number
}
// function createData(
//     date: string,
//     confirmed: string,
//     weight: number,
//     additional: number
//     // total:number
// ): Data {
//     return { date, confirmed, weight, additional };
// }
const rows: Data[] = [];

// function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
//     if (b[orderBy] < a[orderBy]) {
//         return -1;
//     }
//     if (b[orderBy] > a[orderBy]) {
//         return 1;
//     }
//     return 0;
// }

type Order = 'asc' | 'desc';

// function getComparator<Key extends keyof any>(
//     order: Order,
//     orderBy: Key
// ): (
//     a: { [key in Key]: number | string },
//     b: { [key in Key]: number | string }
// ) => number {
//     return order === 'desc'
//         ? (a, b) => descendingComparator(a, b, orderBy)
//         : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
//     const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
//     stabilizedThis.sort((a, b) => {
//         const order = comparator(a[0], b[0]);
//         if (order !== 0) return order;
//         return a[1] - b[1];
//     });
//     return stabilizedThis.map((el) => el[0]);
// }

// interface HeadCell {
//     disablePadding: boolean;
//     id: keyof Data;
//     label: string;
//     numeric: boolean;
// }

// const headCells: HeadCell[] = [
//     { id: 'date', numeric: false, disablePadding: true, label: '날짜' },
//     { id: 'confirmed', numeric: false, disablePadding: false, label: '접수' },
//     { id: 'weight', numeric: false, disablePadding: false, label: '무게' },
//     {
//         id: 'additional',
//         numeric: false,
//         disablePadding: false,
//         label: '합계금액',
//     },
//     // { id: 'total', numeric: true, disablePadding: false, label: '총' },
// ];

interface EnhancedTableProps {
    classes: ReturnType<typeof useStyles>;
    numSelected: number;
    onRequestSort: (
        event: React.MouseEvent<unknown>,
        property: keyof Data
    ) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

// function EnhancedTableHead(props: EnhancedTableProps) {
//     const {
//         classes,
//         onSelectAllClick,
//         order,
//         orderBy,
//         numSelected,
//         rowCount,
//         onRequestSort,
//     } = props;
//     const createSortHandler = (property: keyof Data) => (
//         event: React.MouseEvent<unknown>
//     ) => {
//         onRequestSort(event, property);
//     };

//     return (
//         <TableHead>
//             <TableRow>
//                 <TableCell padding="checkbox">
//                     <Checkbox
//                         indeterminate={
//                             numSelected > 0 && numSelected < rowCount
//                         }
//                         checked={rowCount > 0 && numSelected === rowCount}
//                         onChange={onSelectAllClick}
//                         inputProps={{ 'aria-label': 'select all desserts' }}
//                     />
//                 </TableCell>
//                 {headCells.map((headCell) => (
//                     <TableCell
//                         key={headCell.id}
//                         align={headCell.numeric ? 'right' : 'left'}
//                         padding={headCell.disablePadding ? 'none' : 'default'}
//                         sortDirection={orderBy === headCell.id ? order : false}
//                         style={{
//                             minWidth: '60px',
//                             textAlign: 'center',
//                 fontFamily: "Pretendard-Light",
//                             fontSize: '13px',
//                             padding: '0',
//                         }}
//                     >
//                         <TableSortLabel
//                             active={orderBy === headCell.id}
//                             direction={orderBy === headCell.id ? order : 'asc'}
//                             onClick={createSortHandler(headCell.id)}
//                         >
//                             {headCell.label}
//                             {orderBy === headCell.id ? (
//                                 <span className={classes.visuallyHidden}>
//                                     {order === 'desc'
//                                         ? 'sorted descending'
//                                         : 'sorted ascending'}
//                                 </span>
//                             ) : null}
//                         </TableSortLabel>
//                     </TableCell>
//                 ))}
//             </TableRow>
//         </TableHead>
//     );
// }

// const useToolbarStyles = makeStyles((theme: Theme) =>
//     createStyles({
//         root: {
//             paddingLeft: theme.spacing(2),
//             paddingRight: theme.spacing(1),
//             width: '100%',
//         },
//         highlight:
//             theme.palette.type === 'light'
//                 ? {
//                       color: 'black',
//                       backgroundColor: lighten('#FBAA13', 0.9),
//                   }
//                 : {
//                       color: theme.palette.text.primary,
//                       backgroundColor: theme.palette.secondary.dark,
//                   },
//         title: {
//             flex: '1 1 100%',
//         },
//     })
// );

// interface EnhancedTableToolbarProps {
//     numSelected: number;
// }

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            margin: 'auto',
            padding: '30px 0px 0px 0px',
            border: 'none',
            overflowX: 'hidden',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(3),
            boxShadow: 'none',
            border: 'none',
        },
        table: {
            minWidth: 300,
            padding: '0px',
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 10,
            width: 1,
fontFamily: "Pretendard-Light",
        },
    })
);
//READY

function Buttons2() {
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('date');
    const [selected, setSelected] = React.useState<string[]>([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    //newData
    const [PID, setPID] = useState();
    const [loggedIn, setLoggedIn] = useState(false);
    const [found, setFound] = useState(false);
    const [orderHistory, setOrderHistory] = useState<any>([]);

    const [currentWeight, setCurrentWeight] = useState<any>();
    const [currentAdditional, setCurrentAdditional] = useState<any>();
    const [userTotalWeight, setUserTotalWeight] = useState<any>();
    const [userAverageWeight, setUserAverageWeight] = useState<any>();

    //progress
    const [progress, setProgress] = useState<any>();
    const [date, setDate] = useState<any>();
    const [address, setAddress] = useState<any>();
    const [time, setTime] = useState<any>();
    const [countKG, setCountKG] = useState<any>(0);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if (user) {
                db.collection('user')
                    .doc(auth.currentUser?.uid!)
                    .get()
                    .then((doc) => {
                        if (doc.exists) {
                            if (doc.data()!.orders.length >= 1) {
                                if (
                                    doc.data()!.userId ===
                                    auth.currentUser?.email!
                                ) {
                                    var count = 0;

                                    var kgList = doc.data()!.orders[
                                        doc.data()!.numberOfOrders - 1
                                    ].category;

                                    Object.keys(kgList).map((key: any) => {
                                        count =
                                            Number(count) + Number(kgList[key]);
                                    });
                                    setCountKG(count);
                                    setPID(doc.data()!.phone);
                                    setLoggedIn(true);
                                    setOrderHistory(doc.data()!.orders);
                                    setCurrentWeight(
                                        doc.data()!.orders[
                                            doc.data()!.numberOfOrders - 1
                                        ].weight
                                    );
                                    setProgress(
                                        doc.data()!.orders[
                                            doc.data()!.numberOfOrders - 1
                                        ].confirmed
                                    );
                                    setDate(
                                        doc.data()!.orders[
                                            doc.data()!.numberOfOrders - 1
                                        ].date
                                    );
                                    setTime(
                                        doc.data()!.orders[
                                            doc.data()!.numberOfOrders - 1
                                        ].time
                                    );
                                    setAddress(doc.data()!.address);
                                    setCurrentAdditional(
                                        doc.data()!.orders[
                                            doc.data()!.numberOfOrders - 1
                                        ].additional
                                    );
                                    setFound(true);
                                    setUserTotalWeight(doc.data()!.totalWeight);
                                    setUserAverageWeight(
                                        doc.data()!.averageWeights
                                    );
                                }
                            } else {
                                setPID(doc.data()!.phone);
                                setLoggedIn(true);
                                setFound(true);
                                console.log('user has no documents.');
                            }
                        }
                    });
            } else {
                console.log('User Not Found. Proceed with non_user');
            }
        });
        if (!auth.currentUser?.displayName) {
            db.collection('user')
                .doc('non_user')
                .get()
                .then((doc) => {
                    doc.data()!.orders.forEach((showing: any) => {
                        if (PID === showing.phone) {
                            var count = 0;

                            var kgList = showing.category;

                            Object.keys(kgList).map((key: any) => {
                                count = Number(count) + Number(kgList[key]);
                            });
                            setCountKG(count);
                            setOrderHistory(showing);
                            setCurrentWeight(showing!.weight);
                            setCurrentAdditional(showing!.additional);
                            setDate(showing!.date);
                            setTime(showing!.time);
                            setProgress(showing!.confirmed);
                            setAddress(showing!.address);
                        }
                    });
                });
        }
    }, [PID]);
    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.date);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected.slice(1), name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        }

        setSelected(newSelected);
    };

    const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDense(event.target.checked);
    };

    const isSelected = (name: string) => selected.indexOf(name) !== -1;

    const emptyRows =
        rowsPerPage -
        Math.min(rowsPerPage, orderHistory.length - page * rowsPerPage);

    const findInfo = () => {
        if (PID) {
            db.collection('user')
                .get()
                .then((querySnapshot) => {
                    const documents = querySnapshot.docs.map((doc) =>
                        doc.data()
                    );
                    var list: any[] = [];
                    documents.forEach((doc: any) => {
                        // setOrderHistory([...doc])
                        list.push(doc);
                    });

                    const filtered = list.filter(
                        (order) => order.phone! === PID
                    );

                    if (filtered.length != 0) {
                        setOrderHistory([...filtered[0].orders]);
                        setFound(true);
                    } else {
                        list.forEach((doc: any) => {
                            if (doc.count) {
                                const filtered2 = doc.orders!.filter(
                                    (order: any) => order.phone! === PID
                                );

                                if (filtered2.length != 0) {
                                    setOrderHistory([...filtered2!]);
                                    setFound(true);
                                } else {
                                    alert(
                                        '회원번호 또는 신청번호가 존재하지 않습니다. 다시입력해 주세요.'
                                    );
                                    setFound(false);
                                }
                            }
                        });
                    }
                });
        } else {
            alert(
                '회원번호 또는 신청번호가 존재하지 않습니다. 다시입력해 주세요.'
            );
            setFound(false);
        }
    };
    const history = useHistory();
    const goBack = () => {
        history.push('/setting');
    };
    const formatNumber = (inputNumber: number) => {
        let formatedNumber = Number(inputNumber)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,');
        let splitArray = formatedNumber.split('.');
        if (splitArray.length > 1) {
            formatedNumber = splitArray[0];
        }
        return formatedNumber;
    };

    // const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
    //     const classes = useToolbarStyles();
    //     const { numSelected } = props;

    //     return (
    //         <Toolbar
    //             className={clsx(classes.root, {
    //                 [classes.highlight]: numSelected > 0,
    //             })}
    //             style={{ padding: '10px 0', fontSize: '20px' }}
    //         >
    //             {numSelected > 0 ? (
    //                 <Typography
    //                     style={{ fontFamily: 'EliceDigitalBaeum_Bold' }}
    //                     className={classes.title}
    //                     color="inherit"
    //                     variant="subtitle1"
    //                     component="div"
    //                 >
    //                     {numSelected} 개 선택
    //                 </Typography>
    //             ) : (
    //                 <Typography
    //                     style={{ fontFamily: 'EliceDigitalBaeum_Bold' }}
    //                     className={classes.title}
    //                     variant="h6"
    //                     id="tableTitle"
    //                     component="div"
    //                 >
    //                     정산 내역
    //                 </Typography>
    //             )}
    //             {numSelected > 0 ? (
    //                 <Tooltip title="신청서 보기">
    //                     <IconButton
    //                         aria-label="delete"
    //                         onClick={() => {
    //                             showModal();
    //                         }}
    //                     >
    //                         <SearchIcon />
    //                     </IconButton>
    //                 </Tooltip>
    //             ) : (

    //                 <></>
    //             )}
    //         </Toolbar>
    //     );
    // };
    const handleChange = (prop: string) => (event: any) => {
        if (prop == 'pid_') {
            const onlyNums = event.target.value.replace(/[^0-9]/g, '');
            if (onlyNums.length < 11) {
                setPID(onlyNums);
            } else if (onlyNums.length === 11) {
                const number = onlyNums.replace(
                    /(\d{3})(\d{4})(\d{4})/,
                    '($1) $2-$3'
                );
                setPID(number);
            }
        }
    };
    // const showModal = () => {
    //     if (selected.length != 0) {
    //         const filtered = orderHistory.filter(
    //             (order) => order.date == selected[0]
    //         );
    //         setSelectedOrder(filtered[0]);
    //         setCurrentWeight(filtered[0].weight);
    //         setCurrentAdditional(filtered[0].additional);
    //     } else {
    //         alert('판매내역을 선택해주세요');
    //     }
    //     // setUserAverageWeight(orderHistory[0].)
    // };

    var [step, setStep] = useState('step0');
    var step1 = document.getElementById('step1');
    var step2 = document.getElementById('step2');
    var step3 = document.getElementById('step3');
    var step4 = document.getElementById('step4');
    window.addEventListener('load', () => {
        step1 = document.getElementById('step1');
        step2 = document.getElementById('step2');
        step3 = document.getElementById('step3');
        step4 = document.getElementById('step4');
    });

    const delay = (ms: any) => new Promise((res: any) => setTimeout(res, ms));
    const next = async () => {
        if (step1) {
            if (step === 'step1') {
                step1!.classList.remove('is-active');
                step1!.classList.add('is-complete');
                step2!.classList.add('is-active');
            } else if (step === 'step2') {
                step1!.classList.remove('is-active');
                step2!.classList.remove('is-active');
                step1!.classList.add('is-complete');
                await delay(150);
                step2!.classList.add('is-complete');
                step3!.classList.add('is-active');
            } else if (step === 'step3') {
                step1!.classList.remove('is-active');
                step2!.classList.remove('is-active');
                step3!.classList.remove('is-active');
                step1!.classList.add('is-complete');
                await delay(150);
                step2!.classList.add('is-complete');
                await delay(150);
                step3!.classList.add('is-complete');
                await delay(150);
                step4!.classList.add('is-active');
            } else if (step === 'step4') {
                step1!.classList.remove('is-active');
                step1!.classList.add('is-complete');
                step2!.classList.remove('is-active');
                step3!.classList.remove('is-active');
                await delay(150);
                step2!.classList.add('is-complete');
                await delay(150);
                step3!.classList.add('is-complete');
                await delay(150);
                step4!.classList.add('is-active');

                step4!.classList.add('is-complete');
            } else if (step === 'complete') {
                // setStep('step1');
                // step4!.classList.remove('is-complete');
                // step3!.classList.remove('is-complete');
                // step2!.classList.remove('is-complete');
                // step1!.classList.remove('is-complete');
                // step1!.classList.add('is-active');
            }
        }
    };
    useEffect(() => {
        if (progress === '미확인') {
            setStep('step1');
        } else if (progress === '확인') {
            setStep('step2');
        } else if (progress === '방문') {
            setStep('step3');
        } else if (progress === '완료') {
            setStep('step4');
        }
    }, [progress]);
    useEffect(() => {
        if (step1) {
            next();
        }
    }, [step1]);

    return (
        <div style={{ width: '100%', position: 'relative' }}>
            {isLoading && (
                <div className="loader-wrapper">
                    <span className="loader">
                        <span className="loader-innder"></span>
                    </span>
                    <span className="loader_text">로그인 중입니다..</span>
                </div>
            )}
            {found ? (
                <div className="PIDON fade">
                    <div
                        style={{
                            width: '100%',
                            position: 'relative',
                            padding: '35px 0 10px 0',
                        }}
                    >
                        <div
                            style={{
                                width: '100%',
                                position: 'relative',
                                color: 'black',
                            }}
                        >
                            <div
                                style={{
                        fontFamily: "Pretendard-Light",
                                    fontSize: '20px',
                                    color: 'black',
                                    width: '100%',
                                    justifyContent: 'center',
                                    margin: '0 0 20px 0',
                                }}
                                id="pot2"
                            >
                                <img
                                    id="pot"
                                    className="img-announce"
                                    src="../videhome_recent2.png"
                                ></img>
                            </div>
                            {/* {date} 최근 거래내역 */}
                        </div>
                        <div
                            style={{
                    fontFamily: "Pretendard-Light",
                            }}
                            id="pot2"
                        >
                                                   {(step === "step0")? 
                        <Alert severity="info" style={{  fontFamily: 'EliceDigitalBaeum_Bold', background:"rgb(229, 246, 253)"}}>
                        최근거래내역이 없습니다 </Alert>:<></>}
                            {step === 'step1' ? (
                                <Alert
                                    severity="info"
                                    style={{
                            fontFamily: "Pretendard-Light",
                                        background: 'rgb(229, 246, 253)',
                                    }}
                                >
                                    승인완료가 될때까지 잠시만 기다려주세요!{' '}
                                </Alert>
                            ) : (
                                <></>
                            )}
                            {step === 'step2' ? (
                                <Alert
                                    severity="info"
                                    style={{
                            fontFamily: "Pretendard-Light",
                                        background: 'rgb(229, 246, 253)',
                                    }}
                                >
                                    예약당일날 비드홈 기사님이 직접 찾아뵙니다!{' '}
                                </Alert>
                            ) : (
                                <></>
                            )}
                                   {(step === "step3")? 
                        <Alert severity="info" style={{  fontFamily: 'EliceDigitalBaeum_Bold', background:"rgb(229, 246, 253)"}}>
                        정산 상세내역은 메세지함에서 확인해주세요! </Alert>:<></>}
                        {(step === "step4")? 
                        <Alert severity="info" style={{  fontFamily: 'EliceDigitalBaeum_Bold', background:"rgb(229, 246, 253)"}}>
                        송금을 완료하였습니다! 감사합니다! </Alert>:<></>}
                        </div>
                        <div className="progress">
                            <div id="step1" className="progress-step is-active">
                                <div className="progress-track"></div>
                                <div>
                                    {step === 'step0' ? '신청준비' : '신청완료'}
                                </div>
                                <div style={{ paddingTop: '10px' }}>
                                    {step === 'step0' ? (
                                        <img
                                            className="img-fluid"
                                            src={'../run.gif'}
                                            alt=""
                                            key="1"
                                            id="pot3"
                                        ></img>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>

                            <div id="step2" className="progress-step">
                                <div>
                                    {step === 'step2' ||
                                    step === 'step3' ||
                                    step === 'step4'
                                        ? '접수완료'
                                        : '접수준비'}
                                </div>
                                <div style={{ paddingTop: '10px' }}>
                                    {step === 'step1' ? (
                                        <img
                                            className="img-fluid"
                                            src={'../run.gif'}
                                            alt=""
                                            key="1"
                                            id="pot3"
                                        ></img>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>

                            <div id="step3" className="progress-step">
                                <div>
                                    {step === 'step3' || step === 'step4'
                                        ? '수거/무게금액'
                                        : '수거/무게금액'}
                                </div>
                                <div>
                                    {step === 'step3' || step === 'step4'
                                        ? '입금완료'
                                        : '입금준비'}
                                </div>
                                <div style={{ paddingTop: '10px' }}>
                                    {step === 'step2' ? (
                                        <img
                                            className="img-fluid"
                                            src={'../run.gif'}
                                            alt=""
                                            key="1"
                                            id="pot3"
                                        ></img>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>

                            <div id="step4" className="progress-step">
                                <div>
                                    {step === 'step4' ? '추가금액' : '추가금액'}
                                </div>
                                <div>
                                    {step === 'step4' ? '입금완료' : '입금준비'}
                                </div>
                                <div style={{ paddingTop: '10px' }}>
                                    {step === 'step3' || step === 'step4' ? (
                                        <img
                                            className="img-fluid fade"
                                            src={'../run.gif'}
                                            alt=""
                                            key="1"
                                        ></img>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <Button className="buttons" style={{fontFamily: 'EliceDigitalBaeum_Bold', padding:"4px"}} variant="outlined" onClick={()=>{showModal()}} >선택</Button> */}
                    {/* 
                    <div
                        style={{
                            fontSize: '30px',
                            color: 'black',
                            padding: '10px',
                        }}
                    >
                        {date} 정산금액
                    </div> */}
                    {/* <div style={{color:"black", fontSize:"15px", textAlign:"left", padding:"0 0 10px 10px"}}>
                                    <div>날짜: {date.slice(-5)}</div>
                                    <div>시간: {time}</div>
                                    <div>장소: {address}</div>
                                </div> */}
                    <div
                        className="total"
                        style={{
                            padding: '0 0 0 0',
                            width: '100%',
                            fontSize: '20px',
                        }}
                    >
                        <div className="totalCate">
                            <div
                                className="totalCate_name"
                                style={{ fontWeight: 'bolder' }}
                            >
                                무게금액
                            </div>
                            <div className="totalCate_price">
                                {(progress === '미확인' || progress ==="확인" || progress === undefined) ? (
                                    <>정산중</>
                                ) : typeof currentWeight === typeof Number ? (
                                    <>{currentWeight! + ' 원'}</>
                                ) : (
                                    <>{formatNumber(currentWeight!) + ' 원'}</>
                                )}
                            </div>
                        </div>
                        <div className="totalCate">
                            <div
                                className="totalCate_name"
                                style={{ fontWeight: 'bolder' }}
                            >
                                추가금액
                            </div>
                            <div className="totalCate_price">
                            {(progress === '미확인' || progress ==="확인" || progress === undefined) ? (
                                    <>정산중</>
                                ) : typeof currentWeight === typeof Number ? (
                                    <>{currentAdditional! + ' 원'}</>
                                ) : (
                                    <>
                                        {formatNumber(currentAdditional!) +
                                            ' 원'}
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="totalCate">
                            <div
                                className="totalCate_name"
                                style={{ fontWeight: 'bolder' }}
                            >
                                평균금액
                            </div>
                            <div className="totalCate_price">
                                <>
                                    {' '}
                                    {!(progress === '미확인' || progress ==="확인" || progress === undefined)? (
                                        formatNumber(
                                            Math.round(
                                                (Number(currentWeight!) +
                                                    Number(
                                                        currentAdditional!
                                                    )) /
                                                    Number(countKG!)
                                            )
                                        ) + ' 원/KG'
                                    ) : (
                                        <>정산중</>
                                    )}
                                </>
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                fontFamily: "Pretendard-Light",
                            fontSize: '20px',
                            color: 'black',
                            width: '100%',
                            justifyContent: 'center',
                            margin: '10px 0 10px 0',
                        }}
                        id="pot2"
                    >
                        <img
                            id="pot"
                            className="img-announce"
                            src="../recycle.png"
                        ></img>
                    </div>
                    <div
                        style={{
                            width: '95%',
                            position: 'relative',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-evenly',
                            alignContent: 'center',
                            color: 'black',
                            margin: 'auto',
                            padding: '0 0 30px 0',
                        }}
                    >
                        <div className="wrapper">
                            <div
                                className="ER_wrapper"
                                style={{ width: '100%' }}
                            >
                                <div className="col1">
                                    <div className="row">
                                        <div className="icons">
                                            <LocalDrinkIcon
                                                color="primary"
                                                fontSize="large"
                                            />
                                        </div>{' '}
                                        {currentWeight != undefined ? (
                                            <div
                                                style={{
                                                    color: 'green',
                                                    fontWeight: 'bold',
                                                    borderBottom: 'solid 2px',
                                                }}
                                            >
                                                {Math.round(
                                                    countKG! * 0.24 * 1000
                                                ) + ' L'}
                                                <br />
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                    <div
                                        className="row"
                                        style={{ top: '14px' }}
                                    >
                                        생수절약
                                    </div>
                                    <div
                                        className="row"
                                        style={{
                                            borderBottom: 'none',
                                            fontSize: '12px',
                                        }}
                                    >
                                        <div>WATER</div>
                                        <div>SAVING</div>
                                    </div>
                                </div>
                                <div className="col2">
                                    <div className="row">
                                        <div
                                            className="icons"
                                            style={{ color: 'red' }}
                                        >
                                            <DirectionsCarIcon fontSize="large" />
                                        </div>{' '}
                                        {currentWeight != undefined ? (
                                            <div
                                                style={{
                                                    color: 'green',
                                                    fontWeight: 'bold',
                                                    borderBottom: 'solid 2px',
                                                }}
                                            >
                                                {Math.round(countKG! * 12) +
                                                    ' Km'}
                                                <br />
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                    <div
                                        className="row"
                                        style={{ top: '14px' }}
                                    >
                                        탄소절약
                                    </div>
                                    <div
                                        className="row"
                                        style={{
                                            borderBottom: 'none',
                                            fontSize: '12px',
                                        }}
                                    >
                                        <div>CARBON</div>
                                        <div>SAVING</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            style={{
                                fontSize: '10px',
                                opacity: 0.4,
                                padding: '10px',
                                textAlign: 'left',
                            }}
                        >
                            환경절약 수치는 예시 자료 이며 향후 정확한 데이터를
                            반영 하기위해 준비 하고있습니다.
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
            <div style={{ padding: '0 0 20px 0' }}>
                <Button
                    variant="outlined"
                    onClick={() => goBack()}
                    style={{
                        width: '40%',
                        margin: 'auto',
                        minWidth: '30px',
                        border: 'solid 2px',
                        borderRadius: '10rem',
            fontFamily: "Pretendard-Light",
                    }}
                >
                    뒤로가기
                </Button>
            </div>

            {!found ? (
                <div className="fade">
                    <div
                        style={{
                            width: '80%',
                            display: 'flex',
                            paddingTop: '30px',
                            margin: 'auto',
                        }}
                    >
                        <FormControl
                            style={{
                                width: '100%',
                                color: 'black',
                                paddingTop: '30px',
                            }}
                        >
                            <TextField
                                style={{ top: '8px', marginBottom: '25px' }}
                                id="input-with-icon-TextField"
                                label="전화번호/신청번호"
                                placeholder=" 전화번호 또는 신청번호 입력"
                                disabled={found}
                                InputLabelProps={{
                                    style: {
                                        fontSize: '25px',
                            fontFamily: "Pretendard-Light",
                                        color: 'black',
                                    },
                                }}
                                InputProps={{
                                    style: {
                                        fontSize: '16px',
                            fontFamily: "Pretendard-Light",
                                        padding: '10px 10px 0 0',
                                    },
                                    startAdornment: (
                                        <InputAdornment position="start"></InputAdornment>
                                    ),
                                }}
                                value={PID}
                                onChange={handleChange('pid_')}
                            />
                        </FormControl>
                    </div>
                    <Button
                        disabled={found}
                        style={{
                fontFamily: "Pretendard-Light",
                            width: '50%',
                            margin: 'auto',
                        }}
                        variant="outlined"
                        onClick={() => {
                            findInfo();
                        }}
                    >
                        확인하기
                    </Button>
                </div>
            ) : (
                <></>
            )}
        </div>
    );
}

export default Buttons2;
