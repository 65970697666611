import React, { useEffect, useState } from 'react';
import { Button, TextField } from '@material-ui/core';
import button from '../../components/button';
import items from '../../components/items';
import IPageProps from '../../interfaces/page';
import Carousels from '../../components/commercial';
import SearchBar from '../../components/searchBar';
import { useHistory } from 'react-router-dom';
import { db } from '../../config/firebase';

const Bulletin_board: React.FunctionComponent<IPageProps> = (props) => {
    const [currencyList, setCurrencyList] = useState<any>();
    const [currencyList2, setCurrencyList2] = useState<any>();
    const [listItems, setListItems] = useState<any>();
    const [listItems2, setListItems2] = useState<any>();
    const history = useHistory();
    const translate: any = {
        books: '책',
        steel: '고철',
        non_steel: '비철',
        fashion: '패션',
        clothes: '의류',
        shoes: '신발',
        bags: '가방',
    };
    useEffect(() => {
        db.collection('infos')
            .doc('currency')
            .get()
            .then((doc) => {
                setCurrencyList(doc.data()!.calculate!);
            });
        db.collection('infos')
            .doc('currency')
            .get()
            .then((doc) => {
                setListItems(
                    Object.keys(doc.data()!.calculate).map((key: any) => (
                        <>
                            <TextField
                                autoComplete="new-password"
                                name="confirm"
                                id="confirm"
                                style={{
                                    top: '2px',
                                    marginBottom: '25px',
                                    color: 'black',
                                }}
                                value={doc.data()!.calculate[key] + '원'}
                                label={translate[key] + ' :'}
                                inputProps={{
                                    style: {
                                        textAlign: 'center',
                                        fontSize: '19px',
                                        fontFamily: 'Cafe24Oneprettynight',
                                    },
                                    'aria-label': 'naked',
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontSize: '15px',
                                        fontFamily: 'TmoneyRoundWindExtraBold',
                                    },
                                }}
                            ></TextField>
                        </>
                    ))
                );
                setCurrencyList(doc.data()!.calculate);
            });
    }, []);
    useEffect(() => {
        db.collection('infos')
            .doc('currency')
            .get()
            .then((doc) => {
                setCurrencyList2(doc.data()!.calculate_offline!);
            });
        db.collection('infos')
            .doc('currency')
            .get()
            .then((doc) => {
                setListItems2(
                    Object.keys(doc.data()!.calculate_offline!).map(
                        (key: any) => (
                            <>
                                <TextField
                                    autoComplete="new-password"
                                    name="confirm"
                                    id="confirm"
                                    style={{
                                        top: '2px',
                                        marginBottom: '25px',
                                        color: 'black',
                                    }}
                                    value={
                                        doc.data()!.calculate_offline[key] +
                                        '원'
                                    }
                                    label={translate[key] + ' :'}
                                    inputProps={{
                                        style: {
                                            textAlign: 'center',
                                            fontSize: '19px',
                                            fontFamily: 'Cafe24Oneprettynight',
                                        },
                                        'aria-label': 'naked',
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            fontSize: '15px',
                                            fontFamily:
                                                'TmoneyRoundWindExtraBold',
                                        },
                                    }}
                                ></TextField>
                            </>
                        )
                    )
                );
                setCurrencyList2(doc.data()!.calculate);
            });
    }, []);
    const goBack = () => {
        history.push('/setting');
    };
    return (
        <div style={{}}>
            <div
                style={{
                    fontFamily: 'Pretendard-Light',
                    fontSize: '20px',
                    color: 'black',
                    width: '100%',
                    justifyContent: 'center',
                    margin: '35px 0 10px 0',
                }}
                id="pot2"
            >
                <SearchBar />
                <img
                    id="pot"
                    className="img-announce"
                    src="../announcement.png"
                ></img>
            </div>
            <div id="pot" className="Header_center">
                <Carousels />
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                }}
            >
                <div>
                    <div style={{ color: 'black', paddingBottom:"30px" }}>자택 수거 가격</div>
                    {listItems}
                </div>
                <div>
                    <div style={{ color: 'black', paddingBottom:"30px" }}>매장 방문 가격</div>
                    {listItems2}
                </div>
            </div>
            <div style={{ padding: '0px 0px 20px 0px' }}>
                <Button
                    variant="outlined"
                    onClick={() => goBack()}
                    style={{
                        width: '40%',
                        margin: 'auto',
                        minWidth: '30px',
                        border: 'solid 2px',
                        borderRadius: '10rem',
                        fontFamily: 'Pretendard-Light',
                    }}
                >
                    뒤로가기
                </Button>
            </div>
        </div>
    );
};
export default Bulletin_board;
