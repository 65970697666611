import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { auth, db } from '../../config/firebase';
import IPageProps from '../../interfaces/page';
import { Button, Link, TablePagination } from '@material-ui/core';
import { useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import SearchIcon from '@material-ui/icons/Search';
import clsx from 'clsx';
import {
    createStyles,
    lighten,
    makeStyles,
    Theme,
} from '@material-ui/core/styles';
import firebase from 'firebase/app';

//data table
interface Data {
    date: string;
    title: string;
    info: string;
    read: string;
    // total:number
}
function createData(
    date: string,
    title: string,
    info: string,
    read: string
    // total:number
): Data {
    return { date, title, info, read };
}
const rows: Data[] = [];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const headCells: HeadCell[] = [
    { id: 'date', numeric: false, disablePadding: true, label: '받은날짜' },
    { id: 'title', numeric: false, disablePadding: false, label: '제목' },
    { id: 'info', numeric: false, disablePadding: false, label: '내용' },
    {
        id: 'read',
        numeric: false,
        disablePadding: false,
        label: '읽음',
    },
    // { id: 'total', numeric: true, disablePadding: false, label: '총' },
];

interface EnhancedTableProps {
    classes: ReturnType<typeof useStyles>;
    numSelected: number;
    onRequestSort: (
        event: React.MouseEvent<unknown>,
        property: keyof Data
    ) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const {
        classes,
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
    } = props;
    const createSortHandler = (property: keyof Data) => (
        event: React.MouseEvent<unknown>
    ) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={
                            numSelected > 0 && numSelected < rowCount
                        }
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{
                            minWidth: '60px',
                            textAlign: 'center',
                            fontFamily: 'Pretendard-Light',
                            fontSize: '13px',
                            padding: '0',
                        }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc'
                                        ? 'sorted descending'
                                        : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const useToolbarStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
            width: '100%',
        },
        highlight:
            theme.palette.type === 'light'
                ? {
                      color: 'black',
                      backgroundColor: lighten('#FBAA13', 0.9),
                  }
                : {
                      color: theme.palette.text.primary,
                      backgroundColor: theme.palette.secondary.dark,
                  },
        title: {
            flex: '1 1 100%',
        },
    })
);

interface EnhancedTableToolbarProps {
    numSelected: number;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            margin: 'auto',
            padding: '30px 0px 0px 0px',
            border: 'none',
            overflowX: 'hidden',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(3),
            boxShadow: 'none',
            border: 'none',
        },
        table: {
            minWidth: 300,
            padding: '0px',
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 10,
            width: 1,
            fontFamily: 'Pretendard-Light',
        },
    })
);
//table close

const InboxPage: React.FunctionComponent<IPageProps> = (props) => {
    const [email, setEmail] = useState<string>('');
    const [orders, setOrders] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [uid, setUid] = useState<string>('');
    const [date, setDate] = useState<string>('');
    const [time, setTime] = useState<string>('');
    const [way, setWay] = useState<string>('');
    const [messageInbox, setMessageInbox] = useState<Data[]>([]);

    //table
    const [order, setOrder] = React.useState<Order>('desc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('date');
    const [selected, setSelected] = React.useState<string[]>([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    //rest
    const classes = useStyles();
    const [authDataChanged, setAuthDataChanged] = useState<any | null>(null);

    //mssg
    const [selectedMssg, setSelectedMssg] = useState<any>();

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            setAuthDataChanged(user);
            if (user) {
                db.collection('user')
                    .doc(auth.currentUser?.uid!)
                    .get()
                    .then((doc) => {
                        if (doc.exists) {
                            setMessageInbox(doc.data()!.message);
                        }
                    });
            }
        });
    }, []);

    useEffect(() => {
        if (auth && auth.currentUser?.email != undefined) {
            db.collection('user')
                .doc(auth.currentUser?.uid!)
                .get()
                .then((doc) => {
                    if (doc.exists) {
                        setName(doc.data()!.name);
                        setEmail(doc.data()!.userId);
                        setOrders(doc.data()!.orders);
                        setUid(auth.currentUser?.uid!);
                    }
                });
        }
    }, [authDataChanged]);

    //choosing functions
    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.date);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: string[] = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected.slice(1), name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        }

        setSelected(newSelected);
    };

    const isSelected = (name: string) => selected.indexOf(name) !== -1;

    const formatNumber = (inputNumber: number) => {
        let formatedNumber = Number(inputNumber)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,');
        let splitArray = formatedNumber.split('.');
        if (splitArray.length > 1) {
            formatedNumber = splitArray[0];
        }
        return formatedNumber;
    };
    const history = useHistory();

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const goBack = () => {
        history.push('/setting');
    };
    const clearOut = () => {
        var clearing = window.confirm(
            '메세지함을 비우시겠습니까? (확인시 복구되지 않습니다)'
        );
        if (clearing) {
            db.collection('user').doc(auth.currentUser?.uid!).update({
                message: [],
            });
            console.log('User Message Inbox Cleared.');
            alert('메세지함을 비웠습니다.');
            history.push('/setting');
        }
    };

    //읽기
    const showModal = () => {
        if (selected.length != 0) {
            const filtered = messageInbox.filter(
                (mssg) => mssg.date + mssg.info == selected[0]
            );
            setSelectedMssg(filtered[0]);
            db.collection('user')
                .doc(auth.currentUser?.uid!)
                .update({
                    message: messageInbox!.filter(
                        (order) => order !== filtered[0]
                    ),
                });
            filtered[0].read = '읽음';
            db.collection('user')
                .doc(auth.currentUser?.uid!)
                .update({
                    message: firebase.firestore.FieldValue.arrayUnion(
                        filtered[0]
                    ),
                });
        } else {
            alert('메세지를 선택해주세요');
        }
    };

    const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
        const classes = useToolbarStyles();
        const { numSelected } = props;

        return (
            <Toolbar
                className={clsx(classes.root, {
                    [classes.highlight]: numSelected > 0,
                })}
                style={{ padding: '10px 0', fontSize: '20px' }}
            >
                {numSelected > 0 ? (
                    <Typography
                        style={{ fontFamily: 'Pretendard-Light' }}
                        className={classes.title}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        {numSelected} 개 선택
                    </Typography>
                ) : (
                    <Typography
                        style={{ fontFamily: 'Pretendard-Light' }}
                        className={classes.title}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >
                        메세지함
                    </Typography>
                )}
                {numSelected > 0 ? (
                    <div
                        onClick={() => {
                            showModal();
                        }}
                        style={{width:"20%", fontSize:"15px"}}
                    >
                        보기
                    </div>
                ) : (
                    // <Tooltip title="Filter list">
                    //   <IconButton aria-label="filter list">
                    //     <FilterListIcon />
                    //   </IconButton>
                    // </Tooltip>
                    <></>
                )}
            </Toolbar>
        );
    };

    const msgSwitcher = (msg: string) => {
        switch (msg) {
            case '찾기완료':
                return (
                    <div className="messageBox" id="pot">
                    <div className="title">
                        {selectedMssg.title}
                    </div>
                    <div className="datetime">
                        <div className="date">
                            예약일자: {selectedMssg.date}
                        </div>
                        <div className="time">
                            예약시간: {selectedMssg.time}
                        </div>
                    </div>
                    <div className="info">
                        <>안녕하세요 {auth.currentUser?.displayName!} 님</>
                    </div>
                    <div className="info">
                        {auth.currentUser?.displayName!} 님의 요청품목인, {selectedMssg.title} 을(를) 찾았습니다. 개인적으로 연락이 갈 예정이니 잠시만 기다려주세요!
                        감사합니다.
                    </div>
                </div>
                );
            case '신청정보':
                return (
                    <div className="messageBox" id="pot">
                        <div className="title">
                            {selectedMssg.title}({selectedMssg.way})
                        </div>
                        <div className="datetime">
                            <div className="date">
                                예약일자: {selectedMssg.setDate}
                            </div>
                            <div className="time">
                                예약시간: {selectedMssg.time}
                            </div>
                        </div>
                        <div className="info">
                            <>안녕하세요 {auth.currentUser?.displayName!} 님</>
                        </div>
                        <div className="info">
                            {auth.currentUser?.displayName!} 님의 판매신청이
                            접수 되었습니다. 신청확인은 하루이내로 처리 되오니
                            기존 웹엡의 "메세지" 또는 "거래내역" 창을 이용하여
                            확인 바랍니다. 감사합니다.
                        </div>
                    </div>
                );
            case '승인완료':
                return (
                    <div className="messageBox" id="pot">
                        <div className="title">{selectedMssg.title}</div>
                        <div className="datetime">
                            <div className="date">
                                일자: {selectedMssg.date}
                            </div>
                            <div className="time">
                                시간: {selectedMssg.time.toString().slice(-11)}
                            </div>
                        </div>
                        <div className="info">
                            <>안녕하세요 {auth.currentUser?.displayName!} 님</>
                        </div>
                        <div className="info">
                            {selectedMssg.order.date} 판매요청이{' '}
                            {selectedMssg.info} 되었습니다. 당일날 기사님이
                            찾아뵐예정이니 잊지않고 준비해주시기 바랍니다.
                            감사합니다.
                        </div>
                    </div>
                );
            case '방문완료':
                return (
                    <div className="messageBox" id="pot">
                        <div className="title">{selectedMssg.title}</div>
                        <div className="datetime">
                            <div className="date">
                                일자: {selectedMssg.date}
                            </div>
                            <div className="time">
                                시간: {selectedMssg.time.toString().slice(-11)}
                            </div>
                        </div>
                        <div className="info">
                            <>안녕하세요 {auth.currentUser?.displayName!} 님</>
                        </div>
                        <div className="info">
                            {selectedMssg.order.date} 날 방문하여 아래와같이
                            정산 되었습니다.
                            <div className="info" style={{ fontSize: '20px' }}>
                                무게금액 :{' '}
                                {formatNumber(selectedMssg.order.weight)} 원
                            </div>
                            <div className="info" style={{ fontSize: '20px' }}>
                                추가금액 :{' '}
                                {formatNumber(selectedMssg.order.additional)} 원
                            </div>
                            <div className="info" style={{ fontSize: '20px' }}>
                                총 금액 :{' '}
                                {formatNumber(
                                    selectedMssg.order.weight +
                                        selectedMssg.order.additional
                                )}{' '}
                                원
                            </div>
                        </div>
                    </div>
                );
            case '송금완료':
                return (
                    <div className="messageBox" id="pot">
                        <div className="title">{selectedMssg.title}</div>
                        <div className="datetime">
                            <div className="date">
                                일자: {selectedMssg.date}
                            </div>
                            <div className="time">
                                시간: {selectedMssg.time.toString().slice(-11)}
                            </div>
                        </div>
                        <div className="info">
                            <>안녕하세요 {auth.currentUser?.displayName!} 님</>
                        </div>
                        <div className="info">
                            {selectedMssg.order.date} 날의 송금 내역입니다.
                            <div className="info" style={{ fontSize: '20px' }}>
                                무게금액 :{' '}
                                {formatNumber(selectedMssg.order.weight)} 원
                            </div>
                            <div className="info" style={{ fontSize: '20px' }}>
                                추가금액 :{' '}
                                {formatNumber(selectedMssg.order.additional)} 원
                            </div>
                            <div className="info" style={{ fontSize: '20px' }}>
                                총 금액 :{' '}
                                {formatNumber(
                                    selectedMssg.order.weight +
                                        selectedMssg.order.additional
                                )}{' '}
                                원
                            </div>
                            저장되어있는 계좌로 입금되었습니다. 문제가있을시
                            가까운 비드홈 지점으로 연락 부탁드립니다.
                            감사합니다.
                        </div>
                    </div>
                );
            default:
                return (
                    <div className="messageBox" id="pot">
                    <div className="title">
                        {selectedMssg.title}
                    </div>
                    <div className="datetime">
                        <div className="date">
                            예약일자: {selectedMssg.date}
                        </div>
                        <div className="time">
                            예약시간: {selectedMssg.time}
                        </div>
                    </div>
                    <div className="info">
                        <>안녕하세요 {auth.currentUser?.displayName!} 님</>
                    </div>
                    <div className="info">
                        {auth.currentUser?.displayName!} 님의 필요해요 신청이
                        접수 되었습니다. 신청확인은 하루이내로 처리 되오니
                        기존 웹엡의 "메세지" 기능을 이용해주시기 바랍니다. 감사합니다.
                    </div>
                </div>
                );
        }
    };
    return (
        <div
            className="fade"
            style={{ margin: 'auto', width: 'fit-content', paddingTop: '60px' }}
        >
            {authDataChanged ? (
                <div className={classes.root}>
                    <Paper className={classes.paper}>
                        <EnhancedTableToolbar numSelected={selected.length} />
                        <TableContainer>
                            <Table
                                className={classes.table}
                                aria-labelledby="tableTitle"
                                size={dense ? 'small' : 'medium'}
                                aria-label="enhanced table"
                            >
                                <EnhancedTableHead
                                    classes={classes}
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onSelectAllClick={handleSelectAllClick}
                                    onRequestSort={handleRequestSort}
                                    rowCount={messageInbox.length}
                                />
                                <TableBody>
                                    {stableSort(
                                        messageInbox,
                                        getComparator(order, orderBy)
                                    )
                                        .slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        )
                                        .map((row, index) => {
                                            const isItemSelected = isSelected(
                                                row.date + row.info
                                            );
                                            const labelId = `enhanced-table-checkbox-${index}`;

                                            return (
                                                <TableRow
                                                    hover
                                                    onClick={(event) =>
                                                        handleClick(
                                                            event,
                                                            row.date + row.info
                                                        )
                                                    }
                                                    role="checkbox"
                                                    aria-checked={
                                                        isItemSelected
                                                    }
                                                    tabIndex={-1}
                                                    key={row.date}
                                                    selected={isItemSelected}
                                                    style={{
                                                        textAlign: 'center',
                                                        fontFamily:
                                                            'EliceDigitalBaeum_Bold',
                                                        padding: '0',
                                                    }}
                                                >
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            checked={
                                                                isItemSelected
                                                            }
                                                            inputProps={{
                                                                'aria-labelledby': labelId,
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        style={{
                                                            padding: '0',
                                                            textAlign: 'center',
                                                            fontFamily:
                                                                'EliceDigitalBaeum_Bold',
                                                        }}
                                                        component="th"
                                                        id={labelId}
                                                        scope="row"
                                                        padding="none"
                                                    >
                                                        {row.date.slice(5)}
                                                    </TableCell>
                                                    <TableCell
                                                        style={{
                                                            padding: '0',
                                                            textAlign: 'center',
                                                            fontFamily:
                                                                'EliceDigitalBaeum_Bold',
                                                        }}
                                                        align="right"
                                                    >
                                                        {row.title}
                                                    </TableCell>
                                                    <TableCell
                                                        style={{
                                                            padding: '0',
                                                            textAlign: 'center',
                                                            fontFamily:
                                                                'EliceDigitalBaeum_Bold',
                                                        }}
                                                        align="right"
                                                    >
                                                        {row.info}
                                                    </TableCell>
                                                    <TableCell
                                                        style={{
                                                            padding: '0',
                                                            textAlign: 'center',
                                                            fontFamily:
                                                                'EliceDigitalBaeum_Bold',
                                                        }}
                                                        align="right"
                                                    >
                                                        {row.read === '읽음'
                                                            ? '읽음'
                                                            : '안읽음'}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={messageInbox.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </div>
            ) : (
                <>로그인 해주세요.</>
            )}
            {selectedMssg ? msgSwitcher(selectedMssg.info) : <></>}
            <Button
                variant="outlined"
                onClick={() => goBack()}
                style={{
                    width: '50%',
                    padding: '3px',
                    margin: '40px 40px 0 40px',
                    minWidth: '30px',
                    border: 'solid 2px',
                    borderRadius: '10rem',
                    fontFamily: 'Pretendard-Light',
                }}
            >
                뒤로가기
            </Button>
            <Button
                variant="outlined"
                onClick={() => clearOut()}
                style={{
                    width: '50%',
                    padding: '3px',
                    margin: '10px',
                    minWidth: '30px',
                    border: 'solid 2px',
                    borderRadius: '10rem',
                    fontFamily: 'Pretendard-Light',
                }}
            >
                메세지함 비우기
            </Button>
        </div>
    );
};

export default InboxPage;
