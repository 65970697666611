import React from 'react';
import { Button } from '@material-ui/core';
import button from '../../components/button';
import items from '../../components/items';

function DIY() {
    return (
        <div className="Apps">

                <div className="loader-wrapper" style={{ position: 'fixed' }}>
                    <span className="loader">
                        <span className="loader-innder"></span>
                    </span>
                    <span className="loader_text">
               
                        준비중 입니다.
                    </span>
                </div>
       
        </div>
    );
}
export default DIY;
