import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import './er_rate.css';
import { useDispatch, useSelector } from 'react-redux';
import { ERState } from '../ERReducer';
import { Button } from '@material-ui/core';
import MuiPhoneNumber from "material-ui-phone-number";
import LocalDrinkIcon from '@material-ui/icons/LocalDrink';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';

    function Er_rate(){
        const mass = useSelector<ERState, ERState["totalMass"]>((state)=> state.totalMass) 
        const user = useSelector<ERState, ERState["user"]>((state)=> state.user);

        return(
           <div style={{width:"100%", position:"relative", display:'flex', flexDirection:"column", justifyContent:"space-evenly", alignContent:"center"}}>
            
                <div className="wrapper">
                <div className="ER_wrapper">
                    <div className="col1">
                        <div className="row">
                        <div className="icons"><LocalDrinkIcon color="primary" fontSize="large"/></div> {(mass[0]!=undefined)? <div style={{color:"green", fontWeight:"bold", borderBottom:"solid 2px"}}>{Math.round(mass[mass.length-1] * .24 * 1000) + " L"}<br/></div>:<></>}
                        </div>
                        <div className="row" style={{top:"14px"}}>
                        생수절약
                        </div>
                        <div className="row" style={{borderBottom:"none", fontSize:"12px"}}>
                            <div>WATER</div>
                            <div>SAVING</div>
                        </div>
                    </div>
                    <div className="col2">
                        <div className="row">
                        <div className="icons" style={{color:"red"}}><DirectionsCarIcon fontSize="large"/></div> {(mass[0]!=undefined)? <div style={{color:"green", fontWeight:"bold", borderBottom:"solid 2px"}}>{Math.round(mass[mass.length-1] * .66 * 1000) + " Km"}<br/></div>:<></>}
                        </div>
                        <div className="row" style={{top:"14px"}}>
                        탄소절약
                        </div>
                        <div className="row" style={{borderBottom:"none", fontSize:"12px"}}>
                            <div>CARBON</div>
                            <div>SAVING</div>
                        </div>
                    </div>
                    <div className="col3">
                        <div className="row">
                        <div className="icons" style={{color:"#FBAA13"}}><OfflineBoltIcon fontSize="large"/></div> {(mass[0]!=undefined)? <div style={{color:"green", fontWeight:"bold", borderBottom:"solid 2px"}}>{Math.round(mass[mass.length-1] * .36 * 1000) + " kWh" }<br/></div>:<></>}
                        </div>
                        <div className="row" style={{top:"14px"}}>
                        전기절약
                        </div>
                        <div className="row" style={{borderBottom:"none", fontSize:"12px"}}>
                            <div>ELECTRICITY</div>
                            <div>SAVING</div>
                        </div>
                    </div>
            </div>
                </div> 
           </div>
        )
}

export default Er_rate;