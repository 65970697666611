import React from 'react';
import { useHistory } from 'react-router-dom';

function Footer() {
  const history = useHistory();
    return (
        // <Box style={{display:"flex", flexDirection:"row", flexBasis:0}}>
        //   <Container>
        //     <Grid container spacing ={4}>
        //     <Grid item xs ={12} sm ={4} style={{flexBasis:"0"}}>
        //         <Box borderBottom={1}>Help</Box>
        //         <Box>
        //           <Link href="/" color="inherit">
        //             Contact
        //           </Link>
        //         </Box>
        //         <Box>
        //           <Link href="/" color="inherit">
        //               Support
        //           </Link>
        //         </Box>
        //         <Box>
        //           <Link href="/" color="inherit">
        //               Privacy
        //           </Link>
        //         </Box>
        //         <Box>
        //           <Link href="/" color="inherit">
        //               Support
        //           </Link>
        //         </Box>
        //       </Grid>
        //       <Grid item xs ={12} sm ={4} style={{flexBasis:"0"}}>
        //         <Box borderBottom={1}>Help</Box>
        //         <Box>
        //           <Link href="/" color="inherit">
        //             Contact
        //           </Link>
        //         </Box>
        //         <Box>
        //           <Link href="/" color="inherit">
        //               Support
        //           </Link>
        //         </Box>
        //         <Box>
        //           <Link href="/" color="inherit">
        //               Privacy
        //           </Link>
        //         </Box>
        //         <Box>
        //           <Link href="/" color="inherit">
        //               Support
        //           </Link>
        //         </Box>
        //       </Grid>
        //       <Grid item xs ={12} sm ={4} style={{flexBasis:"0"}}>
        //         <Box borderBottom={1}>Help</Box>
        //         <Box>
        //           <Link href="/" color="inherit">
        //             Contact
        //           </Link>
        //         </Box>
        //         <Box>
        //           <Link href="/" color="inherit">
        //               Support
        //           </Link>
        //         </Box>
        //         <Box>
        //           <Link href="/" color="inherit">
        //               Privacy
        //           </Link>
        //         </Box>
        //         <Box>
        //           <Link href="/" color="inherit">
        //               Support
        //           </Link>
        //         </Box>
        //       </Grid>
        //       <Grid item xs ={12} sm ={4} style={{flexBasis:"0"}}>
        //         <Box borderBottom={1}>Help</Box>
        //         <Box>
        //           <Link href="/" color="inherit">
        //             Contact
        //           </Link>
        //         </Box>
        //         <Box>
        //           <Link href="/" color="inherit">
        //               Support
        //           </Link>
        //         </Box>
        //         <Box>
        //           <Link href="/" color="inherit">
        //               Privacy
        //           </Link>
        //         </Box>
        //         <Box>
        //           <Link href="/" color="inherit">
        //               Support
        //           </Link>
        //         </Box>
        //       </Grid>
        //     </Grid>
        //   </Container>
        // </Box>
        <div className="Footer">

                <div className="top">
                Phone: (010)-9540-6676
                </div>
                <div className="top">
              E-mail: videhome2020@naver.com
                </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                }}
            >
              
                <div
                    style={{
                        color: 'black',
                        fontWeight: 600,
                        fontSize: '13px',
                    }}
                >
                    개인정보 처리방침
                </div>
                <div
                    style={{
                        color: 'black',
                        fontWeight: 600,
                        fontSize: '13px',
                    }}
                >
                    이용약관
                </div>
                <div
                    style={{
                        color: 'black',
                        fontWeight: 600,
                        fontSize: '13px',
                    }}
                >
                    오픈시간
                </div>
                <div
                    style={{
                        color: 'black',
                        fontWeight: 600,
                        fontSize: '13px',
                    }}
                    onClick={()=>{history.push("/direction")}}
                >
                    오시는길
                </div>
            </div>
            <div className="top_title">
                <hr />
            </div>
            <div className="top" style={{ fontFamily: 'Pretendard-Light' }}>
                상호: 진하스 | 대표이사: 임명진 | 전화: 031-975-1741
            </div>
            <div className="top" style={{ fontFamily: 'Pretendard-Light' }}>
                주소: 경기도 고양시 일산동구 중산로 147 1층 |
            </div>
            <div className="top" style={{ fontFamily: 'Pretendard-Light' }}>
                사업자등록번호: 690-86-02052 | 통신판매: 경기중산21-0729-001 |{' '}
            </div>
            <div className="top" style={{ fontFamily: 'Pretendard-Light' }}>
                이용약관 개인정보처리방침 사업자정보확인
            </div>
        </div>
    );
}

export default Footer;
