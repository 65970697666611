import React, { useState } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import './button.css';
import { useDispatch, useSelector } from 'react-redux';
import { ERState } from '../ERReducer';
import {
    Button,
    createStyles,
    FormControlLabel,
    InputLabel,
    Select,
    Theme,
    withStyles,
} from '@material-ui/core';
import Webcam from 'react-webcam';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { useStyles } from '@material-ui/pickers/views/Calendar/SlideTransition';
import { auth, db } from '../config/firebase';
import { green } from '@material-ui/core/colors';
import firebase from 'firebase/app';
import items2 from './items2';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import InputBase from '@material-ui/core/InputBase';
import { Alert, AlertTitle } from '@mui/material';

const BootstrapInput = withStyles((theme: Theme) =>
    createStyles({
        root: {
            'label + &': {
                position: 'relative',
            },
        },
        input: {
            borderRadius: 4,
            position: 'relative',
            backgroundColor: theme.palette.background.paper,
            border: 'none',
            fontSize: 16,
            padding: '10px 26px 10px 12px',
            transition: theme.transitions.create([
                'border-color',
                'box-shadow',
            ]),
            // Use the system font instead of the default Roboto font.
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
                'EliceDigitalBaeum_Bold',
            ].join(','),
            '&:focus': {
                borderRadius: 4,
                borderColor: '#80bdff',
                boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
            },
        },
    })
)(InputBase);
const choices = [
    {
        value: 101,
        label: '자택',
    },
    {
        value: 102,
        label: '매장',
    },
];
const ways = [
    {
        value: 11,
        label: '대면',
    },
    {
        value: 22,
        label: '비대면 (문앞)',
    },
];
const stores = [
    {
        value: 1020,
        label: '백석점(준비중)',
    },
    {
        value: 1021,
        label: '마두점(준비중)',
    },
    {
        value: 1022,
        label: '주엽점(준비중)',
    },
    {
        value: 1023,
        label: '대화점(준비중)',
    },
    {
        value: 1024,
        label: '중산점',
    },
];

function Buttons() {
    const user = useSelector<ERState, ERState['user']>((state) => state.user);
    const isOn = useSelector<ERState, ERState['isOn']>((state) => state.isOn);
    const dispatch = useDispatch();
    const getTel = () => {
        if (tel !== undefined) {
            return tel;
        } else {
            return '0';
        }
    };
    const addInfo = () => {
        if (0 < answer && answer <= 5) {
            var finalWay = '';
            if (way == 22) {
                finalWay = '비대면 (문앞)';
            } else {
                if (cate == 101) {
                    finalWay = '자택';
                } else {
                    if (store == 1020) {
                        finalWay = '백석점(즌비준)';
                    } else if (store == 1021) {
                        finalWay = '마두점(준비중)';
                    } else if (store == 1022) {
                        finalWay = '주엽점(준비중)';
                    } else if (store == 1023) {
                        finalWay = '대화점(준비중)';
                    } else {
                        finalWay = '중산점';
                    }
                }
            }

            const info = [
                name,
                address,
                tel,
                dDay,
                { time: hour + ':' + (minute === '0') ? '00' : minute },
                finalWay,
                additional,
            ];
            if (
                name == undefined ||
                address == undefined ||
                tel == undefined ||
                dDay == undefined ||
                hour == undefined ||
                minute == undefined
            ) {
                alert('모든 입력란을 작성하시고 눌러주세요.');
            } else {
                dispatch({ type: 'ADD_INFO', payload: info });
                alert(
                    name +
                        ' 고객님, 신청서 저장 완료되었습니다. 무게를 직접 제고 입력하시고 싶으시다면 옆 버튼을 줄러주세요.'
                );
                setFilled(true);
                setRealWay(finalWay);
            }
        } else {
            alert('평일신청만 가능합니다. 다시 선택해주세요');
            setDDay('yyyy-MM-dd');
            return;
        }
    };
    const history = useHistory();
    const classes = useStyles();

    const delay = (ms: any) => new Promise((res: any) => setTimeout(res, ms));
    const calcMass = async () => {
        if (consentAgreement1 == false) {
            alert('필수 약관에 동의해주시기 바랍니다.');
        } else if (user.length <= 0) {
            alert(
                '회원정보를 다 입력하시고 저장하기를 누르셧는지 확인해주세요.'
            );
        } else {
            //Timestamp
            const timeStamp = Date.now();
            const messages = {
                date: date,
                setDate: dDay,
                time: hour + ':' + minute,
                way: realWay,
                read: false,
                title: '판매신청',
                info: '신청정보',
            };
            const orders = {
                date: dDay,
                time: hour + ':' + minute,
                way: user[user.length - 1][5],
                weight: 0,
                additional: 0,
                userId: auth.currentUser?.email!,
                confirmed: '미확인',
                name: name,
                address: address,
                phone: tel,
                uid: auth.currentUser?.uid!,
                timeStamp: new Intl.DateTimeFormat('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                }).format(timeStamp),
                category: {
                    clothes: 0,
                    bags: 0,
                    shoes: 0,
                    fashion: 0,
                    books: 0,
                    steel: 0,
                    non_steel: 0
                }
            };
            const BOrders = {
                name: name,
                address: address,
                phone: tel,
                date: dDay,
                time: hour + ':' + minute,
                way: realWay,
                weight: 0,
                additional: 0,
                confirmed: '미확인',
                userId: 'non_user',
                timeStamp: new Intl.DateTimeFormat('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                }).format(timeStamp),
                category: {
                    clothes: 0,
                    bags: 0,
                    shoes: 0,
                    fashion: 0,
                    books: 0,
                    steel: 0,
                    non_steel: 0
                }
            };
            if (auth.currentUser?.uid!) {
                var workPlan: any = {};
                var workPlan2: any = {};
                workPlan2[dDay] = {
                    time: hour + ':' + minute,
                    date: dDay,
                    name: name,
                    address: address,
                    phone: tel,
                    user: auth.currentUser?.email!,
                };
                workPlan[dDay.slice(0, 7)] = workPlan2;
                db.collection('showWork')
                    .doc('planner')
                    .update({
                        requested: firebase.firestore.FieldValue.arrayUnion(
                            workPlan
                        ),
                    });
                db.collection('user')
                    .doc(auth.currentUser?.uid!)
                    .update({
                        orders: firebase.firestore.FieldValue.arrayUnion(
                            orders
                        ),
                        message: firebase.firestore.FieldValue.arrayUnion(
                            messages
                        ),
                        numberOfOrders: firebase.firestore.FieldValue.increment(
                            1
                        ),
                        totalWeight: firebase.firestore.FieldValue.increment(0),
                        totalAdditional: firebase.firestore.FieldValue.increment(
                            0
                        ),
                        privacyAgree: true,
                    });
                db.collection("totalClicks").doc("apply").update({
                    applied :  firebase.firestore.FieldValue.increment(1),
                })
                db.collection('orders')
                    .doc('user')
                    .update({
                        orders: firebase.firestore.FieldValue.arrayUnion(
                            orders
                        ),
                        userId: firebase.firestore.FieldValue.arrayUnion(
                            auth.currentUser?.email
                        ),
                    })
                    .then((message) => console.log(message));
                setDone(true);
                alert(
                    '수거신청이 완료되었습니다. 거래내역 페이지에서 상황을 확인해주세요'
                );
                setIsLoading(true);
                await delay(3000);
                setIsLoading(false);
                history.push('/transaction');
            } else {
                var workPlan: any = {};
                workPlan[dDay] = {
                    date: dDay,
                    name: name,
                    address: address,
                    phone: tel,
                    user: 'non_user',
                };
                db.collection('showWork')
                    .doc('planner')
                    .update({
                        requested: firebase.firestore.FieldValue.arrayUnion(
                            workPlan
                        ),
                    });
                db.collection('orders')
                    .doc('non_user')
                    .update({
                        orders: firebase.firestore.FieldValue.arrayUnion(
                            BOrders
                        ),
                    });
                db.collection('user')
                    .doc('non_user')
                    .update({
                        orders: firebase.firestore.FieldValue.arrayUnion(
                            BOrders
                        ),
                        count: firebase.firestore.FieldValue.increment(1),
                    });
                setDone(true);
                history.push('/transaction');
                alert(
                    '비회원 수거신청이 완료되었습니다. 거래내역 페이지에서 상황을 확인해주세요'
                );
            }
        }
    };

    //Lodaing
    const [isLoading, setIsLoading] = useState<boolean>(false);

    //나중에  DB  구성할것들
    const [cate, setCate] = React.useState(101);
    const [store, setStore] = React.useState(1020);
    const [way, setWay] = React.useState(11);
    const [name, setName] = React.useState();
    const [tel, setTel] = React.useState();
    const [dDay, setDDay] = React.useState<any>();
    const [address, setAddress] = React.useState();
    const [additional, setAdditional] = React.useState('');
    const [PIDformatTEL, setPIDformatTEL] = React.useState();

    const [realWay, setRealWay] = React.useState<any>();

    //ways to 신청
    // const [ways,setWays] = useState([]);
    // const [choices,setChoices] = useState([]);
    // const [stores,setStores] = useState([]);

    //작성 완료 표기
    const [filled, setFilled] = React.useState(false);
    //modal
    const [done, setDone] = React.useState(false);
    //무게 추가
    const [addWeights, setAddWeights] = React.useState(false);
    //개인정보 확인
    const [consentAgreement1, setConsentAgreement1] = useState(false);
    const [consentModal, setConsentModal] = useState(false);

    //날짜 뽑기
    var today = new Date(),
        date =
            today.getFullYear() +
            '-' +
            (today.getMonth() + 1) +
            '/' +
            today.getDate();
    //날짜
    const [time, setTime] = React.useState();

    //시간 9-13 시 30분 단위 variable
    const [timeRangeHours, setTimeRangeHours] = useState([]);
    const [timeRangeMinutes, setTimeRangeMinutes] = useState([]);
    const [hour, setHour] = useState('09');
    const [minute, setMinute] = useState('00');
    const [answer, setAnswer] = useState(0);
    useEffect(() => {
        if (db) {
            db.collection('infos')
                .doc('time')
                .get()
                .then((doc) => {
                    setTimeRangeHours(doc.data()!.hours);
                    setTimeRangeMinutes(doc.data()!.minutes);
                });
            // db.collection('infos')
            // .doc('ways')
            // .get()
            // .then((doc) => {
            //     setWays(doc.data()!.ways);
            //     setChoices(doc.data()!.minutes);
            //     setStores(doc.data()!.stores);
            // });
        }
    }, []);

    //Search Options needed by 성함, 전화번호****
    db.collection('user')
        .doc(auth.currentUser?.uid!)
        .get()
        .then((doc) => {
            if (doc.exists) {
                setAddress(doc.data()!.address);
                setTel(doc.data()!.phone);
                setName(doc.data()!.name);
            } else {
                // doc.data() will be undefined in this case
            }
        });
    
    const goBack = () => {
        history.push('/');
    };

    const handleChange4 = (event: any) => {
        setWay(event.target.value);
        setCate(101);
    };
    const handleChange5 = (event: any) => {
        setCate(event.target.value);
    };
    const handleChange6 = (event: any) => {
        setStore(event.target.value);
    };
    const handleChange = (prop: string) => (event: any) => {
        if (prop == 'name_') {
            setName(event.target.value);
        } else if (prop == 'address_') {
            setAddress(event.target.value);
        } else if (prop == 'tel_') {
            const onlyNums = event.target.value.replace(/[^0-9]/g, '');
            if (onlyNums.length < 11) {
                setTel(onlyNums);
            } else if (onlyNums.length === 11) {
                setPIDformatTEL(onlyNums);
                const number = onlyNums.replace(
                    /(\d{3})(\d{4})(\d{4})/,
                    '($1) $2-$3'
                );
                setTel(number);
            }
        } else if (prop == 'Dday_') {
            const year = event.target.value.toString().slice(0, 4);
            const month = event.target.value.toString().slice(5, 7);
            const day = event.target.value.toString().slice(8, 11);
            const check: number = day_of_week(
                Number(year),
                Number(month),
                Number(day)
            );
            setAnswer(check);
            if (0 < check && check <= 5) {
                db.collection('user')
                    .doc(auth.currentUser?.uid)
                    .get()
                    .then((doc) => {
                        if (doc.exists) {
                            var finding = doc.data()!.orders;
                            finding.forEach((orders: any) => {
                                if (orders.date === event.target.value) {
                                    setDDay('yyyy-MM-dd');
                                    alert(
                                        '정하신 날짜에 이미 판매 신청이 존재합니다. 다른날로 정해주세요.'
                                    );
                                    return;
                                }
                            });
                        }
                    });
                setDDay(event.target.value);
            }
        } else if (prop == 'time_') {
            setTime(event.target.value);
        } else if (prop == 'time_H') {
            setHour(event.target.value);
        } else if (prop == 'time_M') {
            setMinute(event.target.value);
        } else if (prop == 'additional_') {
            setAdditional(event.target.value);
        } else if (prop == 'consent_1') {
            setConsentAgreement1(!consentAgreement1);
        }
    };

    // find weekday
    const day_of_week = (year: number, month: number, day: number) => {
        const t = [0, 3, 2, 5, 0, 3, 5, 1, 4, 6, 2, 4];
        if (month < 3) year -= 1;
        return (
            (year +
                Math.floor(Number(year / 4)) -
                Math.floor(Number(year / 100)) +
                Math.floor(Number(year / 400)) +
                t[month - 1] +
                Math.floor(Number(day))) %
            7
        );
    };

    const refresh = () => {
        setDDay(undefined);
        setTime(undefined);
    };

    function toggleIsOn() {
        setAddWeights(!addWeights);
    }
    function onOff() {
        setConsentModal(!consentModal);
        if (consentModal == false) {
            rootElement.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }
    }

    const GreenCheckbox = withStyles({
        root: {
            zIndex: 0,
            color: green[400],
            '&$checked': {
                color: green[600],
                zIndex: 0,
            },
        },
        checked: {},
    })((props: CheckboxProps) => <Checkbox color="default" {...props} />);

    var rootElement = document.documentElement;
    function scrollToTop() {
        // Scroll to top logic
        refresh();
        rootElement.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    return (
        <div style={{ margin: '0 40px 40px 40px', width: '100%' }}>
            {isLoading && (
                <div className="loader-wrapper" style={{ position: 'fixed' }}>
                    <span className="loader">
                        <span className="loader-innder"></span>
                    </span>
                    <span className="loader_text">
                        {' '}
                        판매신청 접수중입니다..
                    </span>
                </div>
            )}
            {consentModal && (
                <div
                    className="modalz"
                    style={{
                        position: 'absolute',
                        left: '0',
                        right: '0',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                    }}
                >
                    <div className="modal_in">
                        <div className="agreementform">
                            <div className="top_a">
                                개인정보의 수집 및 이용 동의서
                            </div>
                            <div style={{ padding: '10px' }}>
                                - 이용자가 제공한 모든 정보는 다음의 목적을 위해
                                활용하며, 하기 목적 이외의 용도로는 사용되지
                                않습니다.
                            </div>
                            <div style={{ padding: '10px', textAlign: 'left' }}>
                                ① 개인정보 수집 항목 및 수집·이용 목적
                                <div style={{ padding: '10px 0 0 0' }}>
                                    가) 수집 항목 (필수항목)
                                </div>
                                <div style={{ padding: '0 10px' }}>
                                    - 성명(국문), 주소, 전화번호(자택,
                                    휴대전화), 사진, 이메일, 나이 등 지원
                                    신청서에 기재된 정보 또는 신청자가 제공한
                                    정보
                                </div>
                                <div style={{ padding: '10px 0 0 0' }}>
                                    나) 수집 및 이용 목적
                                </div>
                                <div style={{ padding: '0 10px' }}>
                                    - 원만한 신청진행 - 고객정보 리스트 - 데이터
                                    기반 탄소 줄이기
                                </div>
                            </div>
                            <div style={{ padding: '0 10px' }}>
                                ② 개인정보 보유 및 이용기간 - 수집·이용
                                동의일로부터 개인정보의 수집·이용목적을 달성할
                                때까지
                            </div>
                            <div style={{ padding: '10px' }}>
                                ③ 동의거부관리 - 귀하께서는 본 안내에 따른
                                개인정보 수집, 이용에 대하여 동의를 거부하실
                                권리가 있습니다. 다만, 귀하가 개인정보의
                                수집/이용에 동의를 거부하시는 경우에 수거신청
                                과정에 있어 불이익이 발생할 수 있음을
                                알려드립니다.
                            </div>
                        </div>
                    </div>
                    <div>
                        <Button
                            className="buttons"
                            style={{
                              fontFamily: "Pretendard-Light",
                                padding: '4px',
                                width: '50%',
                            }}
                            variant="outlined"
                            onClick={() => {
                                onOff();
                            }}
                        >
                            확 인
                        </Button>
                    </div>
                </div>
            )}

            <FormControl style={{ width: '100%' }}>
                <TextField
                    style={{ top: '8px', marginBottom: '25px' }}
                    id="input-with-icon-TextField"
                    label="이름"
                    type="input"
                    disabled={done}
                    InputLabelProps={{
                        style: {
                            fontSize: '25px',
                          fontFamily: "Pretendard-Light",
                        },
                    }}
                    InputProps={{
                        style: {
                            fontSize: '16px',
                          fontFamily: "Pretendard-Light",
                            padding: '10px 0 0 0',
                        },
                        startAdornment: (
                            <InputAdornment position="start"></InputAdornment>
                        ),
                    }}
                    value={name}
                    placeholder="(ex) 홍길동"
                    onChange={handleChange('name_')}
                />
                <TextField
                    style={{ top: '8px', marginBottom: '25px' }}
                    id="input-with-icon-TextField"
                    label="주소"
                    type="input"
                    disabled={done}
                    placeholder="(ex) 경기도 고양시 일산동구 중산로 147 1층"
                    InputLabelProps={{
                        style: {
                            fontSize: '25px',
                          fontFamily: "Pretendard-Light",
                        },
                    }}
                    InputProps={{
                        style: {
                            fontSize: '16px',
                          fontFamily: "Pretendard-Light",
                            padding: '10px 0 0 0',
                        },
                        startAdornment: (
                            <InputAdornment position="start"></InputAdornment>
                        ),
                    }}
                    value={address}
                    onChange={handleChange('address_')}
                />
                <TextField
                    style={{ top: '8px', marginBottom: '25px' }}
                    id="input-with-icon-TextField"
                    label="전화번호"
                    type="input"
                    disabled={done}
                    InputLabelProps={{
                        style: {
                            fontSize: '25px',
                          fontFamily: "Pretendard-Light",
                        },
                    }}
                    InputProps={{
                        style: {
                            fontSize: '16px',
                          fontFamily: "Pretendard-Light",
                            padding: '10px 0 0 0',
                        },
                        startAdornment: (
                            <InputAdornment position="start"></InputAdornment>
                        ),
                    }}
                    placeholder="(000) 000 - 000"
                    value={tel}
                    onChange={handleChange('tel_')}
                />
                <TextField
                    style={{ top: '8px', marginBottom: '25px' }}
                    id="date"
                    label="희망일자 (월 - 금)"
                    placeholder="평일만 설정해 주세요 (기능 개발중에 있습니다)"
                    type="date"
                    value={dDay}
                    disabled={done}
                    InputLabelProps={{
                        shrink: true,
                        style: {
                            fontSize: '25px',
                          fontFamily: "Pretendard-Light",
                        },
                    }}
                    onChange={handleChange('Dday_')}
                    InputProps={{
                        style: {
                            fontSize: '16px',
                          fontFamily: "Pretendard-Light",
                            padding: '10px 0 0 0',
                        },
                    }}
                />
                <div
                    style={{ textAlign: 'left', color: 'rgba(0, 0, 0, 0.54)',   fontFamily: "Pretendard-Light", fontSize:"18px" }}
                >
                    희망시간 (9시 - 13시)
                </div>
                <div
                    style={{
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-evenly',
                        textAlign: 'center',

                        borderBottom: 'solid 1px rgba(0, 0, 0, 0.54)',
                        padding: '10px 0',
                    }}
                >
                    <div
                        style={{
                            position: 'relative',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <div style={{ margin: 'auto' }}>
                            <Select
                                label="시간"
                                labelId="demo-customized-select-label"
                                id="demo-customized-select"
                                value={hour}
                                onChange={handleChange('time_H')}
                                input={<BootstrapInput />}
                            >
                                {timeRangeHours!.map((key) => {
                                    return (
                                        <MenuItem value={key}>{key}</MenuItem>
                                    );
                                })}
                            </Select>
                        </div>
                        <div
                            style={{
                                fontSize: '16px',
                              fontFamily: "Pretendard-Light",
                                color: 'black',
                                margin: 'auto',
                            }}
                        >
                            시
                        </div>
                    </div>
                    <div style={{ margin: 'auto' }}>:</div>
                    <div
                        style={{
                            position: 'relative',
                            width: '100%',
                            flexDirection: 'row',
                            display: 'flex',
                        }}
                    >
                        <div style={{ margin: 'auto' }}>
                            <Select
                                label="분"
                                labelId="demo-customized-select-label"
                                id="demo-customized-select"
                                value={minute}
                                onChange={handleChange('time_M')}
                                input={<BootstrapInput />}
                            >
                                {timeRangeMinutes!.map((key) => {
                                    return (
                                        <MenuItem value={key}>{key}</MenuItem>
                                    );
                                })}
                            </Select>
                        </div>
                        <div
                            style={{
                                fontSize: '16px',
                              fontFamily: "Pretendard-Light",
                                color: 'black',
                                margin: 'auto',
                            }}
                        >
                            분
                        </div>
                    </div>
                </div>
                {/* <TextField
                    style={{ top: '8px', marginBottom: '25px' }}
                    id="time"
                    label="희망시간 (9시 - 13시)"
                    placeholder="9 시 ~ 13시, 30분단위 로 설정해주세요 (기능 개발중에 있습니다)"
                    type="time"
                    disabled={done}
                    value={time}
                    InputLabelProps={{
                        shrink: true,
                        style: {
                            fontSize: '25px',
                          fontFamily: "Pretendard-Light",
                        },
                    }}
                    inputProps={{
                        style: {
                            fontSize: '16px',
                          fontFamily: "Pretendard-Light",
                            padding: '10px 0 0 0',
                        },
                        step: 1800,
                    }}
                    onChange={handleChange('time_')}
                /> */}
                <TextField
                    style={{ top: '8px', marginBottom: '25px' }}
                    id="standard-select-condition"
                    select
                    label="선택1"
                    disabled={done}
                    value={way}
                    onChange={handleChange4}
                    InputProps={{
                        style: {
                            fontSize: '20px',
                          fontFamily: "Pretendard-Light",
                            padding: '10px 0 0 0',
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            fontSize: '25px',
                          fontFamily: "Pretendard-Light",
                        },
                    }}
                >
                    {ways.map((option: any) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
                {way == 11 ? (
                    <TextField
                        style={{ top: '8px', marginBottom: '25px' }}
                        id="standard-select-condition"
                        select
                        label="선택2"
                        disabled={done}
                        value={cate}
                        onChange={handleChange5}
                        InputProps={{
                            style: {
                                fontSize: '20px',
                              fontFamily: "Pretendard-Light",
                                padding: '10px 0 0 0',
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                fontSize: '25px',
                              fontFamily: "Pretendard-Light",
                            },
                        }}
                    >
                        {choices.map((option: any) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                ) : (
                    <></>
                )}
                {cate == 102 ? (
                    <TextField
                        style={{ top: '8px', marginBottom: '25px' }}
                        id="standard-select-condition"
                        select
                        label="매장"
                        disabled={done}
                        value={store}
                        onChange={handleChange6}
                        InputProps={{
                            style: {
                                fontSize: '16px',
                              fontFamily: "Pretendard-Light",
                                padding: '10px 0 0 0',
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                fontSize: '25px',
                              fontFamily: "Pretendard-Light",
                            },
                        }}
                    >
                        {stores.map((option: any) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                ) : (
                    <></>
                )}
            </FormControl>
            <div
                className="buttons"
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                    paddingTop: '20px',
                }}
            >
                <div>
                    {!filled ? (
                        <Button
                        id="pot"
                            style={{
                                fontSize: '15px',
                              fontFamily: "Pretendard-Light",
                            }}
                            variant="outlined"
                            onClick={() => {
                                addInfo();
                            }}
                        >
                            저장하기
                        </Button>
                    ) : (
                        <>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-evenly',
                                flexDirection: 'row',
                            }}
                        >
                            <Button
                                style={{
                                  fontFamily: "Pretendard-Light",
                                }}
                                variant="outlined"
                                disabled
                            >
                                저장완료
                            </Button>
                            <Button
                                style={{
                                  fontFamily: "Pretendard-Light",
                                }}
                                variant="outlined"
                                onClick={() => {
                                    toggleIsOn();
                                }}
                            >
                                (준비중) 무게 추가하기
                            </Button>
                        </div>
                        <div style={{ fontFamily: 'EliceDigitalBaeum_Bold', marginTop:"20px"}} id="pot">
                        <Alert severity="success" style={{  fontFamily: 'EliceDigitalBaeum_Bold', background:"rgb(237, 247, 237)"}}>
                        저장 완료되었습니다!</Alert>
                        </div>
                        <div style={{ fontFamily: 'EliceDigitalBaeum_Bold', marginTop:"20px"} }id="pot2" >
                        <Alert severity="info" style={{  fontFamily: 'EliceDigitalBaeum_Bold', background:"rgb(229, 246, 253)"}}>
                        신청하기를 눌러 신청을 완료해주세요. </Alert>
                        </div>
                   
                        </>
                    )}
                </div>
            </div>

            {addWeights ? (
                <>
                    {() => {
                        items2();
                    }}
                </>
            ) : (
                <></>
            )}

            <div style={{ padding: '20px 0 10px 0', fontSize: '10px' }}>
                <FormControlLabel
                    style={{ fontSize: '15px', color: 'black' }}
                    control={
                        <GreenCheckbox
                            checked={consentAgreement1}
                            disabled={done}
                            onChange={handleChange('consent_1')}
                            value={consentAgreement1}
                            name="radio-button-demo"
                            inputProps={{ 'aria-label': 'C' }}
                        />
                    }
                    label="개인정보처리방침 내용에 동의합니다."
                    labelPlacement="end"
                />
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                    fontSize: '20px',
                    width: '100%',
                }}
            >
                {done ? (
                    <Button
                        disabled={done}
                        className="buttons"
                        style={{
                            fontSize: '15px',
                          fontFamily: "Pretendard-Light",
                            width: '100%',
                        }}
                        variant="outlined"
                        onClick={() => {
                            calcMass();
                        }}
                    >
                        신청완료
                    </Button>
                ) : (
                    <Button
                        disabled={done}
                        className="buttons"
                        style={{
                          fontFamily: "Pretendard-Light",
                            width: '100%',
                            fontSize: '15px',
                        }}
                        variant="outlined"
                        onClick={() => {
                            calcMass();
                        }}
                    >
                        신청하기
                    </Button>
                )}
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                    fontSize: '10px',
                    width: '100%',
                }}
            >
                <Button
                    disabled={done}
                    className="buttons waves-effect"
                    style={{
                      fontFamily: "Pretendard-Light",
                        width: '80%',
                        padding: '4px',
                        margin: '5px 2.5px 5px 0',
                        zIndex: 0,
                    }}
                    variant="outlined"
                    onClick={() => {
                        onOff();
                    }}
                >
                    개인정보 처리방침
                </Button>
                <Button
                    disabled={done}
                    id="scrolltoTop"
                    className="buttons"
                    style={{
                      fontFamily: "Pretendard-Light",
                        width: '80%',
                        padding: '4px',
                        margin: '5px 0 5px 2.5px',
                    }}
                    variant="outlined"
                    onClick={() => {
                        scrollToTop();
                    }}
                >
                    다시작성하기
                </Button>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                    fontSize: '20px',
                    width: '100%',
                }}
            >
                <Button
                    disabled={done}
                    className="buttons"
                    style={{
                      fontFamily: "Pretendard-Light",
                        width: '100%',
                        fontSize: '15px',
                    }}
                    variant="outlined"
                    onClick={() => goBack()}
                >
                    뒤로가기
                </Button>
            </div>
        </div>
    );
}

export default Buttons;
