import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import AuthContainer from '../../components/AuthContainer';
import ErrorText from '../../components/ErrorText';
import { auth, db, Providers } from '../../config/firebase';
import logging from '../../config/logging';
import IPageProps from '../../interfaces/page';
import firebase from 'firebase';
import { SignInWithSocialMedia } from './modules';
import {
    FormControl,
    InputAdornment,
    TextField,
    Button,
} from '@material-ui/core';
import { useEffect } from 'react';
import KakaoLogin from '../../KakaoLogin';
import KakaoUnlink from '../../KakaoUnlink';
import '../../App.css';

import LockOpenIcon from '@material-ui/icons/LockOpen';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import VpnKeyIcon from '@material-ui/icons/VpnKey';

require('dotenv').config();

const LoginPage: React.FunctionComponent<IPageProps> = (props) => {
    const [authenticating, setAuthenticating] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean | null>(null);

    const history = useHistory();

    const signInWithEmailAndPassword = () => {
        if (email !== undefined && password !== undefined) {
            if (error !== '') setError('');
            setAuthenticating(true);
            if (email == '' || password == '') {
                setError('아이디 또는 비밀번호를 입력해주세요');
                setAuthenticating(false);
                return;
            } else {
                db.collection('admin')
                    .doc(email)
                    .get()
                    .then((doc) => {
                        if (!doc.exists) {
                            auth.signInWithEmailAndPassword(email, password)
                                .then((result) => {
                                    logging.info(result);
                                    history.push('/setting');
                                })
                                .catch((error) => {
                                    logging.error(error);
                                    if (
                                        error.code.includes(
                                            'auth/wrong-password'
                                        )
                                    ) {
                                        setError(
                                            '비밀번호를 다시 입력 해주세요.'
                                        );
                                    } else {
                                        setError('아이디가 존재하지 않습니다.');
                                    }
                                    setAuthenticating(false);
                                });
                        } else {
                            setError('관리자모드가 아닙니다.');
                            setAuthenticating(false);
                        }
                    });
            }
        } else {
            setError('아이디 또는 비밀번호를 입력해주세요');
            setAuthenticating(false);
        }
        // auth.signInWithEmailAndPassword(email, password)
        // .then(result => {
        //     logging.info(result);
        //     history.push('/');
        // })
        // .catch(error => {
        //     logging.error(error);

        //     if (error.code.includes('auth/wrong-password'))
        //     {
        //         setError('비밀번호를 다시 입력 해주세요.');
        //     }
        //     else{
        //         setError('아이디가 존재하지 않습니다.');
        //     }
        //     setAuthenticating(false);
        // });
    };

    const signInWithKakao = () => {
        // window.kakao.auth.login({
        //     scope:"profile, account_email, age, gender",
        //     sucess: function(authObj:any){
        //         console.log(authObj);
        //         window.kakao.API.request({
        //             url:"/v2/user/me",
        //             sucess: (res:any) =>{
        //                 const kakao_account = res.kakao_account;
        //                 console.log(kakao_account);
        //             }
        //         });
        //     }
        // })
        // Kakao.Auth.createLoginButton({
        //     container: '#kakao-login-btn',
        //     success: function(authObj) {
        //       Kakao.API.request({
        //         url: '/v2/user/me',
        //         success: function(res) {
        //           alert(JSON.stringify(res))
        //         },
        //         fail: function(error) {
        //           alert(
        //             'login success, but failed to request user information: ' +
        //               JSON.stringify(error)
        //           )
        //         },
        //       })
        //     },
        //     fail: function(err) {
        //       alert('failed to login: ' + JSON.stringify(err))
        //     },
        //   })
        //         signInWithCustomToken(auth, token)
        //         .then((userCredential:any) => {
        //             // Signed in
        //             const user = userCredential.user;
        //             console.log(user);
        //             // ...
        //         })
        //         .catch((error:any) => {
        //             const errorCode = error.code;
        //             const errorMessage = error.message;
        //             // ...
        //   });
    };
    const signInWithSocialMedia = (provider: firebase.auth.AuthProvider) => {
        if (error !== '') setError('');

        setAuthenticating(true);

        SignInWithSocialMedia(provider)
            .then((result) => {
                logging.info(result);

                db.collection('user')
                    .doc(auth.currentUser?.uid!)
                    .get()
                    .then((doc) => {
                        if (doc.exists) {
                            history.push('/setting');
                        } else {
                            // doc.data() will be undefined in this case

                            history.push('/provider_fill');
                        }
                    });
            })
            .catch((error) => {
                logging.error(error);
                setAuthenticating(false);
                setError(error.message);
            });
    };
    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if (user) {
                logging.info('User detected.');
                db.collection('user')
                .doc(auth.currentUser?.uid!)
                .get()
                .then((doc) => {
                    if (doc.exists) {
                        history.push('/');
                    } else {
                        // doc.data() will be undefined in this case

                        history.push('/provider_fill');
                    }
                });
            } else {
                logging.info('No user detected');
            }
        });
    }, []);

    return (
        <div className="fade" style={{ margin: '40px 40px 0 40px' }}>
            {authenticating && (
                <div className="loader-wrapper">
                    <span className="loader">
                        <span className="loader-innder"></span>
                    </span>
                    <span className="loader_text">로그인 중입니다..</span>
                </div>
            )}
            <img className="img-logo-login" src="./videhome_logo.png"></img>
            <div className="fade" style={{ color: 'black' }}>
                <FormControl style={{ width: '100%' }}>
                    <div
                        style={{
                    
                    
                            marginTop: '20px',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                position: 'relative',
                                width: '100%',
                                justifyContent: 'center',
                            }}
                        >
                            <PersonOutlineIcon
                                fontSize="small"
                                style={{
                                    position: 'absolute',
                                    display: 'inline',
                                    left: '0px',
                                    bottom: '5%',
                                    color:"grey"
                                }}
                            />
                            <TextField
                                style={{
                                    top: '2px',
                                    width: '90%',
                                    left:"5%"
                                }}
                                id="input-with-icon-textfield"
                                placeholder="이메일 주소입력"
                                type="input"
                                onChange={(event) =>
                                    setEmail(event.target.value)
                                }
                                label="아이디"
                                inputProps={{
                                    style: {
                                        textAlign: 'left',
                                        fontSize: '15px',
                                        width: '100%',
                                        fontFamily: 'EliceDigitalBaeum_Bold',
                                    },
                                    'aria-label': 'naked',
                                    startAdornment: (
                                        <InputAdornment position="start"></InputAdornment>
                                    ),
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontSize: '15px',
                                        fontFamily: 'EliceDigitalBaeum_Bold',
                                  
                                    },
                                }}
                            />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                position: 'relative',
                                width: '100%',
                                justifyContent: 'center',
                            }}
                        >
                            <VpnKeyIcon
                                fontSize="small"
                                style={{
                                    position: 'absolute',
                                    display: 'inline',
                                    left: '0px',
                                    bottom: '40%',
                                    color:"grey"
                                }}
                            />
                            <TextField
                                style={{
                                    top: '2px',
                                    marginBottom: '25px',
                                    color: 'black',
                                    width: '90%',
                                    left:"5%"
                                }}
                                id="input-with-icon-textfield"
                                placeholder="********"
                                type="password"
                                autoComplete="current-password"
                                onChange={(event) =>
                                    setPassword(event.target.value)
                                }
                                label="비밀번호"
                                inputProps={{
                                    style: {
                                        textAlign: 'left',
                                        fontSize: '19px',
                                        color: 'black',
                                        width: '100%',
                                    },
                                    'aria-label': 'naked',
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontSize: '15px',
                                        fontFamily: 'EliceDigitalBaeum_Bold',
                             
                                    },
                                }}
                            />
                        </div>
                        <div
                            className="fade"
                            style={{ fontSize: '13px', padding: '10px 0 0 0' }}
                        >
                            <small>
                                <Button
                                    disabled={authenticating}
                                    variant="outlined"
                                    onClick={() => signInWithEmailAndPassword()}
                                    style={{
                                        width: '100%',
                                        margin: 'auto',
                                        fontSize: '13px',
                                        fontFamily: 'EliceDigitalBaeum_Bold',
                                        backgroundColor: '#FBAA13',
                                        color: 'white',
                                    }}
                                >
                                    <LockOpenIcon
                                        fontSize="small"
                                        style={{
                                            position: 'absolute',
                                            display: 'inline',
                                            left: '22px',
                                            color: 'white',
                                        }}
                                    />
                                    <div style={{ fontSize: '15px' }}>
                                        로그인
                                    </div>
                                </Button>
                            </small>
                        </div>
                    </div>
                    <div
                        className="fade"
                        style={{
                            fontSize: '13px',
                            padding: '20px',
                            textDecoration: 'none',
                        }}
                    >
                        <small>
                            {/* <Button
                                disabled={authenticating}
                                variant="outlined"
                                style={{
                                    width: '60%',
                                    margin: 'auto',
                                    fontSize: '13px',
                                    fontFamily: 'EliceDigitalBaeum_Bold',
                                    border: 'solid 2px',
                                }}
                                onClick={() => {
                                    history.push('/register');
                                }}
                            >
                                회원가입
                            </Button> */}
                            <Link
                                to="/register"
                                style={{
                                    textDecoration: 'none',
                                    color: 'grey',
                                }}
                            >
                                회원가입 |
                            </Link>
                            <Link
                                to="/forget"
                                style={{
                                    textDecoration: 'none',
                                    color: 'grey',
                                }}
                            >
                                {' '}
                                비밀번호 찾기 |
                            </Link>
                            <Link
                                to="/forget"
                                style={{
                                    textDecoration: 'none',
                                    color: 'grey',
                                }}
                            >
                                {' '}
                                아이디 찾기
                            </Link>
                        </small>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            position: 'relative',
                            justifyContent: 'center',
                        }}
                    >
                        <div id="pot2" style={{ padding: '0 10px' }}>
                            <Button
                                variant="outlined"
                                disabled={authenticating}
                                onClick={() =>
                                    signInWithSocialMedia(Providers.google)
                                }
                                style={{
                                    minWidth: '30px',
                                    margin: 'auto',
                                    border: 'solid 2px',
                                    borderRadius: '10rem',
                                    height: '56px',
                                    fontFamily: 'EliceDigitalBaeum_Bold',
                                }}
                            >
                                <img
                                    className="google-icon"
                                    src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
                                />
                            </Button>
                        </div>
                        <div id="pot1" style={{ padding: '0 10px' }}>
                            <KakaoLogin
                                token={process.env.REACT_APP_KAKAO_JS_KEY!}
                                onSuccess={console.log}
                                onFail={console.error}
                                onLogout={console.info}
                            />
                        </div>
                    </div>
                    {/* <div id="pot2">
                        <KakaoLogin
                            token={process.env.REACT_APP_KAKAO_JS_KEY!}
                            onSuccess={console.log}
                            onFail={console.error}
                            onLogout={console.info}
                        />
                    </div> */}

                    <div style={{ marginTop: '20px' }}>
                        <div
                            style={{
                                fontSize: '13px',
                                fontFamily: 'EliceDigitalBaeum_Bold',
                                color: 'rgb(60, 30, 30)',
                            }}
                        >
                            카카오와 연결 해제
                        </div>
                        <KakaoUnlink
                            token={process.env.REACT_APP_KAKAO_JS_KEY!}
                            onSuccess={console.log}
                            onFail={console.error}
                            onLogout={console.info}
                        />
                    </div>
                    <div
                        style={{
                            padding: '10px 0 0 0',
                            fontFamily: 'EliceDigitalBaeum_Bold',
                            color: 'red',
                        }}
                    >
                        {/* <div
                            style={{
                                fontSize: '13px',
                                fontFamily: 'EliceDigitalBaeum_Bold',
                                color: 'rgb(60, 30, 30)',
                                paddingTop: '10px',
                            }}
                        >
                            <Link
                                to="/forget"
                                style={{ textDecoration: 'none' }}
                            >
                                패스워드를 잊어버리셧나요?
                            </Link>
                        </div> */}
                        <ErrorText error={error} />
                    </div>
                </FormControl>
            </div>
        </div>
    );
};

export default LoginPage;
