import firebase from 'firebase/app'
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';
import config from '../config/config';



const Firebase = firebase.initializeApp(config.firebase);

export const Providers = {
    google : new firebase.auth.GoogleAuthProvider(),
}

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
// const analytics = getAnalytics(Firebase);
const settings = {/* your settings... */ timestampsInSnapshots: true};

export default Firebase;