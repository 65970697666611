import {
    Button,
    FormControl,
    InputAdornment,
    TextField,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import ErrorText from '../../components/ErrorText';
import { auth } from '../../config/firebase';
import logging from '../../config/logging';
import IPageProps from '../../interfaces/page';

const ForgotPasswordPage: React.FunctionComponent<IPageProps> = (props) => {
    const [sending, setSending] = useState<boolean>(false);
    const [sent, setSent] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('');
    const [error, setError] = useState<string>('');

    const history = useHistory();

    const resetPasswordRequest = () => {
        if (error !== '') setError('');

        setSending(true);

        auth.sendPasswordResetEmail(email)
            .then(() => {
                logging.info('Email sent.');
                setSent(true);
                setSending(false);
                alert('이메일을 확인해주세요.');
            })
            .catch((error) => {
                logging.error(error);
                console.log(error.message);
                if (error.code === 'auth/invalid-email') {
                    setError('이메일이 등록되어있지않습니다.');
                    setSending(false);
                } else {
                    setError(error.message);
                }
                setSending(false);
            });
    };

    const goBack = () => {
        history.push('/');
    };

    return (
        // <AuthContainer header="Send Password Reset">
        //     {sent ?
        //         <p>A link has been sent to your email with instructions.</p>
        //     :
        //         <>
        //             <p>Please enter your email.</p>
        //             <FormGroup>
        //                 <Input
        // type="email"
        // name="email"
        // id="email"
        // placeholder="Email Address"
        // onChange={event => setEmail(event.target.value)}
        // value={email}
        //                 />
        //             </FormGroup>
        //             <Button
        //                 disabled={sending}
        //                 color="success"
        //                 block
        //                 onClick={() => resetPasswordRequest()}
        //             >
        //                 Send Reset Link
        //             </Button>
        //             <ErrorText error={error} />
        //         </>
        //     }
        // </AuthContainer>

        <div className="fade" style={{ margin: '40px' }}>
            {sending && (
                <div className="loader-wrapper">
                    <span className="loader">
                        <span className="loader-innder"></span>
                    </span>
                    <span className="loader_text">로그인 중입니다..</span>
                </div>
            )}
            <img className="img-logo-login" src="./videhome_logo.png"></img>
            <div style={{ color: 'black' }}>
                <FormControl style={{ width: '100%' }}>
                    <TextField
                        autoComplete="new-password"
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Email Address"
                        onChange={(event) => setEmail(event.target.value)}
                        value={email}
                        style={{
                            top: '2px',
                            marginBottom: '25px',
                            color: 'black',
                        }}
                        label="이메일"
                        inputProps={{
                            style: {
                                textAlign: 'left',
                                fontSize: '19px',
                                fontFamily: 'Pretendard-Light',
                            },
                            'aria-label': 'naked',
                            startAdornment: (
                                <InputAdornment position="start"></InputAdornment>
                            ),
                        }}
                        InputLabelProps={{
                            style: {
                                fontSize: '15px',
                                fontFamily: 'Pretendard-Light',
                            },
                        }}
                    />

                    <div>
                        <Button
                            variant="outlined"
                            onClick={() => goBack()}
                            style={{ width: '50%', margin: 'auto' }}
                        >
                            뒤로가기
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => resetPasswordRequest()}
                            style={{ width: '50%', margin: 'auto' }}
                        >
                            링크 보내기
                        </Button>
                    </div>
                    <div
                        style={{
                            padding: '10px',
                            fontFamily: 'Pretendard-Light',
                            color: 'red',
                        }}
                    >
                        <ErrorText error={error} />
                    </div>
                </FormControl>
            </div>
        </div>
    );
};

export default ForgotPasswordPage;
