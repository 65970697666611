import React from 'react';
import { loadSdk, DEFAULT_STYLE } from './utils';
import { ExtendedWindow, Props, State } from './types';
import { auth } from './config/firebase';
import logging from './config/logging';
import { Button } from '@material-ui/core';

declare let window: ExtendedWindow;

export default class KakaoLogin extends React.PureComponent<Props, State> {
    state = { isLoggedIn: false };

    public static DEFAULT_STYLE = DEFAULT_STYLE;

    public async componentDidMount() {
        await loadSdk();
        window.Kakao.init(this.props.token);
    }

    private onButtonClick = () => {
        const {
            throughTalk = true,
            persistAccessToken = true,
            needProfile = true,
            useLoginForm = false,
            onSuccess,
            onFail,
        } = this.props;

        const method = useLoginForm ? 'loginForm' : 'login';

        // const signInWithEmailAndPassword = () => {
        //   if (error !== '') setError('');

        //   setAuthenticating(true);

        //   db.collection('admin').doc(email).get().then((doc)=>{
        //       if (!doc.exists) {
        //           auth.signInWithEmailAndPassword(email, password)
        //           .then(result => {
        //               logging.info(result);
        //               history.push('/');
        //           })
        //           .catch(error => {
        //               logging.error(error);
        //               if (error.code.includes('auth/wrong-password'))
        //               {
        //                   setError('비밀번호를 다시 입력 해주세요.');
        //               }
        //               else{
        //                   setError('아이디가 존재하지 않습니다.');
        //               }
        //               setAuthenticating(false);
        //           });
        //       }
        //       else{
        //           setError('관리자모드가 아닙니다.');
        //           setAuthenticating(false);
        //       }
        //   })

        (window.Kakao?.Auth)[method]({
            throughTalk,
            persistAccessToken,
            success: (response) => {
                this.setState({ isLoggedIn: true });

                if (needProfile) {
                    window.Kakao?.API.request({
                        url: '/v2/user/me',
                        success: (profile) => {
                            const result = { response, profile };
                            onSuccess(result);

                            auth.createUserWithEmailAndPassword(
                                result.profile.kakao_account.email,
                                result.profile.id.toString()
                            )
                                .then((results) => {
                                    logging.info(results);
                                    auth.currentUser?.updateProfile({
                                        photoURL:
                                            result.profile.kakao_account.profile
                                                .thumbnail_image_url,
                                    });

                                    // auth.currentUser?.updateProfile({
                                    //     displayName: '카카오',
                                    // });
                                })
                                .catch((error) => {
                                    if (
                                        error.code.includes(
                                            'auth/email-already-in-use'
                                        )
                                    ) {
                                        auth.signInWithEmailAndPassword(
                                            result.profile.kakao_account.email,
                                            result.profile.id.toString()
                                        );
                                    } else {
                                        logging.error(error);
                                    }
                                });
                        },
                        fail: onFail,
                    });
                } else {
                    onSuccess({ response });
                }
            },
            fail: onFail,
        });
    };

    private onLogout = () => {
        window.Kakao?.Auth.logout(() => {
            this.props.onLogout?.(window.Kakao?.Auth.getAccessToken());
            this.setState({ isLoggedIn: false });
        });
    };

    public render() {
        const { isLoggedIn } = this.state;
        const onClick = isLoggedIn ? this.onLogout : this.onButtonClick;
        const {
            render,
            // className = '',
            // style = DEFAULT_STYLE,
            // children = '카카오 로그인하기',
        } = this.props;

        if (typeof render === 'function') {
            return render({ onClick });
        }

        return (
            <div>
                {isLoggedIn && (
                    <div
                        className="loader-wrapper"
                        style={{
                            minWidth: '51px',
                            top: '-1px',
                            height: '58px',
                        }}
                    >
                        <span className="loader2">
                            <span className="loader-innder2"></span>
                        </span>
                        <span className="loader_text">카카오</span>
                    </div>
                )}
                <Button
                    variant="outlined"
                    onClick={onClick}
                    style={{
                        background: 'rgb(255, 235, 0)',
                        color: 'rgb(60, 30, 30)',
                        minWidth: '50px',
                        margin: 'auto',
                        border: 'solid 2px',
                        borderRadius: '10rem',
                        height: '56px',
                        fontFamily: 'TmoneyRoundWindExtraBold',
                    }}
                >
                    <img
                        className="kakao-icon"
                        src="https://upload.wikimedia.org/wikipedia/commons/d/dd/Kakao_Corp._symbol_-_2012.svg"
                        alt=""
                    />
                </Button>
                {/* <img
                    src="//k.kakaocdn.net/14/dn/btqCn0WEmI3/nijroPfbpCa4at5EIsjyf0/o.jpg"
                    onClick={onClick}
                    style={{ margin: 'auto', minWidth: '200px', width: '10%' }}
                /> */}
            </div>
        );
    }
}
