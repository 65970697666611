import { Button, Icon, makeStyles, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Carousels2 from '../components/commercial2';
import Carousels3 from '../components/commercial3';
import NeedsTable from '../components/needsTable';
import { auth, db } from '../config/firebase';
import Footer from '../Footer';
import IPageProps from '../interfaces/page';

const useStyles = makeStyles({
    flexGrow: {
        flex: '1',
    },
    button: {
        fontSize: '13px',
        backgroundColor: '#fff',
        borderRadius: '1rem',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#FBAA13',
            color: '#07381B',
        },
    },
});

const HomePage: React.FunctionComponent<IPageProps> = (props) => {
    const [username, setUsername] = useState(auth.currentUser?.displayName);
    const [kakao, setKakao] = useState();
    const [totalApply, setTotalApply] = useState();
    const [totalNeed, setTotalNeed] = useState();
    const [messages, setMessages] = useState<any | null>(0);
    const history = useHistory();
    const userList = document.querySelector('.user');
    const setupUser = (data: any) => {
        data.forEach((doc: any) => {
            const user = doc.data();
        });
    };

    const [dispSetDone, setDispSetDone] = useState<boolean>(
        auth.currentUser?.displayName != undefined
    );

    db.collection('user')
        .get()
        .then((snapshot) => {
            setupUser(snapshot.docs);
        });
    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if (user?.displayName) {
                setDispSetDone(true);
            }
        });
    }, []);
    useEffect(() => {
        db.collection('user')
            .doc(auth.currentUser?.uid!)
            .get()
            .then((doc) => {
                if (doc.data()!) {
                    var inbox = doc.data()!.message;
                    var count = 0;
                    inbox!.forEach((data: any) => {
                        if (data.read === false) count += 1;
                    });
                    setMessages(count);
                }
                //finding unread messages counts
            });
        db.collection('totalClicks')
            .doc('apply')
            .get()
            .then((doc) => {
                if (doc.data()!) {
                    setTotalApply(doc.data()!.applied);
                }
            });
        db.collection('totalClicks')
            .doc('need')
            .get()
            .then((doc) => {
                if (doc.data()!) {
                    setTotalNeed(doc.data()!.applied);
                }
            });
    }, []);

    const saveUserName = (name: string) => {
        if (auth.currentUser?.displayName === '카카오') {
            auth.currentUser
                ?.updateProfile({
                    displayName: name,
                })
                .then(function () {
                    console.log('Display name updated');
                })
                .catch(function (error) {
                    console.error(error);
                });
            db.collection('user').doc(auth.currentUser?.uid).update({
                nickname: name,
            });
            history.push('/provider_fill');
        } else {
            auth.currentUser
                ?.updateProfile({
                    displayName: name,
                })
                .then(function () {
                    console.log('Display name updated');
                })
                .catch(function (error) {
                    console.error(error);
                });
            db.collection('user').doc(auth.currentUser?.uid).update({
                nickname: name,
            });
            setDispSetDone(true);
        }
    };
    const classes = useStyles();

    return (
        <div
            id="pot"
            style={{
                width: '100%',
                height: '100%',
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            }}
        >
            <div
                style={{
                    fontFamily: 'Pretendard-Light',
                    fontSize: '20px',
                    color: 'black',
                    width: '100%',
                    height: '100%',
                    margin: '0px 0 0 0',
                }}
            >
                {/* <div
                    style={{
                        width: '100%',
                        position: 'relative',
                        display: 'flex',
                    }}
                >
                    <Carousels3 />
                </div> */}
                <img
                    style={{
                        position: 'relative',
                        width: '95%',
                    }}
                    className="img-announce"
                    src="../videhome_noyes2.png"
                ></img>
                {/* <div
                    style={{
                        width: '90%',
                        border: 'solid 3px #1D7955',
                        margin:"auto",
                        marginTop:"30px",
                        display: 'flex',
                        flexDirection:"row",
                        justifyContent:"space-evenly",
                        fontFamily: 'Pretendard-Light',
                    }}
                >
                    <div style={{padding:"10px", color:"#1D7955"}}>수거해요</div>
                    <div style={{padding:"10px", color:"#1D7955", fontWeight:"bolder"}}>NO</div>
                    <div style={{padding:"10px", color:"#1D7955"}}>판매해요</div>
                    <div style={{padding:"10px", color:"#1D7955", fontWeight:"bolder"}}>YES</div>
                </div> */}
                <img
                    style={{
                        position: 'relative',
                        width: '100%',
                     
                    }}
                    className="img-announce"
                    src="../videhome_home1.png"
                ></img>
                {/* <div style={{
                    display:"flex",
                    flexDirection:"column",
                    fontSize:"25px",
                    fontWeight:"bolder",
                    padding: '30px 0px 10px 0px',
                }}>
                    <div style={{color:"#4C4948"}}>
                        그냥 버리지 마세요!
                    </div>
                    <div style={{color:"#1D7955"}}>
                        당신의 현명한 비움이 지구를 지킵니다
                    </div>

                </div> */}

                <div
                    style={{
                        width: '100%',
                        margin: 'auto',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                    }}
                >
                    <div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                border: 'solid 3px #1D7955',
                                width: ' 120px',
                                height: '120px',
                                padding: '10px',
                                margin: '20px 10px 10px 10px',
                            }}
                        >
                            <div
                                style={{
                                    margin: 'auto',
                                    color: '#1D7955',
                                    fontFamily: 'Pretendard-Light',
                                }}
                                onClick={() => {
                                    history.push('/apply');
                                }}
                            >
                                <div>쓰던물건</div>
                                <div style={{ fontWeight: 'bolder' }}>
                                    판매신청
                                </div>
                                <div
                                    style={{
                                        position: 'absolute',
                                        fontSize: '10px',
                                    }}
                                >
                                    총 신청: {totalApply} 건
                                </div>
                            </div>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                border: 'solid 3px #1D7955',
                                width: ' 120px',
                                height: '120px',
                                padding: '10px',
                                margin: '20px 10px 10px 10px',
                            }}
                        >
                            <div
                                style={{
                                    margin: 'auto',
                                    color: '#1D7955',
                                    fontFamily: 'Pretendard-Light',
                                }}
                                onClick={() => {
                                    history.push('/records');
                                }}
                            >
                                <div>지금까지</div>
                                <div style={{ fontWeight: 'bolder' }}>
                                    판매내역
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                border: 'solid 3px #1D7955',
                                width: ' 120px',
                                height: '120px',
                                padding: '10px',
                                margin: '20px 10px 10px 10px',
                            }}
                        >
                            <div
                                style={{
                                    margin: 'auto',
                                    color: '#1D7955',
                                    fontFamily: 'Pretendard-Light',
                                }}
                                onClick={() => {
                                    history.push('/need');
                                }}
                            >
                                <div>필요해요</div>
                                <div style={{ fontWeight: 'bolder' }}>
                                    구매신청
                                </div>
                                <div
                                    style={{
                                        position: 'absolute',
                                        fontSize: '10px',
                                        margin: 'auto',
                                        textAlign:"center"
                                    }}
                                >
                                    총 신청: {totalNeed} 건
                                </div>
                            </div>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                border: 'solid 3px #1D7955',
                                width: ' 120px',
                                height: '120px',
                                padding: '10px',
                                margin: '20px 10px 10px 10px',
                            }}
                        >
                            <div
                                style={{
                                    margin: 'auto',
                                    color: '#1D7955',
                                    fontFamily: 'Pretendard-Light',
                                }}
                                onClick={() => {
                                    history.push('/company_info');
                                }}
                            >
                                <div>동네에서</div>
                                <div style={{ fontWeight: 'bolder' }}>
                                    나의랭킹
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div style={{color:'#1D7955', fontWeight:600, fontSize:"25px", padding:"30px 0 10px 0"}}>
                    필 요 제 품 요 청 게 시 판
                </div> */}
                <div style={{padding:"20px 0px"}}>
                <img
                    className="img-announce"
                    src="../videhome_buy.png"
                ></img>
                </div>
                <div style={{width:"100%", margin:"auto"}}>
                <NeedsTable/>
                </div>
                <div style={{paddingTop:"10px 0"}}>
                <img
                    className="img-announce"
                    src="../signup_sign.png"
                ></img>
                </div>
                <img
                    className="img-announce"
                    src="../signup_procedure.png"
                ></img>
                <div style={{paddingBottom:"3px"}}>
                </div>
                    <img
                    className="img-announce"
                    src="../sell_sign.png"
                ></img>
                           <img
                    className="img-announce"
                    src="../sell_procedure.png"
                ></img>
                <img
                    className="img-announce"
                    src="../videhome_bottom.png"
                ></img>
                      
                {/* <div
                    style={{
                        height: '93px',
                        position: 'relative',
                        display: 'flex',
                    }}
                >
                    <Carousels2 />
                </div> */}
                <div
                    style={{
                        background: '#F5F5F5',
                        height: '300px',
                        padding: '20px',
                    }}
                >
                    <Footer />
                </div>

                {/* <img id="pot11" className="img-announce" src="../videhome_title.gif"></img>
               <img id="pot11" className="img-announce" src="../videhome_title2.gif"></img> */}
            </div>
            {/* <div className="Header_center" style={{paddingTop:"50px"}}>
                    <div className="child" id="pot33">
                        <img className="img-item" src="../001.png" onClick={()=>{history.push('/apply')}}></img>
                    </div>
                    <div className="child" id="pot33">
                        <img className="img-item" src="../002.png" onClick={()=>{history.push('/transaction')}}></img>
                    </div>
                    <div className="child" id="pot33">
                        <img className="img-item" src="../003.png" onClick={()=>{history.push('/direction')}}></img>
                    </div>
                    <div className="child" id="pot33">
                        <img className="img-item" src="../004.png" onClick={()=>{history.push('/setting')}}></img>
                    </div>
            </div> */}
        </div>
    );
};

export default HomePage;
