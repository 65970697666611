import React, { useState } from 'react';
import { Button, FormControlLabel, withStyles } from '@material-ui/core';
import button from '../../components/button';
import items from '../../components/items';
import items2 from '../../components/items2';
import { useDispatch, useSelector } from 'react-redux';
import { ERState } from '../../ERReducer';
import Er_rate from '../../components/er_rate';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { green } from '@material-ui/core/colors';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { useHistory } from 'react-router-dom';

import IPageProps from '../../interfaces/page';
import SearchBar from '../../components/searchBar';
const Collect: React.FunctionComponent<IPageProps> = (props) => {
    const user = useSelector<ERState, ERState['user']>((state) => state.user);
    // const history = useHistory();
    // history.push("/apply")

    return (
        <div className="Apps" id="pot">
            <div
                style={{
                    color: 'black',
                    fontSize: '20px',
                    margin: '35px 0 10px 0',
                }}
            >
                <SearchBar />
                <img
                    style={{ margin: '10px 0 10px 0' }}
                    id="pot"
                    className="img-announce"
                    src="./videhome_apply2.png"
                ></img>
            </div>
            <div className="left">
                <div className="wrapper">{button()}</div>
            </div>
            <div className="middles">
                <div className="top"></div>
                {/* <div className="wrapper">
          {items2()}
          </div>  */}
            </div>
        </div>
    );
};

export default Collect;
