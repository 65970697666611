import React from 'react';
import './App.css';
import Footer from './Footer';
import Application from './application';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BottomNav from './components/bottomNav';



export interface UserState {
    user: {
        isAuthorized: boolean;
        isLoading: boolean;
    };
    page: any;
    value: any;
}


export default class App extends React.Component<{}, UserState> {
    constructor(props?: any, context?: any) {
        super(props, context);
        this.state = {
            user: {
                isAuthorized: false,
                isLoading: false,
            },
            page: ["/","/apply","/setting","/records"],
            value: "/"
        };
    }

    public render() {
        // const handleChange = (event:any, newValue:any) => {
        //     this.setState({

        //     })
        //   };
        return (
            <>
                <div className="wrap">
                    <div
                        className="header_top"
                        style={{ display: 'flex', flexDirection: 'column' }}
                    >
                        {/* <div style={{ width: '100%' }}>
                            <img src="./videhome_header.png" alt=""></img>
                        </div> */}
                    </div>

                    {/* <div style={{width:"100%", justifyContent:"center", display:"flex"}}>
                        <img className="img-topnav-2"src="./videhome_logo.png"></img>
                    </div> */}

                    <div className="App">
                        {this.state.user.isLoading && (
                            <div className="spinner-div"></div>
                        )}
                        <div className="Body">
                            <Application />
                        </div>
                        {/* <Footer /> */}
                    </div>
                </div>
            </>
        );
    }
}
