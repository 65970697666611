import React from "react";
import { loadSdk, DEFAULT_STYLE } from "./utils";
import { ExtendedWindow, Props, State } from "./types";


declare let window: ExtendedWindow;

export default class KakaoUnlink extends React.PureComponent<Props, State> {
  state = { isLoggedIn: false };

  public static DEFAULT_STYLE = DEFAULT_STYLE;

  public async componentDidMount() {
    await loadSdk();
    window.Kakao.init(this.props.token);
  }

  private onButtonClick = () => {
    const {
      throughTalk = true,
      persistAccessToken = true,
      needProfile = true,
      useLoginForm = false,
      onSuccess,
      onFail,
    } = this.props;

    const method = useLoginForm ? "loginForm" : "login";

    // const signInWithEmailAndPassword = () => {
    //   if (error !== '') setError('');

    //   setAuthenticating(true);

    //   db.collection('admin').doc(email).get().then((doc)=>{
    //       if (!doc.exists) {
    //           auth.signInWithEmailAndPassword(email, password)
    //           .then(result => {
    //               logging.info(result);
    //               history.push('/');
    //           })
    //           .catch(error => {
    //               logging.error(error);
    //               if (error.code.includes('auth/wrong-password'))
    //               {
    //                   setError('비밀번호를 다시 입력 해주세요.');
    //               }
    //               else{
    //                   setError('아이디가 존재하지 않습니다.');
    //               }
    //               setAuthenticating(false);
    //           });
    //       }
    //       else{
    //           setError('관리자모드가 아닙니다.');
    //           setAuthenticating(false);
    //       } 
    //   })

    (window.Kakao?.Auth)[method]({
      throughTalk,
      persistAccessToken,
      success: (response) => {
        this.setState({ isLoggedIn: true });

        if (needProfile) {
          window.Kakao?.API.request({
            url: "/v1/user/unlink",
            success: (profile) => {
              const result = { response, profile };
              onSuccess(result);
         
            },
            fail: onFail,
          });
        } else {
          onSuccess({ response });
        }
      },
      fail: onFail,
    });
  };

  private onLogout = () => {
    window.Kakao?.Auth.logout(() => {
      this.props.onLogout?.(window.Kakao?.Auth.getAccessToken());
      this.setState({ isLoggedIn: false });
    });
  };

  public render() {
    const { isLoggedIn } = this.state;
    const onClick = isLoggedIn ? this.onLogout : this.onButtonClick;
    const {
      render,
      // className = "",
      // style = DEFAULT_STYLE,
      children = "카카오 Unlink",
    } = this.props;

    if (typeof render === "function") {
      return render({ onClick });
    }

    return (
      // <button
      //   type="button"
      //   className={className}
      //   onClick={onClick}
      //   style={style}
      // >
      //   {children}
      // </button>
      <button onClick={onClick} style={{border:"solid 2px", borderRadius:"2rem", background:"rgb(255, 235, 0)", color:"rgb(60, 30, 30)", fontFamily: 'TmoneyRoundWindExtraBold'}}>
        {children}
      </button>
    //   <img
    //   src="//k.kakaocdn.net/14/dn/btqCn0WEmI3/nijroPfbpCa4at5EIsjyf0/o.jpg"
    //   width="70%"
    //   onClick={onClick}
    //   style={{margin:"auto"}}
    // />
    );
  }
}
