import {
    FormControl,
    InputAdornment,
    TextField,
    Button,
    Select,
    withStyles,
    Theme,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import ErrorText from '../../components/ErrorText';
import { auth, db } from '../../config/firebase';
import logging from '../../config/logging';
import IPageProps from '../../interfaces/page';
import DaumPostcode from 'react-daum-postcode';
import { Alert, createStyles } from '@mui/material';
import InputBase from '@material-ui/core/InputBase';
import MenuItem from '@material-ui/core/MenuItem';

const BootstrapInput = withStyles((theme: Theme) =>
    createStyles({
        root: {
            'label + &': {
                position: 'relative',
            },
        },
        input: {
            borderRadius: 4,
            position: 'relative',
            backgroundColor: theme.palette.background.paper,
            border: 'none',
            fontSize: 16,
            padding: '10px 26px 10px 12px',
            transition: theme.transitions.create([
                'border-color',
                'box-shadow',
            ]),
            // Use the system font instead of the default Roboto font.
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            '&:focus': {
                borderRadius: 4,
                borderColor: '#80bdff',
                boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
            },
        },
    })
)(InputBase);


const RegisterPage: React.FunctionComponent<IPageProps> = (props) => {
    const [registering, setRegistering] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [confirm, setConfirm] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [address, setAddress] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [additionalAddress, setAdditionalAddresss] = useState<string>(''); // 주소
    const history = useHistory();
    const [num,setNum] = useState<string>('');
    const [banks, setBanks] = useState([]);
    const [selectedBank, setSelectedBank] = useState('국민은행');
    const [accountNumber, setAccountNumber] = React.useState();

    useEffect(()=>{
        if(db){
            db.collection('infos')
            .doc('banks')
            .get()
            .then((doc) => {
                setBanks(doc.data()!.korea);
            });
        }
    },[])


    const signUpWithEmailAndPassword = () => {
        // if (address.slice(0,6) !== "경기 고양시"){
        //     setError("현재 고양시에 거주하시는분들만 서비스를 이용하실수 있습니다. 양해 부탁드립니다."); 
        //     return;
        // }
        if (password !== confirm) {
            setError('비밀번호가 같은지 다시확인해 주시기 바랍니다.');
            return;
        }
        if (password.length < 8 ){
            setError("비밀번호는 8 자 이상으로 지정해주세요.");
            return;
        }
        if (password.search(/[a-z]/i) < 0) {
            setError("비밀번호는 최소 글자 하나가 필요합니다.");
            return;
        }
        if (password.search(/[0-9]/) < 0) {
            setError("비밀번호는 최소 번호 하나가 들어가야합니다."); 
            return;
        }
        if (error !== '') setError('');
        setRegistering(true);
        auth.createUserWithEmailAndPassword(email, password)
            .then((result) => {
                logging.info(result);
                db.collection('user').doc(auth.currentUser?.uid).set({
                    userId: email,
                    name: name,
                    address: address + additionalAddress,
                    phone: phone,
                    orders: [],
                    totalWeight: 0,
                    totalAdditional: 0,
                    totalWeightConverted:0,
                    numberOfOrders: 0,
                    averageWeights: 0,
                    privacyAgree: false,
                    rank: 0,
                    uid: auth.currentUser?.uid,
                    nickname: '',
                    message: [],
                    accountNumber: accountNumber,
                    bank_type: selectedBank
                });
                history.push('/');
            })
            .catch((error) => {
                logging.error(error);

                if (error.code.includes('auth/weak-password')) {
                    setError('더욱 복잡한 비밀번호를 입력해주세요.');
                } else if (error.code.includes('auth/email-already-in-use')) {
                    setError('이미 사용중에있는 이메일입니다.');
                } else if (error.code.includes('auth/invalid-email')) {
                    setError('이메일 형식에 맞춰 작성해주세요.');
                } else {
                    setError('서버가 다운되었습니다. 나중에 다시 해주세요.');
                }

                setRegistering(false);
            });
    };


    const handleChange = (prop: string) => (event: any) => {
        if (prop == 'email_') {
            setEmail(event.target.value.toLocaleLowerCase());
        } else if (prop == 'password_') {
                setPassword(event.target.value);   
        } else if (prop == 'confirm_') {
            setConfirm(event.target.value);
        } else if (prop == 'name_') {
            setName(event.target.value);
        } else if (prop == 'address_') {
            setAddress(event.target.value);
        } else if (prop == 'additionalAddress_') {
            setAdditionalAddresss(event.target.value);
        }        else if (prop === 'bank_acc_') {
            setSelectedBank(event.target.value);
        } else if (prop === 'accountNumber_') {
            const onlyNums = event.target.value.replace(/[^0-9]/g, '');
            setAccountNumber(onlyNums);
        } 
        else if (prop == 'phone_') {
            const onlyNums = event.target.value.replace(/[^0-9]/g, '');
            if (onlyNums.length < 11) {
                setPhone(onlyNums);
            } else if (onlyNums.length === 11) {
                setNum(event.target.value.replace(/[^0-9]/g, ''));
                const number = onlyNums.replace(
                    /(\d{3})(\d{4})(\d{4})/,
                    '($1) $2-$3'
                );
                setPhone(number);
            }
        }
    };
    const onCompletePost = (data: any) => {
        let fullAddr = data.address;
        let extraAddr = '';

        if (data.addressType === 'R') {
            if (data.bname !== '') {
                extraAddr += data.bname;
            }
            if (data.buildingName !== '') {
                extraAddr +=
                    extraAddr !== ''
                        ? `, ${data.buildingName}`
                        : data.buildingName;
            }
            fullAddr += extraAddr !== '' ? ` (${extraAddr})` : '';
        }

        setAddress(data.zonecode);
        setAddress(fullAddr);
        setIsOpenPost(false);
    };

    const [isOpenPost, setIsOpenPost] = useState(false);

    const onChangeOpenPost = () => {
        setIsOpenPost(!isOpenPost);
    };

    return (
        <div className="fade" style={{ margin: '40px' }}>
            {registering && (
                <div className="loader-wrapper">
                    <span className="loader">
                        <span className="loader-innder"></span>
                    </span>
                    <span className="loader_text">회원 가입중 입니다..</span>
                </div>
            )}
            <img className="img-logo-login" src="./videhome_logo.png"></img>
            <img
                    id="pot"
                    className="img-announce"
                    src="../videhome_apply.png"
                ></img>
            <div style={{ color: 'black' }}>
                <FormControl style={{ width: '100%' }}>
                    <TextField
                        autoComplete="new-password"
                        name="confirm"
                        id="confirm"
                        style={{
                            top: '2px',
                            marginBottom: '25px',
                            color: 'black',
                        }}
                        placeholder="성명입력"
                        type="input"
                        onChange={handleChange('name_')}
                        label="이름"
                        inputProps={{
                            style: {
                                textAlign: 'left',
                                fontSize: '19px',
fontFamily: "Pretendard-Light",
                            },
                            'aria-label': 'naked',
                            startAdornment: (
                                <InputAdornment position="start"></InputAdornment>
                            ),
                        }}
                        InputLabelProps={{
                            style: {
                                fontSize: '15px',
fontFamily: "Pretendard-Light",
                            },
                        }}
                    />
        <TextField
                        autoComplete="new-password"
                        name="confirm"
                        id="confirm"
                        style={{
                            top: '2px',
                            marginBottom: '25px',
                            color: 'black',
                        }}
                        placeholder="주소를 입력해주세요"
                        type="input"
                        onChange={handleChange('address_')}
                        label="주소"
                        disabled
                        onClick={()=>{setIsOpenPost(!isOpenPost)}}
                        value={address}
                        inputProps={{
                            style: {
                                textAlign: 'left',
                                fontSize: '19px',
fontFamily: "Pretendard-Light",
                            },
                            'aria-label': 'naked',
                            startAdornment: (
                                <InputAdornment position="start"></InputAdornment>
                            ),
                        }}
                        InputLabelProps={{
                            style: {
                                fontSize: '15px',
fontFamily: "Pretendard-Light",
                            },
                        }}
                    />
                    {(isOpenPost) &&
                    <DaumPostcode
                        style={{
                            display: 'block',
                            position: 'relative',
                            top: '0%',
                            width: '100%',
                            height: '300px',
                            padding: '7px',
                        }}
                        autoClose
                        onComplete={onCompletePost}
                    />
                    }
                    {(address.slice(0,6) !== "경기 고양시")?          <div style={{ fontFamily: 'EliceDigitalBaeum_Bold'} }id="pot2" >
                        {/* <Alert severity="error" style={{  fontFamily: 'EliceDigitalBaeum_Bold', background:"rgb(253, 237, 237)"}}>
                        현재 고양시 주민들만 이용가능합니다. </Alert> */}
                        </div>:<div style={{ fontFamily: 'EliceDigitalBaeum_Bold'} }id="pot2" >
                        <Alert severity="success" style={{  fontFamily: 'EliceDigitalBaeum_Bold', background:"rgb(237, 247, 237)"}}>
                        주소 확인 되었습니다. </Alert>
                        </div>}
                     <TextField
                        autoComplete="new-password"
                        name="confirm"
                        id="confirm"
                        style={{
                            top: '2px',
                            marginBottom: '25px',
                            color: 'black',
                        }}
                        placeholder="주소를 입력해주세요"
                        type="input"
                        onChange={handleChange('additionalAddress_')}
                        label="추가 주소"
                        inputProps={{
                            style: {
                                textAlign: 'left',
                                fontSize: '19px',
fontFamily: "Pretendard-Light",
                            },
                            'aria-label': 'naked',
                            startAdornment: (
                                <InputAdornment position="start"></InputAdornment>
                            ),
                        }}
                        InputLabelProps={{
                            style: {
                                fontSize: '15px',
fontFamily: "Pretendard-Light",
                            },
                        }}
                    />
                    <TextField
                        autoComplete="new-password"
                        name="confirm"
                        id="confirm"
                        style={{
                            top: '2px',
                            marginBottom: '25px',
                            color: 'black',
                        }}
                        placeholder="- 없이 숫자만 입력"
                        type="input"
                        value={phone}
                        onChange={handleChange('phone_')}
                        label="휴대폰번호"
                        inputProps={{
                            style: {
                                textAlign: 'left',
                                fontSize: '19px',
fontFamily: "Pretendard-Light",
                            },
                            'aria-label': 'naked',
                            startAdornment: (
                                <InputAdornment position="start"></InputAdornment>
                            ),
                        }}
                        InputLabelProps={{
                            style: {
                                fontSize: '15px',
fontFamily: "Pretendard-Light",
                            },
                        }}
                    />
                      {/* <div id="pot" style={{ padding: '0 0 20px 0' }}>
                        <Button
                            variant="outlined"
                            onClick={() => {
                               setUpRecaptcha(num);
                            }}
                            style={{
                                width: '65%',
                                padding: '10px',
                                margin: 'auto',
                                border: 'solid 2px',
                                borderRadius: '10rem',
                                color: 'black',
fontFamily: "Pretendard-Light",
                            }}
                        >
                            본인인증
                        </Button>
                        </div> */}
                     <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            fontSize: '15px',
                            paddingBottom: '20px',
                        }}
                    >
                        <div style={{ width: '30%' }}>
                            <div
                                style={{
                                    textAlign: 'left',
                                    color: 'rgba(0, 0, 0, 0.54)',
                                    marginTop: '6px',
                                }}
                            >
                                은행/계좌번호
                            </div>
                            <div
                                style={{
                                    position: 'relative',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-evenly',
                                    textAlign: 'center',
                                    width: '100%',
                                }}
                            >
                                <div
                                    style={{
                                        position: 'relative',
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}
                                >
                                    <div
                                        style={{
                                            width: '100%',
                                            position: 'relative',
                                        }}
                                    >
                                        <Select
                                            label="시간"
                                            labelId="demo-customized-select-label"
                                            id="demo-customized-select"
                                            value={selectedBank}
                                            onChange={handleChange('bank_acc_')}
                                            input={<BootstrapInput />}
                                        >
                                            {banks!.map((key) => {
                                                return (
                                                    <MenuItem value={key}>
                                                        {key}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <TextField
                                style={{
                                    top: '20px',
                                    marginBottom: '25px',
                                    fontSize: '15px',
                                }}
                                id="input-with-icon-TextField"
                                type="input"
                                InputLabelProps={{
                                    style: {
                                        fontSize: '20px',
                                    },
                                }}
                                InputProps={{
                                    style: {
                                        fontSize: '15px',

                                        padding: '10px 0 0 0',
                                    },
                                    startAdornment: (
                                        <InputAdornment position="start"></InputAdornment>
                                    ),
                                }}
                                placeholder="xxx-xxxx-xxxx"
                                value={accountNumber}
                                onChange={handleChange('accountNumber_')}
                            />
                        </div>
                    </div>
                    <TextField
                        style={{ top: '2px', marginBottom: '25px' }}
                        id="input-with-icon-textfield"
                        placeholder="이메일 주소 입력"
                        type="input"
                        onChange={handleChange('email_')}
                        label="이메일 (ID)"
                        inputProps={{
                            style: {
                                textAlign: 'left',
                                fontSize: '19px',
fontFamily: "Pretendard-Light",
                            },
                            'aria-label': 'naked',
                            startAdornment: (
                                <InputAdornment position="start"></InputAdornment>
                            ),
                        }}
                        InputLabelProps={{
                            style: {
                                fontSize: '15px',
fontFamily: "Pretendard-Light",
                            },
                        }}
                    />
                    <TextField
                        autoComplete="new-password"
                        style={{
                            top: '2px',
                            marginBottom: '25px',
                            color: 'black',
                        }}
                        id="input-with-icon-textfield"
                        placeholder="********"
                        type="password"
                        onChange={handleChange('password_')}
                        label="비밀번호"
                        inputProps={{
                            style: { textAlign: 'left', fontSize: '19px' },
                            'aria-label': 'naked',
                            startAdornment: (
                                <InputAdornment position="start"></InputAdornment>
                            ),
                        }}
                        InputLabelProps={{
                            style: {
                                fontSize: '15px',
fontFamily: "Pretendard-Light",
                            },
                        }}
                    />
                    <TextField
                        autoComplete="new-password"
                        name="confirm"
                        id="confirm"
                        style={{
                            top: '2px',
                            marginBottom: '25px',
                            color: 'black',
                        }}
                        placeholder="********"
                        type="password"
                        onChange={handleChange('confirm_')}
                        label="비밀번호 재입력"
                        inputProps={{
                            style: { textAlign: 'left', fontSize: '19px' },
                            'aria-label': 'naked',
                            startAdornment: (
                                <InputAdornment position="start"></InputAdornment>
                            ),
                        }}
                        InputLabelProps={{
                            style: {
                                fontSize: '15px',
fontFamily: "Pretendard-Light",
                            },
                        }}
                    />
                    <div id="pot" style={{ padding: '0 0 20px 0' }}>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                signUpWithEmailAndPassword();
                            }}
                            style={{
                                width: '65%',
                                padding: '10px',
                                margin: 'auto',
                                border: 'solid 2px',
                                borderRadius: '10rem',
                                color: 'black',
fontFamily: "Pretendard-Light",
                            }}
                        >
                            회원가입
                        </Button>
                    </div>

                    <div style={{ fontSize: '15px', padding: '10px' }}>
                        <small>
                            <p className="m-1 text-center">
                                이미 회원이신가요?{' '}
                                <Link to="/login">로그인.</Link>
                            </p>
                        </small>
                    </div>
                    <div
                        style={{
                            padding: '10px',
fontFamily: "Pretendard-Light",
                            color: 'red',
                        }}
                    >
                        <ErrorText error={error} />
                    </div>
                </FormControl>
            </div>
        </div>
    );
};

export default RegisterPage;
