import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const Carousels = () => {
    return (
    
        <Carousel autoPlay infiniteLoop useKeyboardArrows interval={5000} >
            <div style={{ justifyContent:"center", position:"relative", display:"flex", border:"solid 1px grey",width:"100%"}}>
                <img
                    className="img-fluid"
                    src={'../videhome_commercial2.png'}
                    alt=""
                    key="1"
                ></img>
            </div>
            <div style={{ justifyContent:"center", position:"relative", display:"flex", border:"solid 1px grey",width:"100%"}}>
                <img
                    className="img-fluid"
                    src={'../videhome_commercial.jpg'}
                    alt=""
                    key="2"
                ></img>
            </div>
            {/* <div style={{ justifyContent:"center", position:"relative", display:"flex", border:"solid 1px grey",width:"100%"}}>
                <img
                    className="img-fluid"
                    src={'../banner4.png'}
                    alt=""
                    key="2"
                ></img>
            </div> */}
            {/* <div style={{margin:"10%", justifyContent:"center", position:"relative", display:"flex"}}>
                <img
                    className="img-fluid"
                    src={'../videhome_commercial.jpg'}
                    alt=""
                    key="3"
                ></img>
            </div>
            <div style={{margin:"10%", justifyContent:"center", position:"relative", display:"flex"}}>
                <img
                    className="img-fluid"
                    src={'../videhome_commercial.jpg'}
                    alt=""
                    key="4"
                ></img>
            </div> */}
        </Carousel>

    );
};

export default Carousels;
