import React, { useEffect, useState } from 'react';
import LocalDrinkIcon from '@material-ui/icons/LocalDrink';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';
import clsx from 'clsx';
import {
    createStyles,
    lighten,
    makeStyles,
    Theme,
} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import SearchIcon from '@material-ui/icons/Search';
import { useHistory } from 'react-router-dom';
import { auth, db } from '../../config/firebase';
import IPageProps from '../../interfaces/page';
import { Button, TablePagination } from '@material-ui/core';

interface Data {
    confirmed: string;
    weight: number;
    additional: number;
    date: string;
    // total:number
}
function createData(
    date: string,
    confirmed: string,
    weight: number,
    additional: number
    // total:number
): Data {
    return { date, confirmed, weight, additional };
}
const rows: Data[] = [];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const headCells: HeadCell[] = [
    { id: 'date', numeric: false, disablePadding: true, label: '날짜' },
    { id: 'confirmed', numeric: false, disablePadding: false, label: '접수' },
    { id: 'weight', numeric: false, disablePadding: false, label: '무게금액' },
    {
        id: 'additional',
        numeric: false,
        disablePadding: false,
        label: '합계금액',
    },
    // { id: 'total', numeric: true, disablePadding: false, label: '총' },
];

interface EnhancedTableProps {
    classes: ReturnType<typeof useStyles>;
    numSelected: number;
    onRequestSort: (
        event: React.MouseEvent<unknown>,
        property: keyof Data
    ) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const {
        classes,
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
    } = props;
    const createSortHandler = (property: keyof Data) => (
        event: React.MouseEvent<unknown>
    ) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={
                            numSelected > 0 && numSelected < rowCount
                        }
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{
                            minWidth: '60px',
                            textAlign: 'center',
                   fontFamily: "Pretendard-Light",
                   fontWeight:600,
                            fontSize: '13px',
                            padding: '0',
                        }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc'
                                        ? 'sorted descending'
                                        : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const useToolbarStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
            width: '100%',
        },
        highlight:
            theme.palette.type === 'light'
                ? {
                      color: 'black',
                      backgroundColor: lighten('#FBAA13', 0.9),
                  }
                : {
                      color: theme.palette.text.primary,
                      backgroundColor: theme.palette.secondary.dark,
                  },
        title: {
            flex: '1 1 100%',
        },
    })
);

interface EnhancedTableToolbarProps {
    numSelected: number;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            margin: 'auto',
            padding: '10px 0px 0px 0px',
            border: 'none',
            overflowX: 'hidden',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(3),
            boxShadow: 'none',
            border: 'none',
        },
        table: {
            minWidth: 300,
            padding: '0px',
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
        
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 10,
            width: 1,
   fontFamily: "Pretendard-Light",
   fontWeight:600,
        },
    })
);

const RecordPage: React.FunctionComponent<IPageProps> = (props) => {
    const classes = useStyles();
    const [order, setOrder] = React.useState<Order>('desc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('date');
    const [selected, setSelected] = React.useState<string[]>([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    //newData
    const [PID, setPID] = useState();
    const [loggedIn, setLoggedIn] = useState(false);
    const [found, setFound] = useState(false);
    const [orderHistory, setOrderHistory] = useState<Data[]>([]);
    const [orderDataTalbe, setOrderDataTable] = useState();
    const [selectedOrder, setSelectedOrder] = React.useState<any>();
    const [currentWeight, setCurrentWeight] = useState<any>();
    const [currentAdditional, setCurrentAdditional] = useState<any>();
    const [userTotalWeight, setUserTotalWeight] = useState<any>();
    const [userAverageWeight, setUserAverageWeight] = useState<any>();
    const [countKG, setCountKG] = useState<any>(0);

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if (user) {
                db.collection('user')
                    .doc(auth.currentUser?.uid!)
                    .get()
                    .then((doc) => {
                        if (doc.exists) {
                            if (doc.data()!.orders.length >= 1) {
                                if (
                                    doc.data()!.userId ===
                                    auth.currentUser?.email!
                                ) {
                                    var count = 0;

                                    var kgList =   doc.data()!.orders[
                                        doc.data()!.numberOfOrders - 1
                                    ].category;

                                    Object.keys(kgList).map((key: any) => {
                                        count =
                                            Number(count) + Number(kgList[key]);
                                    });
                                    setCountKG(count);
                                    setPID(doc.data()!.phone);
                                    setLoggedIn(true);
                                    setOrderHistory(doc.data()!.orders);
                                    setCurrentWeight(
                                        doc.data()!.orders[
                                            doc.data()!.numberOfOrders - 1
                                        ].weight
                                    );
                                    setCurrentAdditional(
                                        doc.data()!.orders[
                                            doc.data()!.numberOfOrders - 1
                                        ].additional
                                    );
                                    setFound(true);
                                    setUserTotalWeight(doc.data()!.totalWeight);
                                    setUserAverageWeight(
                                        doc.data()!.averageWeights
                                    );
                                }
                            } else {
                                setPID(doc.data()!.phone);
                                setLoggedIn(true);
                                setFound(true);
                                console.log('user has no documents.');
                            }
                        }
                    });
            } else {
                console.log('User Not Found. Proceed with non_user');
            }
        });
    }, [...orderHistory]);
    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.date);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected.slice(1), name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        }

        setSelected(newSelected);
    };

    const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDense(event.target.checked);
    };

    const isSelected = (name: string) => selected.indexOf(name) !== -1;

    const emptyRows =
        rowsPerPage -
        Math.min(rowsPerPage, orderHistory.length - page * rowsPerPage);

    const findInfo = () => {
        if (PID) {
            db.collection('user')
                .get()
                .then((querySnapshot) => {
                    const documents = querySnapshot.docs.map((doc) =>
                        doc.data()
                    );
                    var list: any[] = [];
                    documents.forEach((doc: any) => {
                        // setOrderHistory([...doc])
                        list.push(doc);
                    });

                    const filtered = list.filter(
                        (order) => order.phone! === PID
                    );

                    if (filtered.length != 0) {
                        setOrderHistory([...filtered[0].orders]);
                        setFound(true);
                    } else {
                        list.forEach((doc: any) => {
                            if (doc.count) {
                                const filtered2 = doc.orders!.filter(
                                    (order: any) => order.phone! === PID
                                );

                                if (filtered2.length != 0) {
                                    setOrderHistory([...filtered2!]);
                                    setFound(true);
                                } else {
                                    alert(
                                        '회원번호 또는 신청번호가 존재하지 않습니다. 다시입력해 주세요.'
                                    );
                                    setFound(false);
                                }
                            }
                        });
                    }
                });
        } else {
            alert(
                '회원번호 또는 신청번호가 존재하지 않습니다. 다시입력해 주세요.'
            );
            setFound(false);
        }
    };
    const formatNumber = (inputNumber: number) => {
        let formatedNumber = Number(inputNumber)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,');
        let splitArray = formatedNumber.split('.');
        if (splitArray.length > 1) {
            formatedNumber = splitArray[0];
        }
        return formatedNumber;
    };
    const history = useHistory();

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const goBack = () => {
        history.push('/setting');
    };

    const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
        const classes = useToolbarStyles();
        const { numSelected } = props;

        return (
            <Toolbar
                className={clsx(classes.root, {
                    [classes.highlight]: numSelected > 0,
                })}
                style={{ padding: '10px 0', fontSize: '20px' }}
            >
                {numSelected > 0 ? (
                    <Typography
                        style={{ fontFamily: 'EliceDigitalBaeum_Bold' }}
                        className={classes.title}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        {numSelected} 개 선택
                    </Typography>
                ) : (
                    <Typography
                        style={{ fontFamily: 'EliceDigitalBaeum_Bold' }}
                        className={classes.title}
                        variant="h6"
                        id="pot"
                        component="div"
                    >
                        <img
                            className="img-announce"
                            src="../videhome_history.png"
                        ></img>
                    </Typography>
                )}
                {numSelected > 0 ? (
                    <Tooltip title="신청서 보기">
                        <div
                            style={{
                       fontFamily: "Pretendard-Light",
                       fontWeight:600,
                                fontSize: '15px',
                                color: 'black',
                                padding: '0 10px',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'right',
                            }}
                            onClick={() => {
                                showModal();
                            }}
                        >
                            보기
                        </div>
                    </Tooltip>
                ) : (
                    // <Tooltip title="Filter list">
                    //   <IconButton aria-label="filter list">
                    //     <FilterListIcon />
                    //   </IconButton>
                    // </Tooltip>
                    <></>
                )}
            </Toolbar>
        );
    };
    const handleChange = (prop: string) => (event: any) => {
        if (prop == 'pid_') {
            const onlyNums = event.target.value.replace(/[^0-9]/g, '');
            if (onlyNums.length < 11) {
                setPID(onlyNums);
            } else if (onlyNums.length === 11) {
                const number = onlyNums.replace(
                    /(\d{3})(\d{4})(\d{4})/,
                    '($1) $2-$3'
                );
                setPID(number);
            }
        }
    };
    const showModal = () => {
        if (selected.length != 0) {
            const filtered: any = orderHistory.filter(
                (order) => order.date == selected[0]
            );
            var count = 0;

            var kgList = filtered[0].category;

            Object.keys(kgList).map((key: any) => {
                count = Number(count) + Number(kgList[key]);
            });
            setCountKG(count);
            setSelectedOrder(filtered[0]);
            setCurrentWeight(filtered[0].weight);
            setCurrentAdditional(filtered[0].additional);
        } else {
            alert('판매내역을 선택해주세요');
        }
    };

    return (
        <div>
            {auth.currentUser ? (
                <div
                    className="fade"
                    style={{ fontFamily: 'EliceDigitalBaeum_Bold' }}
                >
                    <div
                        style={{
                            width: '100%',
                            position: 'relative',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <div className="PIDON">
                            <div className={classes.root}>
                                <Paper className={classes.paper} style={{   boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"}}>
                                    <EnhancedTableToolbar
                                        numSelected={selected.length}
                                    />
                                    <TableContainer>
                                        <Table
                                            className={classes.table}
                                            aria-labelledby="tableTitle"
                                            size={dense ? 'small' : 'medium'}
                                            aria-label="enhanced table"
                                        >
                                            <EnhancedTableHead
                                                classes={classes}
                                                numSelected={selected.length}
                                                order={order}
                                                orderBy={orderBy}
                                                onSelectAllClick={
                                                    handleSelectAllClick
                                                }
                                                onRequestSort={
                                                    handleRequestSort
                                                }
                                                rowCount={orderHistory.length}
                                            />
                                            <TableBody>
                                                {stableSort(
                                                    orderHistory,
                                                    getComparator(
                                                        order,
                                                        orderBy
                                                    )
                                                )
                                                    .slice(
                                                        page * rowsPerPage,
                                                        page * rowsPerPage +
                                                            rowsPerPage
                                                    )
                                                    .map((row, index) => {
                                                        const isItemSelected = isSelected(
                                                            row.date
                                                        );
                                                        const labelId = `enhanced-table-checkbox-${index}`;

                                                        return (
                                                            <TableRow
                                                                hover
                                                                onClick={(
                                                                    event
                                                                ) =>
                                                                    handleClick(
                                                                        event,
                                                                        row.date
                                                                    )
                                                                }
                                                                role="checkbox"
                                                                aria-checked={
                                                                    isItemSelected
                                                                }
                                                                tabIndex={-1}
                                                                key={row.date}
                                                                selected={
                                                                    isItemSelected
                                                                }
                                                                style={{
                                                                    textAlign:
                                                                        'center',
                                                                    fontFamily:
                                                                        'EliceDigitalBaeum_Bold',
                                                                    padding:
                                                                        '0',
                                                                }}
                                                            >
                                                                <TableCell padding="checkbox">
                                                                    <Checkbox
                                                                        checked={
                                                                            isItemSelected
                                                                        }
                                                                        inputProps={{
                                                                            'aria-labelledby': labelId,
                                                                        }}
                                                                    />
                                                                </TableCell>
                                                                <TableCell
                                                                    style={{
                                                                        padding:
                                                                            '0',
                                                                        textAlign:
                                                                            'center',
                                                                fontFamily: "Pretendard-Light"
                                                                    }}
                                                                    component="th"
                                                                    id={labelId}
                                                                    scope="row"
                                                                    padding="none"
                                                                >
                                                                    {row.date.slice(
                                                                        -5
                                                                    )}
                                                                </TableCell>
                                                                <TableCell
                                                                    style={{
                                                                        padding:
                                                                            '0',
                                                                        textAlign:
                                                                            'center',
                                                                fontFamily: "Pretendard-Light"
                                                                    }}
                                                                    align="right"
                                                                >
                                                                    {
                                                                        row.confirmed
                                                                    }
                                                                </TableCell>
                                                                <TableCell
                                                                    style={{
                                                                        padding:
                                                                            '0',
                                                                        textAlign:
                                                                            'center',
                                                                fontFamily: "Pretendard-Light"
                                                                    }}
                                                                    align="right"
                                                                >
                                                                    {row.weight ==
                                                                    0 ? (
                                                                        <>
                                                                            진행중
                                                                        </>
                                                                    ) : row.confirmed.toString() ===
                                                                      '취소' ? (
                                                                        row.weight
                                                                    ) : (
                                                                        formatNumber(
                                                                            row.weight
                                                                        ) +
                                                                        ' 원'
                                                                    )}
                                                                </TableCell>
                                                                <TableCell
                                                                    style={{
                                                                        padding:
                                                                            '0',
                                                                        textAlign:
                                                                            'center',
                                                                fontFamily: "Pretendard-Light"
                                                                    }}
                                                                    align="right"
                                                                >
                                                                    {row.additional ==
                                                                    0 ? (
                                                                        <>
                                                                            진행중
                                                                        </>
                                                                    ) : row.confirmed.toString() ===
                                                                      '취소' ? (
                                                                        row.additional
                                                                    ) : (
                                                                        formatNumber(
                                                                            Number(
                                                                                row.additional +
                                                                                    row.weight
                                                                            )
                                                                        ) +
                                                                        ' 원'
                                                                    )}
                                                                </TableCell>
                                                                {/* <TableCell style={{ textAlign:"center", fontFamily: 'EliceDigitalBaeum_Bold'}} align="right">{(row.weight == 0)? <>진행중</>:Number(row.weight * 200) + Number(row.additional) + " 원"}</TableCell> */}
                                                            </TableRow>
                                                        );
                                                    })}
                                                {/* {emptyRows > 0 && (
                                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                                <TableCell colSpan={4} />
                                </TableRow>
                            )} */}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        component="div"
                                        count={orderHistory.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={
                                            handleChangeRowsPerPage
                                        }
                                    />
                                </Paper>
                                {/* <FormControlLabel
                        control={<Switch checked={dense} onChange={handleChangeDense} />}
                        label="줄이기" style={{padding:"0 20px"}}
                    /> */}
                            </div>
                            {/* <Button className="buttons" style={{fontFamily: 'EliceDigitalBaeum_Bold', padding:"4px"}} variant="outlined" onClick={()=>{showModal()}} >선택</Button> */}

                            <div
                                style={{
                                    fontSize: '30px',
                                    color: 'black',
                                    padding: '10px',
                                   fontFamily:'Pretendard-Light'
                                }}
                            >
                                {selected[0]} 정산금액
                            </div>
                            <div
                                className="total"
                                style={{
                                    padding: '0 0 0 0',
                                    width: '100%',
                                    fontSize: '20px',
                                    
                                }}
                            >
                                <div className="totalCate">
                                    <div className="totalCate_name" style={{fontSize:"15px"}}>
                                        무게금액
                                    </div>
                                    <div className="totalCate_price" style={{fontSize:"15px", margin:"auto",fontFamily:'Pretendard-Light',}}>
                                        {!currentWeight! ? (
                                            <>0 원</>
                                        ) : typeof currentWeight ===
                                          typeof Number ? (
                                            <>{currentWeight! + ' 원'}</>
                                        ) : (
                                            <>
                                                {formatNumber(currentWeight!) +
                                                    ' 원'}
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="totalCate">
                                <div className="totalCate_name" style={{fontSize:"15px"}}>
                                        추가금액
                                    </div>
                                    <div className="totalCate_price" style={{fontSize:"15px", margin:"auto",fontFamily:'Pretendard-Light',}}>
                                        {!currentAdditional! ? (
                                            <>0 원</>
                                        ) : typeof currentWeight ===
                                          typeof Number ? (
                                            <>{currentAdditional! + ' 원'}</>
                                        ) : (
                                            <>
                                                {formatNumber(
                                                    currentAdditional!
                                                ) + ' 원'}
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="totalCate">
                                <div className="totalCate_name" style={{fontSize:"15px"}}>
                                        평균금액
                                    </div>
                                <div className="totalCate_price" style={{fontSize:"15px", margin:"auto",fontFamily:'Pretendard-Light',}}>
                                        <>
                                            {Math.round(
                                                (Number(currentWeight!) +
                                                    Number(
                                                        currentAdditional!
                                                    )) /
                                                    Number(countKG!)
                                            ) + ' 원/KG'}
                                        </>
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{
                                    fontSize: '30px',
                                    color: 'black',
                                    padding: '30px 10px 10px 10px',
                                    fontFamily:'Pretendard-Light',
                   
                                }}
                            >
                                환경절약
                            </div>
                            <div
                                style={{
                                    width: '95%',
                                    position: 'relative',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-evenly',
                                    alignContent: 'center',
                                    color: 'black',
                                    margin: 'auto',
                                    padding: '0 0 30px 0',
                                }}
                            >
                                <div className="wrapper">
                                    <div
                                        className="ER_wrapper"
                                        style={{ width: '100%' }}
                                    >
                                        <div className="col1">
                                            <div className="row">
                                                <div className="icons">
                                                    <LocalDrinkIcon
                                                        color="primary"
                                                        fontSize="large"
                                                    />
                                                </div>{' '}
                                                {currentWeight != undefined ? (
                                                    <div
                                                        style={{
                                                            color: 'green',
                                                            fontWeight: 'bolder',
                                                            borderBottom:
                                                                'solid 2px',
                                                                
                                                        }}
                                                    >
                                                        {Math.round(
                                                            countKG! *
                                                                0.24 *
                                                                1000
                                                        ) + ' L'}
                                                        <br />
                                                    </div>
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                            <div
                                                className="row"
                                                style={{ top: '14px',fontFamily:'Pretendard-Light', }}
                                            >
                                                생수절약
                                            </div>
                                            <div
                                                className="row"
                                                style={{
                                                    borderBottom: 'none',
                                                    fontSize: '12px',fontFamily:'Pretendard-Light',
                                                }}
                                            >
                                                <div>WATER SAVING</div>
                                  
                                            </div>
                                        </div>
                                        <div className="col2">
                                            <div className="row">
                                                <div
                                                    className="icons"
                                                    style={{ color: 'red' }}
                                                >
                                                    <DirectionsCarIcon fontSize="large" />
                                                </div>{' '}
                                                {currentWeight != undefined ? (
                                                    <div
                                                        style={{
                                                            color: 'green',
                                                            fontWeight: 'bold',
                                                            borderBottom:
                                                                'solid 2px',
                                                                
                                                        }}
                                                    >
                                                        {Math.round(
                                                            countKG! * 12
                                                        ) + ' Km'}
                                                        <br />
                                                    </div>
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                            <div
                                                className="row"
                                                style={{ top: '14px' 
                                                ,fontFamily:'Pretendard-Light',}}
                                            >
                                                탄소절약
                                            </div>
                                            <div
                                                className="row"
                                                style={{
                                                    borderBottom: 'none',
                                                    fontSize: '12px',fontFamily:'Pretendard-Light',
                                                }}
                                            >
                                                <div>CARBON SAVING</div>
                         
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        fontSize: '10px',
                                        opacity: 0.4,
                                        padding: '10px',
                                        textAlign: 'left',
                                    }}
                                >
                                    환경절약 수치는 예시 자료 이며 향후 정확한
                                    데이터를 반영 하기위해 준비 하고있습니다.
                                </div>
                                <div style={{ padding: '20px 0 0 0' }}>
                                    <Button
                                        variant="outlined"
                                        onClick={() => goBack()}
                                        style={{
                                            width: '40%',
                                            margin: 'auto',
                                            minWidth: '30px',
                                            borderRadius: '10rem',
                                            fontFamily:'Pretendard-Light',
                                                boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
                                        }}
                                    >
                                        뒤로가기
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div></div>
                    </div>
                </div>
            ) : (
                <>
                    로그인 해주세요.
                    <div style={{ padding: '20px 0 0 0' }}>
                        <Button
                            variant="outlined"
                            onClick={() => goBack()}
                            style={{
                                width: '40%',
                                margin: 'auto',
                                minWidth: '30px',
                                border: 'solid 2px',
                                borderRadius: '10rem',
                       fontFamily: "Pretendard-Light",
                       fontWeight:600,
                            }}
                        >
                            뒤로가기
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
};

export default RecordPage;
