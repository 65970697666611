import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import HouseIcon from '@material-ui/icons/House';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import LoginIcon from '@material-ui/icons/LockOpen';
import StorefrontIcon from '@material-ui/icons/Storefront';
import HomeIcon from '@material-ui/icons/Home';
import AssistantDirectionIcon from '@mui/icons-material/AssistantDirection';
import CampaignIcon from '@mui/icons-material/Campaign';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import { auth, db } from '../config/firebase';
import { zIndex } from 'material-ui/styles';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

const TopNav = () => {
    const [page, setPage] = useState<Number | null>(0);
    const [messages, setMessages] = useState<any | null>(0);
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const history = useHistory();

    useEffect(() => {
      db.collection('user')
          .doc(auth.currentUser?.uid!)
          .get()
          .then((doc) => {
              if (doc.data()!) {
                  var inbox = doc.data()!.message;
                  var count = 0;
                  inbox!.forEach((data: any) => {
                      if (data.read === false) count += 1;
                  });
                  setMessages(count);
                  //finding unread messages counts
              }
          });
  }, [state.left]);
    const onClickHeaderBtn = (path: number, name: string) => {
        setPage(path);
        history.push(name);
    };

    const toggleDrawer = (anchor: Anchor, open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent
    ) => {
        if (
            event &&
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };
    const list = (anchor: Anchor) => (
        <Box
            sx={{
                width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250,
            }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
            style={{
              overflowX:"hidden"
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                }}
            >
                <img className="img-logo-side" src="./videhome_side.png"></img>
                <Button onClick={toggleDrawer('left', false)}>
                    <CloseIcon />
                </Button>
            </div>
            <div
                style={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    justifyContent: 'center',
                    textAlign: 'center',
                    background: '#07381B',
                    borderTop: 'solid 5px #FBAA13',
                    borderBottom: 'solid 5px #FBAA13',
                }}
            >
                <div
                    style={{
                        border: 'solid 2px #FBAA13',
                        background: 'white',
                        height: '100px',
                        width: '100px',
                        borderRadius: '100%',
                        margin: 'auto',
                        marginTop: '20px',
                        justifyContent: 'center',
                        zIndex: 2,
                    }}
                >
                    {(auth.currentUser?.photoURL !== null)?
                         <img
                  
                         className="img-logo"
                         src={auth.currentUser?.photoURL?.toString()}
                         style={{
                                    
                             width: '100%',
                             position: 'relative',
                             borderRadius:"100%",
                             zIndex:-1
                         }}
                     ></img>:
                    <img
                 
                        className="img-logo"
                        src="./videhome_logo.png"
                        style={{
                            margin: '10px',
                            width: '70%',
                            height:"100%",
                            position: 'relative',
                        }}
                    ></img>
}
                </div>
                <div
                    style={{
                fontFamily: "Pretendard-Light",
                        padding: '20px',
                        fontSize: '15px',
                        color: 'white',
                        fontWeight: "bolder"
                    }}
                >
                    {auth.currentUser?.displayName !== undefined ? (
                        <>안녕하세요, {auth.currentUser?.displayName + ' 님'}</>
                    ) : (
                        <> 로그인 해주세요</>
                    )}
                </div>
                <div>
                    <div></div>
                    <div></div>
                </div>
            </div>
            <Divider />
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    flexDirection: 'row',
                    padding: '20px 0',
            fontFamily: "Pretendard-Light",
    
                }}
            >
                <div
                    style={{ justifyContent: 'center', textAlign: 'center' }}
                    onClick={onClickHeaderBtn.bind(this, 1, '/login')}
                >
                    <div>
                        <LoginIcon fontSize="medium" />
                    </div>
                    <div style={{    fontFamily: "Pretendard-Light",}}>로그인</div>
                </div>
                <div
                    style={{ justifyContent: 'center', textAlign: 'center' }}
                    onClick={onClickHeaderBtn.bind(this, 1, '/apply')}
                >
                    <div>
                        <AddBusinessIcon fontSize="medium" />
                    </div>
                    <div>판매신청</div>
                </div>
                <div
                    style={{ justifyContent: 'center', textAlign: 'center' }}
                    onClick={onClickHeaderBtn.bind(this, 1, '/setting')}
                >
           {messages !== 0 && (
                                <div className="notification2">{messages}</div>
                            )}
                    <div>
                        <PermIdentityIcon fontSize="medium" />
                    </div>
                    <div>내정보</div>
                </div>
            </div>
            <Divider />
            <div
                style={{
                    textAlign: 'left',
  fontFamily: "Pretendard-Light",
  fontWeight:600,
                    padding: '20px',
                    fontSize: '20px',
                }}
            >
                비드홈
            </div>
            <div
                style={{
                    padding: ' 0 10px 10px 20px',
  fontFamily: "Pretendard-Light",
  fontWeight:600,
                }}
            >
                <div style={{ padding: '5px' }}>
                    <ListItem button key={'사업내용'}
                      onClick={onClickHeaderBtn.bind(this, 1, '/company_info')}>
                        <ListItemIcon>
                            <HomeIcon />
                        </ListItemIcon>
                        <ListItemText primary={'사업내용'} />
                    </ListItem>
                </div>
                <div style={{ padding: '5px' }}>
                    <ListItem
                        button
                        key={'매장찾기'}
                        onClick={onClickHeaderBtn.bind(this, 1, '/direction')}
                    >
                        <ListItemIcon>
                            <AssistantDirectionIcon />
                        </ListItemIcon>
                        <ListItemText primary={'매장찾기'} />
                    </ListItem>
                </div>
                <div style={{ padding: '5px' }}>
                    <ListItem
                        button
                        key={'공지사항'}
                        onClick={onClickHeaderBtn.bind(
                            this,
                            1,
                            '/bulletin_board'
                        )}
                    >
                        <ListItemIcon>
                            <CampaignIcon />
                        </ListItemIcon>
                        <ListItemText primary={'공지사항'} />
                    </ListItem>
                </div>
                <div style={{ padding: '5px' }}>
                    <ListItem
                        button
                        key={'Q&A'}
                        onClick={onClickHeaderBtn.bind(
                            this,
                            1,
                            '/Q&A'
                        )}
                    >
                        <ListItemIcon>
                            <ContactSupportOutlinedIcon/>
                        </ListItemIcon>
                        <ListItemText primary={'Q & A'} />
                    </ListItem>
                </div>
            </div>
            <Divider />
            {auth.currentUser?.uid ? 
                <>
            <div
                style={{
                    textAlign: 'left',
  fontFamily: "Pretendard-Light",
  fontWeight:600,
                    padding: '20px',
                    fontSize: '20px',
                }}
            >
                판매신청
            </div>
            <div style={{ padding: ' 0 10px 10px 20px' }}>
                <div style={{ padding: '5px' }}>
                    <ListItem
                        button
                        key={'판매신청'}
                        onClick={onClickHeaderBtn.bind(this, 1, '/apply')}
                    >
                        <ListItemIcon>
                            <AddBusinessIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={'판매신청'}
                            style={{ fontFamily: "Pretendard-Light", }}
                        />
                    </ListItem>
                </div>
                <div style={{ padding: '5px' }}>
                    <ListItem
                        button
                        key={'최근 거래내역'}
                        onClick={onClickHeaderBtn.bind(this, 1, '/transaction')}
                    >
                        <ListItemIcon>
                            <AttachMoneyIcon />
                        </ListItemIcon>
                        <ListItemText primary={'최근 거래내역'} />
                    </ListItem>
                </div>
                <div style={{ padding: '5px' }}>
                    <ListItem
                        button
                        key={'거래내역'}
                        onClick={onClickHeaderBtn.bind(this, 1, '/records')}
                    >
                        <ListItemIcon>
                            <AttachMoneyIcon />
                        </ListItemIcon>
                        <ListItemText primary={'거래내역'} />
                    </ListItem>
                </div>
                <div style={{ padding: '5px' }}>
                    <ListItem
                        button
                        key={'신청방법'}
                        onClick={onClickHeaderBtn.bind(this, 1, '/how_to_apply')}
                    >
                        <ListItemIcon>
                            <HelpOutlineIcon />
                        </ListItemIcon>
                        <ListItemText primary={'신청방법'} />
                    </ListItem>
                </div>
            </div>
            </>:<></>}
      
            {/* <List>
                {['All mail', 'Trash', 'Spam'].map((text, index) => (
                    <ListItem button key={text}>
                        <ListItemIcon>
                            {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                        </ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItem>
                ))}
            </List> */}
     
        </Box>
        
    );

    return (
        <div
            className="topnav"
            id="myTopnav"
            style={{ justifyContent: 'space-between', overflow:"hidden"}}
        >
            <a
                id="0"
                onClick={onClickHeaderBtn.bind(this, 0, '/')}
                className={page == 0 ? 'pressed' : ''}
            >
                <img
                    style={{ width: '100%' }}
                    src="./videhome_side.png"
                ></img>
            </a>
            {/* <a
                id="1"
                onClick={onClickHeaderBtn.bind(this, 1, '/apply')}
                className={page == 1 ? 'pressed' : ''}
            >
                판매신청
            </a>
            <a
                id="2"
                onClick={onClickHeaderBtn.bind(this, 2, '/transaction')}
                className={page == 2 ? 'pressed' : ''}
            >
                거래내역
            </a>
            <a
                id="3"
                onClick={onClickHeaderBtn.bind(this, 3, '/bulletin_board')}
                className={page == 3 ? 'pressed' : ''}
            >
                공지사항
            </a>
            <a
                id="4"
                onClick={onClickHeaderBtn.bind(this, 4, '/setting')}
                className={page == 4 ? 'pressed' : ''}
            >
                내정보
            </a> */}
            <React.Fragment key={'left'}>
                <Button
                    onClick={toggleDrawer('left', true)}
                    style={{ color: '#07381B' }}
                >
                    <MenuIcon fontSize="large" />
                </Button>
                <SwipeableDrawer
                    anchor={'left'}
                    open={state['left']}
                    onClose={toggleDrawer('left', false)}
                    onOpen={toggleDrawer('left', true)}
                >
                    {list('left')}
                </SwipeableDrawer>
            </React.Fragment>
        </div>
    );
};

export default TopNav;
