import React from 'react';
import { Button } from '@material-ui/core';
import button from '../../components/button';
import items from '../../components/items';
import { useHistory } from 'react-router-dom';

function CompanyInfo() {
    const history = useHistory();
    return (
        <div
            id="pot"
            style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    margin: 'auto',
                    justifyContent: 'center',
                    paddingTop: '40px',
                }}
            >
                <img className="img-logo-login" src="./videhome_logo.png"></img>
            </div>
            <div
                style={{
                    textAlign: 'left',
                    fontSize: '15px',
                    color: 'black',
                    width: '90%',
                    margin: 'auto',
                    marginTop: '20px',
                }}
            >
                <p>안녕하세요 비드홈 입니다.</p>
                <p>집안 정리후 불필요한 물건들 판매하세요!</p>
                <p>집안 정리에 지친 여러분들에게,가족을 위해서가</p>
                <p>아닌 소중한 나를 위해서 쓰는 그런 시간을 만들어</p>
                <p>주는 그런 비드홈의 서비스를 만나 보세요!</p>
                <p>집안 정리 하세요! 판매 신청 하세요! 문앞에 두세요!</p>
                <p>그럼 입금 드려요! 넘 쉽고 간편해요.</p>
                <p>또한 집안에 필요한 물건 있으시면 바로 신청하세요!&nbsp;</p>
                <p>확인후 2시간 이내에 문앞 까지 무료로 배달해 드려요.</p>
                <p>귀찮더라도 하찮더라도 그냥 버리지 마세요!</p>
                <p>비싸지 않더라도 사소한 거라도 그냥 사지 마세요!</p>
                <p>당신의 현명한 비움과 가치있는 중고 소비가 탄소를</p>
                <p>줄이고 지구를 지키는 소중한 첫걸음이 됩니다.</p>
                <p>우리동네 착한가게 비드홈이 함께 할게요!</p>
            </div>
            <div style={{ paddingTop: '50px' }}>
                <Button
                    className="buttons"
                    style={{
                        fontFamily: 'Pretendard-Light',
                        width: '80%',
                        fontSize: '15px',
                    }}
                    variant="outlined"
                    onClick={() => history.push('/')}
                >
                    뒤로가기
                </Button>
            </div>
        </div>
    );
}
export default CompanyInfo;
