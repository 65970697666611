import React from 'react';
import { Button } from '@material-ui/core';
import button from '../../components/button';
import items from '../../components/items';
import QnAList from '../../components/qnaList';

function QnA() {
    return (
        <div id="pot">
            {/* <img id="pot" className="img-announce" src="../QandA.png"></img> */}
            <div>
                <QnAList />
            </div>
        </div>
    );
}
export default QnA;
