import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import DirectionsIcon from '@material-ui/icons/Directions';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            background: 'white',
            color: 'white',
            height: '40px',
            borderRadius:0,
            boxShadow:"none",
            overflow: "hidden",

        },
        input: {
            marginLeft: theme.spacing(1),
            flex: 1,
            color: 'black',
            border: 'solid 3px #FBAA13',
            borderRadius: '1rem',
            background: 'white',
        },
        iconButton: {
            padding: 10,
            color: '#FBAA13',
        },
        divider: {
            height: 28,
            margin: 4,
            color:"white"
        },
    })
);

export default function SearchBar() {
    const classes = useStyles();

    return (
        <Paper component="form" className={classes.root}>
            <div className={classes.iconButton}>
                {/* <img className="img-top-icon" src="./videhome_logo.png"></img> */}
            </div>
            <InputBase
                className={classes.input}
                placeholder="검색어를 입력해 주세요"
                inputProps={{
                    'aria-label': 'search google maps',
                    style: {
                        textAlign: 'left',
                        paddingLeft: '10px',
                        position: 'relative',
                        fontFamily: "Pretendard-Light",
                    },
                }}
            />
            <IconButton
                type="submit"
                className={classes.iconButton}
                aria-label="search"
            >
                <SearchIcon />
            </IconButton>
        </Paper>
    );
}
