import React from 'react';
import  button2 from "../../components/button2";
import { useDispatch, useSelector } from 'react-redux';
import { ERState } from '../../ERReducer';
import Er_rate from '../../components/er_rate';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import IPageProps from '../../interfaces/page';
import Progress from '../../components/progess';

const Receipt : React.FunctionComponent<IPageProps> = props =>  {    
const user = useSelector<ERState, ERState["user"]>((state)=> state.user);

    return (
    <div className="Apps" id="pot">
      <div className="left">
        <div className="wrapper">
          {button2()}
          {/* {Progress()} */}
        </div> 
        </div>
    </div>
  );
}

export default Receipt;