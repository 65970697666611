import React, { useEffect, useState } from 'react';
import {
    BrowserRouter,
    Route,
    RouteComponentProps,
    Switch,
} from 'react-router-dom';
import AuthRoute from './components/AuthRoute';
import logging from './config/logging';
import routes from './config/routes';
import { auth, db } from './config/firebase';
import TopNav from './components/topnav';
import BottomNav from './components/bottomNav';



export interface IApplicationProps {}

const Application: React.FunctionComponent<IApplicationProps> = (props) => {



    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if (user) {
                logging.info('User detected.');
            } else {
                logging.info('No user detected');
            }

        });
    }, []);


    return (
        <div style={{width:"100%"}}>
            <BrowserRouter>
                <TopNav/>
                <Switch>
                    {routes.map((route, index) => (
                        <Route
                            key={index}
                            path={route.path}
                            exact={route.exact}
                            render={(routeProps: RouteComponentProps<any>) => {
                                if (route.protected)
                                    return (
                                        <AuthRoute>
                                            <route.component
                                                auth={auth}
                                                db={db}
                                                {...routeProps}
                                            />
                                        </AuthRoute>
                                    );
                                return (
                                    <route.component
                                        auth={auth}
                                        db={db}
                                        {...routeProps}
                                    />
                                );
                            }}
                        />
                    ))}
                </Switch>
                <BottomNav/>
            </BrowserRouter>
        </div>
    );
};

export default Application;
