import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import HouseSidingIcon from '@mui/icons-material/HouseSiding';
import { useHistory } from 'react-router-dom';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import DeliveryDiningOutlinedIcon from '@mui/icons-material/DeliveryDiningOutlined';
import HistoryEduOutlinedIcon from '@mui/icons-material/HistoryEduOutlined';
import AssistantDirectionOutlinedIcon from '@mui/icons-material/AssistantDirectionOutlined';
import { createTheme } from '@mui/material';

export default function BottomNav() {
    const [value, setValue] = useState(2);
    const history = useHistory();
    const [page, setPage] = useState<any>({
        판매하기: '/apply',
        거래내역: '/records',
        HOME: '/',
        내정보: '/setting',
    });
    const styles = {
        stickToBottom: {
            width: '100%',
            position: 'fixed',
            bottom: 0,
        },
    };
    // useEffect(()=>{
    //     if(window.location.pathname === "/apply"){
    //         setValue(0);
    //     }
    //     else if(window.location.pathname === "/records"){
    //         setValue(1);
    //     }
    //     else if(window.location.pathname === "/direction"){
    //         setValue(3);
    //     }
    //     else if(window.location.pathname === "/setting"){
    //         setValue(4);
    //     }
    //     else{
    //         setValue(2);
    //     }
    //     console.log(window.location.pathname, "loc")
    // },[window.location.pathname])
    return (
        <div className="bottomNav">
            <Box sx={{ width: '100%' }}>
                <BottomNavigation
                    showLabels
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                    className="blocks"
                >
                    <BottomNavigationAction
                        label={<span className="block">판매하기</span>}
                        icon={<span className="block2"><DeliveryDiningOutlinedIcon /></span>}
                        style={{

                        }}
                        onClick={() => {
                            history.push('/apply');
                        }}
                    />
                    <BottomNavigationAction
                        label={<span className="block">거래내역</span>}
                        icon={<span className="block2"><HistoryEduOutlinedIcon  /></span>}
                        style={{

                        }}
                        onClick={() => {
                            history.push('/records');
                        }}
                    />
                    <BottomNavigationAction
                        label={<span className="block">HOME</span>}
                        icon={<span className="block2">< HouseSidingIcon  /></span>}
                        style={{

                        }}
                        onClick={() => {
                            history.push('/');
                        }}
                    />
                          <BottomNavigationAction
                        label={<span className="block">매장찾기</span>}
                        icon={<span className="block2">< AssistantDirectionOutlinedIcon /></span>}
                        style={{

                        }}
                        onClick={() => {
                            history.push('/direction');
                        }}
                    />
                    <BottomNavigationAction
                        label={<span className="block">내정보</span>}
                        icon={<span className="block2"><AccountCircleOutlinedIcon /></span>}
                        style={{

                        }}
                        onClick={() => {
                            history.push('/setting');
                        }}
                    />
                </BottomNavigation>
            </Box>
        </div>
    );
}
