import React, { useEffect, useState } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import button from '../../components/button';
import items from '../../components/items';
import DirectionsIcon from '@material-ui/icons/Directions';
import PhoneIcon from '@material-ui/icons/Phone';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PinDropIcon from '@material-ui/icons/PinDrop';
import IPageProps from '../../interfaces/page';
import { useHistory } from 'react-router-dom';
import StorefrontIcon from '@material-ui/icons/Storefront';

declare global {
    interface Window {
        kakao: any;
    }
}

const useStyles = makeStyles({
    flexGrow: {
        flex: '1',
    },
    button: {
        fontSize: '13px',
        backgroundColor: '#fff',
        borderRadius: '1rem',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#FBAA13',
            color: '#07381B',
        },
    },
});

const Direction: React.FunctionComponent<IPageProps> = (props) => {
    const [selected, setSelected] = useState<string>('중산점');
    const [map, setMap] = useState<any>();
    const [positions, setPositions] = useState<any>();
    function makeOverListener(map: any, marker: any, infowindow: any) {
        return function () {
            infowindow.open(map, marker);
        };
    }
    function makeOutListener(infowindow: any) {
        return function () {
            infowindow.close();
        };
    }
    const history = useHistory();
    const classes = useStyles();
    const goBack = () => {
        history.push('/setting');
    };

    useEffect(() => {
        let container = document.getElementById('map'); //지도를 담을 영역의 DOM 레퍼런스
        let options = {
            //지도를 생성할 때 필요한 기본 옵션
            center: new window.kakao.maps.LatLng(37.685158, 126.780891), //지도의 중심좌표.
            level: 3, //지도의 레벨(확대, 축소 정도)
        };
        var positions = [
            {
                content: '<div>비드홈(중산점)</div>',
                latlng: new window.kakao.maps.LatLng(37.685158, 126.780891),
            },
            {
                content: '<div>비드홈(일산점)</div>',
                latlng: new window.kakao.maps.LatLng(
                    37.68231965789217,
                    126.76951497707205
                ),
            },
            {
                content: '<div>비드홈(마두점)</div>',
                latlng: new window.kakao.maps.LatLng(
                    37.65234144033886,
                    126.77762632862952
                ),
            },
            {
                content: '<div>비드홈(대화점)</div>',
                latlng: new window.kakao.maps.LatLng(
                    37.67629037539999,
                    126.74801326186177
                ),
            },
            {
                content: '<div>비드홈(백석점)</div>',
                latlng: new window.kakao.maps.LatLng(
                    37.642994341006535,
                    126.78796500877374
                ),
            },
            {
                content: '<div>비드홈(주엽점)</div>',
                latlng: new window.kakao.maps.LatLng(
                    37.67089902793422,
                    126.76105755446791
                ),
            },
        ];
        setPositions(positions);
        let map = new window.kakao.maps.Map(container, options); //지도 생성 및 객체 리턴
        setMap(map);
        for (var i = 0; i < positions.length; i++) {
            var marker = new window.kakao.maps.Marker({
                map: map, // 마커를 표시할 지도
                position: positions[i].latlng, // 마커의 위치
            });
            var infowindow = new window.kakao.maps.InfoWindow({
                content: positions[i].content, // 인포윈도우에 표시할 내용
            });
            window.kakao.maps.event.addListener(
                marker,
                'mouseover',
                makeOverListener(map, marker, infowindow)
            );
            // var markerPosition  = new window.kakao.maps.LatLng(37.685158, 126.780891);
            // var marker = new window.kakao.maps.Marker({
            //     position: markerPosition
            // });
            marker.setMap(map);
        }
    }, []);

    const setCenter = (prop: string) => (event: any) => {
        if (prop === '중산점') {
            setSelected('중산점');
            map.setCenter(positions[0].latlng);
        } else if (prop === '백석점') {
            setSelected('백석점');
            map.setCenter(positions[4].latlng);
        } else if (prop === '주엽점') {
            setSelected('주엽점');
            map.setCenter(positions[5].latlng);
        } else if (prop === '마두점') {
            setSelected('마두점');
            map.setCenter(positions[2].latlng);
        } else if (prop === '대화점') {
            setSelected('대화점');
            map.setCenter(positions[3].latlng);
        } else if (prop === '일산점') {
            setSelected('일산점');
            map.setCenter(positions[1].latlng);
        }
    };

    return (
        <div className="Apps" id="pot">
            {/* <img className="background" src="./videhome_logo.png"></img> */}
            <div
                style={{
                    color: 'black',
                    fontSize: '20px',
                    margin: '35px 0 10px 0',
                }}
            >
                <img
                    id="pot"
                    className="img-announce"
                    src="../videhome_find.png"
                ></img>
            </div>
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    margin: 'auto',
                    justifyContent: 'center',
                }}
            >
                <div
                    style={{
                        position: 'relative',
                        justifyContent: 'space-evenly',
                        flexDirection: 'row',
                        width: '100%',
                        display: 'flex',
                    }}
                >
                    <div
                        style={{
                            paddingBottom: '20px',
                        }}
                    >
                        <Button
                            className={classes.button}
                            variant="outlined"
                            onClick={setCenter('중산점')}
                            style={{
                                width: '150px',
                                padding: '10px 20px',
                                margin: 'auto',
               
                                borderRadius: '10rem',
                                color: 'black',
                                fontFamily: "Pretendard-Light",
                                fontSize: '15px',
                                fontWeight:500
                            }}
                        >
                            <StorefrontIcon
                                fontSize="medium"
                                style={{
                                    display: 'inline',
                                    left: '22px',
                                    paddingRight: '10px',
                                }}
                            />
                            중산점
                        </Button>
                    </div>
                    <div
                        style={{
                            paddingBottom: '20px',
                        }}
                    >
                        <Button
                            className={classes.button}
                            variant="outlined"
                            onClick={setCenter('주엽점')}
                            style={{
                                width: '150px',
                                padding: '10px 20px',
                                margin: 'auto',
               
                                borderRadius: '10rem',
                                color: 'black',
               fontFamily: "Pretendard-Light",
                                fontSize: '15px',
                                fontWeight:500
                            }}
                        >
                            <StorefrontIcon
                                fontSize="medium"
                                style={{
                                    display: 'inline',
                                    left: '22px',
                                    paddingRight: '10px',
                                }}
                            />
                            주엽점
                        </Button>
                    </div>
                </div>
                <div
                    style={{
                        position: 'relative',
                        justifyContent: 'space-evenly',
                        flexDirection: 'row',
                        width: '100%',
                        display: 'flex',
                    }}
                >
                    <div
                        style={{
                            paddingBottom: '20px',
                        }}
                    >
                        <Button
                            className={classes.button}
                            variant="outlined"
                            onClick={setCenter('백석점')}
                            style={{
                                width: '150px',
                                padding: '10px 20px',
                                margin: 'auto',
               
                                borderRadius: '10rem',
                                color: 'black',
               fontFamily: "Pretendard-Light",
                                fontSize: '15px',
                                fontWeight:500
                            }}
                        >
                            <StorefrontIcon
                                fontSize="medium"
                                style={{
                                    display: 'inline',
                                    left: '22px',
                                    paddingRight: '10px',
                                }}
                            />
                            백석점
                        </Button>
                    </div>
                    <div
                        style={{
                            paddingBottom: '20px',
                        }}
                    >
                        <Button
                            className={classes.button}
                            variant="outlined"
                            onClick={setCenter('일산점')}
                            style={{
                                width: '150px',
                                padding: '10px 20px',
                                margin: 'auto',
               
                                borderRadius: '10rem',
                                color: 'black',
               fontFamily: "Pretendard-Light",
                                fontSize: '15px',
                                fontWeight:500
                            }}
                        >
                            <StorefrontIcon
                                fontSize="medium"
                                style={{
                                    display: 'inline',
                                    left: '22px',
                                    paddingRight: '10px',
                                }}
                            />
                            일산점
                        </Button>
                    </div>
                </div>
                <div
                    style={{
                        position: 'relative',
                        justifyContent: 'space-evenly',
                        flexDirection: 'row',
                        width: '100%',
                        display: 'flex',
                    }}
                >
                    <div
                        style={{
                            paddingBottom: '20px',
                        }}
                    >
                        <Button
                            className={classes.button}
                            variant="outlined"
                            onClick={setCenter('마두점')}
                            style={{
                                width: '150px',
                                padding: '10px 20px',
                                margin: 'auto',
               
                                borderRadius: '10rem',
                                color: 'black',
               fontFamily: "Pretendard-Light",
                                fontSize: '15px',
                                fontWeight:500
                            }}
                        >
                            <StorefrontIcon
                                fontSize="medium"
                                style={{
                                    display: 'inline',
                                    left: '22px',
                                    paddingRight: '10px',
                                }}
                            />
                            마두점
                        </Button>
                    </div>
                    <div
                        style={{
                            paddingBottom: '20px',
                        }}
                    >
                        <Button
                            className={classes.button}
                            variant="outlined"
                            onClick={setCenter('대화점')}
                            style={{
                                width: '150px',
                                padding: '10px 20px',
                                margin: 'auto',
               
                                borderRadius: '10rem',
                                color: 'black',
               fontFamily: "Pretendard-Light",
                                fontSize: '15px',
                                fontWeight:500
                            }}
                        >
                            <StorefrontIcon
                                fontSize="medium"
                                style={{
                                    display: 'inline',
                                    left: '22px',
                                    paddingRight: '10px',
                                }}
                            />
                            대화점
                        </Button>
                    </div>
                </div>
            </div>
            <div
                style={{
                    fontSize: '20px',
                    color: 'black',
                    padding: '0px 10px 10px 10px',
                            fontFamily: "Pretendard-Light",
                }}
            >
                {selected}
            </div>
            <div className="map_container">
                <div
                    id="map"
                    style={{
                        width: '80%',
                        height: '300px',
                        margin: 'auto',
                        border: 'solid black 3px',
                    }}
                ></div>
            </div>

            <div
                style={{
                    color: 'black',
                    fontSize: '15px',
                    padding: '7px 0px',
                    float: 'left',
                    width: '100%',
                    textAlign: 'center',
                            fontFamily: "Pretendard-Light",
                }}
            >


            </div>
            <div style={{ padding: '0px 0px 20px 0px' }}>
                <Button
                    variant="outlined"
                    onClick={() => goBack()}
                    style={{
                        width: '40%',
                        margin: 'auto',
                        minWidth: '30px',
       
                        borderRadius: '10rem',
                                fontFamily: "Pretendard-Light",
                    }}
                >
                    뒤로가기
                </Button>
            </div>
        </div>
    );
};
export default Direction;
